import React, { Component } from "react";
import {Container, Col, Row, Button, Card, Accordion} from "react-bootstrap";
import banner from "../images/banners/golden-triangle.jpg";

import img1 from "../images/hotel1.jpg";
import img2 from "../images/hotel2.jpg";
import img3 from "../images/hotel3.jpg";

import img4 from "../images/india-gate.jpg";
import img5 from "../images/president-house.jpg";
import img6 from "../images/iron-pillar.jpg";

import img7 from "../images/agra-fort2.jpg";
import img8 from "../images//agra-fort.jpg";
import img9 from "../images/taj-mahal.jpg";


import img10 from "../images/fatehpur-sikri3.jpg";
import img11 from "../images/jai-mahal-palace.jpg";
import img12 from "../images/shopping.jpg";

import img13 from "../images/castle-riding.jpg";
import img14 from "../images/kali-temple.jpg";
import img15 from "../images/pink-city.jpg";

import img16 from "../images/delhi.jpg";
import img17 from "../images/delhi2.jpg";
import img18 from "../images/airport.jpg";
import { withRouter } from "react-router-dom";
import goldentrianglepdf from "../doc/GoldenTriangle.pdf";

class GoldenTriangle extends Component {
  state = {  } 
  render() { 
    return (    <>
      <section>
        <Row>
          <Col lg="12">
            <img src={banner} className="img-fluid" />
          </Col>
        </Row>
      </section>
<Container>
<Row>
<Col lg="12">
  <h1 className="mb-4 mt-5">
  Golden <strong> Triangle </strong>
  </h1>
  
</Col>

<Col lg="12">
  <p className="text-justify mt-2 tour-para"><span>This journey travels </span> through the classic sights of India's most populist circuit - Delhi, Agra and Jaipur. This tour has been put together to represent the colourful shades that can be witnessed whilst travelling through the princely state. Start by exploring the Mughal and Imperial monuments of Delhi from here travel to Agra, home of the impressive Taj Mahal and end your stay discovering the pink city of Jaipur.</p>
  
  <p className="text-right mb-5"><Button className="mr-5" onClick={() => this.nextPath("/customTour")}>Book Now</Button>
  <Button href={goldentrianglepdf} target = "_blank"> Read Itinerary Details </Button> </p>
</Col>
</Row>

<Row>
<Col lg="6">
<Accordion  defaultActiveKey="0">
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="0">
    Day 01

    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="0">
    <Card.Body>
      <h5>Delhi</h5>
      <img src={img1} className="img-fluid mr-3" />
      <img src={img2} className="img-fluid mr-3" />
      <img src={img3} className="img-fluid mr-3" />
      <p className="text-justify">Check in at Hotel. Spend rest of day at leisure.</p>

    </Card.Body>
  </Accordion.Collapse>
</Card>
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="1">
    Day 02

    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="1">
    <Card.Body>
    <h5>Delhi</h5>
    <img src={img4} className="img-fluid mr-3" />
      <img src={img5} className="img-fluid mr-3" />
      <img src={img6} className="img-fluid mr-3" />
      <p className="text-justify">Sightseeing tour of the Old and New Delhi, visiting the Jama Masjid (1465 A.D.), drive through the fabled Chandni Chowk (Silver Street) and see the Red Fort, Rajghat, where the "Father of the nation", Mahatma Gandhi, was cremated; the 72M high Qutab Minar, the 1500-year-old Iron Pillar, government buildings, the President's House, Parliament House, India Gate, Laxmi Narayan Temple, and last but not least Delhi's main shopping centre, Connaught Place.</p>

    </Card.Body>
  </Accordion.Collapse>
</Card>
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="2">
    Day 03

    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="2">
    <Card.Body>
    <h5>Delhi - Agra</h5>
    <img src={img7} className="img-fluid mr-3" />
      <img src={img8} className="img-fluid mr-3" />
      <img src={img9} className="img-fluid mr-3" />
      <p className="text-justify">After breakfast departure to Agra by bus or private air conditioned car, a distance of 203Km. - about 4 1/2 Hours. After arriving at hotel in the late afternoon, visit the Agra Fort and the Taj Mahal recently awarded as one of the Modern Seven Wonders of the world.</p>

    </Card.Body>
  </Accordion.Collapse>
</Card>

</Accordion>
</Col>


<Col lg="6">
<Accordion  defaultActiveKey="5">
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="5">
      Day 04
    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="5">
    <Card.Body>
    <h5>Agra - Jaipur</h5>
    <img src={img10} className="img-fluid mr-3" />
      <img src={img11} className="img-fluid mr-3" />
      <img src={img12} className="img-fluid mr-3" />
      <p className="text-justify">Drive to Jaipur, a distance of 235Km. - about 6 hours by bus or private air conditioned car. On the way to Jaipur visit the Fatehpur-Sikri, an ancient city built by Akbar that had to be deserted due to lack of water. A very impressive sight. Evening is free for relaxing or shopping. Jaipur is great for purchases of jewelry and clothing. Stay at Jai Mahal Palace.</p>
    </Card.Body>
  </Accordion.Collapse>
</Card>
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="6">
    Day 05
    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="6">
    <Card.Body>
    <h5>Jaipur</h5>
    <img src={img13} className="img-fluid mr-3" />
      <img src={img14} className="img-fluid mr-3" />
      <img src={img15} className="img-fluid mr-3" />
      <p className="text-justify">Morning visit to Amber Fort including a climb to the Castle riding on the back of an elephant. Later, visit the Temple of Kali. In the afternoon visit the "pink" city and its many interesting sights.</p>

    </Card.Body>
  </Accordion.Collapse>
</Card>
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="7">
    Day 06
    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="7">
    <Card.Body>
    <h5>Jaipur - Delhi</h5>
    <img src={img16} className="img-fluid mr-3" />
      <img src={img17} className="img-fluid mr-3" />
      <img src={img18} className="img-fluid mr-3" />
      <p className="text-justify">Drive to Delhi, a distance of 260Km. - about 6 hours by bus or private air conditioned car. Transfer to Delhi Airport for flight home.</p>

    </Card.Body>
  </Accordion.Collapse>
</Card>


</Accordion>
</Col>

</Row>

</Container>
</>);
  }
  nextPath(path) {
    this.props.history.push(path);
  }
}
 

export default withRouter(GoldenTriangle);
