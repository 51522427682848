import React, { Component } from "react";
import {Container, Col, Row, Button} from "react-bootstrap";
import banner from "../images/banners/tailor-made-tours.jpg";


const   TailorMadeTours = () => ( 
  <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    <strong>Tailor Made </strong> Tours  
    </h1>
    
  </Col>

  <Col lg="12">
  
<p className="text-justify  mt-2 tour-para">
Your vacation should be a holiday that is planned and arranged as per your requirements. We believe that only then can our guests take in the true essence of every place they visit. Every place you plan to visit in India has a different culture, flavor, and history to offer. Each historical monument is backed by rich history of the founder and the various battles it has witnessed. The lanes of every tourist city have something special you can shop for souvenirs and almost every city, town, village has a specialty to offer in food, clothing, jewelry, and much more. All this needs a lot of time and luxury to absorb.</p>

<p className="text-justify tour-para">
Tailor-made tours is our solution to your personalized vacation. We give you the golden opportunity to visit India with all the convenience you want. Taj Tour has a team that specializes in planning holidays to make them really special for you. We carefully bring together for you a holiday that highlights the various cultures of India. We proceed by preparing a package for you that incorporates selected highlights and a bounty of culture and history that will reveal the real India. Be it a group travel or a family trip, we are proficient at designing tailor-made tour packages.</p>

<p className="text-justify tour-para">After getting a complete understanding of the kind of trip you want, we prepare an itinerary to meet all of your requirements. All you need to do is let us know the places you want to visit, the you prefer. Our expert tailor-made tour team will set to work preparing your dream India holiday. Also, our team will guide you on the best places to visit for the kind of vacation you amount of time you want to spend there, the kind of accommodation you want, and the mode of transportation want.</p>

<p className="text-justify tour-para">Group tours are best when they are planned as per your requirements. This is the best way to avoid stress and rushing on a trip. Our experts can make the travel, accommodation and sightseeing arrangements for your entire group as per your convenience. You can easily relax through the entire trip as all the work has been done for you beforehand.</p>

<p className="text-justify tour-para">Taj Tour has a team that specializes in making Group travel and Tailor-made Tours for clients with the clear intention of providing tourists peace of mind and a carefree attitude throughout their vacation.</p>

    
    <p className="text-right mb-5"><Button className="mr-5" href="" target="_blank">Book Now</Button> 
    <Button href=""> Read Itinerary Details </Button> </p>
  </Col>
</Row>


</Container>
</>
);
export default TailorMadeTours;
