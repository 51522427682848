import React, { Component } from "react";
import {Container, Col, Row, Button, Card, Accordion} from "react-bootstrap";
import banner from "../images/banners/glimpse-of-india.jpg";

import img1 from "../images/hotel1.jpg";
import img2 from "../images/hotel2.jpg";
import img3 from "../images/hotel3.jpg";
import img4 from "../images/new-delhi.jpg";
import img5 from "../images/old-delhi.jpg";
import img6 from "../images/son-et-lumiere.jpg";
import img7 from "../images/jaipur.jpg";
import img8 from "../images/jaipur2.jpg";
import img9 from "../images/jaipur3.jpg";
import img10 from "../images/amber-fort1.jpg";
import img11 from "../images/amber-fort2.jpg";
import img12 from "../images/amber-fort3.jpg";
import img28 from "../images/fatehpur-sikri.jpg";
import img29 from "../images/fatehpur-sikri2.jpg";
import img30 from "../images/taj-mahal.jpg";


import img13 from "../images/khajuraho.jpg";
import img14 from "../images/orchha.jpg";
import img15 from "../images/khajuraho2.jpg";
import img16 from "../images/kandariya.jpg";
import img17 from "../images/kandariya2.jpg";
import img18 from "../images/kandariya3.jpg";
import img19 from "../images/varanasi.jpg";
import img20 from "../images/varanasi2.jpg";
import img21 from "../images/varanasi3.jpg";
import img22 from "../images/varanasi4.jpg";
import img23 from "../images/golden-temple.jpg";
import img24 from "../images//golden-temple2.jpg";
import img25 from "../images/sarnath.jpg";
import img26 from "../images/sarnath2.jpg";
import img27 from "../images/airport.jpg";
import indiapdf from "../doc/GlimpseOfIndia.pdf";
import { withRouter } from "react-router-dom";

class GlimpseofIndia  extends Component {
  state = {  } 
  render() { 
    return (
    <>
      <section>
        <Row>
          <Col lg="12">
            <img src={banner} className="img-fluid" />
          </Col>
        </Row>
      </section>
<Container>
<Row>
<Col lg="12">
  <h1 className="mb-4 mt-5">
  Glimpses of <strong> India </strong>
  </h1>

</Col>

<Col lg="12">
  <p className="text-justify mt-2 tour-para"><span>Get a Glimpses of India </span> with our tour of the most magnificent places in India. Taj Tours has put together a dream trip for travellers, with cities like Delhi, Jaipur, Agra, Khajuraho, and Varanasi. Visit the most mesmerizing monuments summarizing the historic richness of the country and be smitten by the scenic splendor each place has to offer. The trip is well planned with complete itinerary and comfortable travel and accommodation facilities. Try our trip â€˜Glimpses of Indiaâ€™ for a well spent vacation.</p>

  <p className="text-right mb-5"><Button className="mr-5" onClick={() => this.nextPath("/customTour")}>Book Now</Button>
  <Button href={indiapdf} target = "_blank"> Read Itinerary Details </Button> </p>
</Col>
</Row>

<Row>
<Col lg="6">
<Accordion  defaultActiveKey="0">
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="0">
    Day 01

    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="0">
    <Card.Body>
      <h5>Delhi</h5>
      <img src={img1} className="img-fluid mr-3" />
      <img src={img2} className="img-fluid mr-3" />
      <img src={img3} className="img-fluid mr-3" />
      <p className="text-justify">Arrival in Delhi and transfer to hotel. Upon arrival at the hotel, proceed for check-in. Overnight at the hotel.</p>

    </Card.Body>
  </Accordion.Collapse>
</Card>
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="1">
    Day 02

    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="1">
    <Card.Body>
    <h5>Delhi</h5>
    <img src={img4} className="img-fluid mr-3" />
      <img src={img5} className="img-fluid mr-3" />
      <img src={img6} className="img-fluid mr-3" />
      <p className="text-justify">Morning Sightseeing tour of the New Delhi. Afternoon sightseeing of Old Delhi. Evening visit the Red Fort for a "Son et Lumiere" Show.</p>

    </Card.Body>
  </Accordion.Collapse>
</Card>
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="2">
    Day 03

    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="2">
    <Card.Body>
    <h5>Delhi - Jaipur</h5>
    <img src={img7} className="img-fluid mr-3" />
      <img src={img8} className="img-fluid mr-3" />
      <img src={img9} className="img-fluid mr-3" />
      <p className="text-justify">Delhi to Jaipur by Coach or private air conditioned car a distance of 260 Kms - 5.30 Hours. Afternoon sightseeing of Jaipur.</p>

    </Card.Body>
  </Accordion.Collapse>
</Card>
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="3">
    Day 04

    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="3">
    <Card.Body>
    <h5>Jaipur</h5>
    <img src={img10} className="img-fluid mr-3" />
      <img src={img11} className="img-fluid mr-3" />
      <img src={img12} className="img-fluid mr-3" />
      <p className="text-justify">Morning excursion to the Amber Fort. Afternoon is free</p>

    </Card.Body>
  </Accordion.Collapse>
</Card>
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="4">
    Day 05

    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="4">
    <Card.Body>
    <h5>Jaipur - Agra</h5>
    <img src={img28} className="img-fluid mr-3" />
      <img src={img29} className="img-fluid mr-3" />
      <img src={img30} className="img-fluid mr-3" />
      <p className="text-justify">Drive to Agra by Coach or Car via Fatehpur-Sikri a distance of 240 Kms - 6.3 Hours. After arrival at hotel, afternoon sightseeing tour of Agra including the Taj Mahal.</p>
      </Card.Body>
  </Accordion.Collapse>
</Card>

</Accordion>
</Col>


<Col lg="6">
<Accordion  defaultActiveKey="5">
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="5">
      Day 06
    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="5">
    <Card.Body>
    <h5>Agra - Khajuraho</h5>
    <img src={img13} className="img-fluid mr-3" />
      <img src={img14} className="img-fluid mr-3" />
      <img src={img15} className="img-fluid mr-3" />
      <p className="text-justify">Morning transfer to take the Shatabdi express train to Jhansi a distance of 200 Kms - 5 Hours. On arrival in Jhansi, transfer to bus for Khajuraho. En route visit Orchha. On arrival in Khajuraho, check-in at the hotel.</p>
    </Card.Body>
  </Accordion.Collapse>
</Card>
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="6">
    Day 07
    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="6">
    <Card.Body>
    <h5>Jaipur - Agra</h5>
    <img src={img16} className="img-fluid mr-3" />
      <img src={img17} className="img-fluid mr-3" />
      <img src={img18} className="img-fluid mr-3" />
      <p className="text-justify">Sightseeing of Khajuraho. Khajuraho is renowned for its magnificent temples, unrivaled for beautiful ornamentation and detail. The main temples, Kandariya Mahadeva, Chatterhigh, Parshwanath, and Ghanti, are remarkable for their exquisite design sculpture and graceful pillars.</p>

    </Card.Body>
  </Accordion.Collapse>
</Card>
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="7">
    Day 08
    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="7">
    <Card.Body>
    <h5>Khajuraho - Varanasi</h5>
    <img src={img19} className="img-fluid mr-3" />
      <img src={img20} className="img-fluid mr-3" />
      <img src={img21} className="img-fluid mr-3" />
      <p className="text-justify">Fly to Varanasi. Arrive in the holy city of Varanasi, also known as Benares. Afternoon city sightseeing of Varanasi</p>

    </Card.Body>
  </Accordion.Collapse>
</Card>
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="8">
    Day 09
    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="8">
    <Card.Body>
    <h5>Varanasi</h5>
    <img src={img22} className="img-fluid mr-3" />
      <img src={img23} className="img-fluid mr-3" />
      <img src={img24} className="img-fluid mr-3" />
      <p className="text-justify">Varanasi is the principal city of Hinduism, located on the banks of the holy River Ganges. For several thousand years, pilgrims have cleansed themselves of sins at this holy place. Experience a unique motor launch cruise on the holy river, visit the "Golden Temple" and browse through the age-old narrow streets.</p>

    </Card.Body>
  </Accordion.Collapse>
</Card>
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="9">
    Day 10
    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="9">
    <Card.Body>
    <h5>Varanasi - Delhi or Kathmandu</h5>
    <img src={img25} className="img-fluid mr-3" />
      <img src={img26} className="img-fluid mr-3" />
      <img src={img27} className="img-fluid mr-3" />
      <p className="text-justify">Group leaving for Delhi Check-out from hotel at noon and visit Sarnath. Later transfer to airport for flight to Delhi. On arrival in Delhi at Domestic airport, transfer to international airport for flight back home.</p>

    </Card.Body>
  </Accordion.Collapse>
</Card>


</Accordion>
</Col>

</Row>

</Container>
</>);
  }
  nextPath(path) {
    this.props.history.push(path);
  }

}
 
export default withRouter(GlimpseofIndia);

