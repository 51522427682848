import React, { Component } from "react";
import {Container, Col, Row, Button, Card, Accordion} from "react-bootstrap";
import banner from "../images/banners/surf-and-sand.jpg";

import img1 from "../images/wales-museum.jpg";
import img2 from "../images/hanging-gardens.jpg";
import img3 from "../images/india-gate.jpg";

import img4 from "../images/fort-cochin.jpg";
import img5 from "../images/jewish-synagogue.jpg";
import img6 from "../images/kathakali-dance.jpg";

import img7 from "../images/kochin.jpg";
import img8 from "../images/kochin2.jpg";
import img9 from "../images/kochin3.jpg";

import img10 from "../images/goa-beach.jpg";
import img11 from "../images/goa-beach2.jpg";
import img12 from "../images/goa-beach3.jpg";

import img13 from "../images/portuguese-colony.jpg";
import img14 from "../images/portuguese-colony2.jpg";
import img15 from "../images/portuguese-colony3.jpg";

import img16 from "../images/bombay.jpg";
import img17 from "../images/bombay2.jpg";
import img18 from "../images/bombay3.jpg";

import img19 from "../images/harbour.jpg";
import img20 from "../images/sculpted-cave.jpg";
import img21 from "../images/sculpted-cave2.jpg";

import img22 from "../images/airport.jpg";
import img23 from "../images/airport2.jpg";
import img24 from "../images/airport3.jpg";
import surfSand from "../doc/SurfAndSand.pdf";
import { useHistory } from "react-router-dom";


function SurfSand(){
  const history = useHistory();

  const routeChange = () =>{ 
    let path = `/customTour`; 
    history.push(path);
  }


return( 
  <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    Surf &   <strong> Sand</strong>
    </h1>
    
  </Col>

  <Col lg="12">
    <p className="text-justify mt-2 tour-para"><span>The beaches of India </span> are a must see. There are many famous beach tourist destinations in India and with that in mind, Taj tour gets you packages like Kerala holiday packages, Goa Tours etc. Experience the sunset on the magnificent beaches and walk on the sun kissed sands. Enjoy water sports and have the time of your life. Our beach vacations like Kerala Tour packages include visits to popular places like Mumbai, Cochin, and Goa as well.
    </p>

    
    <p className="text-right mb-5"><Button className="mr-5" onClick={routeChange}>Book Now</Button>
  <Button href={surfSand} target = "_blank"> Read Itinerary Details </Button> </p>
  </Col>
</Row>

<Row>

<Col lg="6">
<Accordion  defaultActiveKey="0">
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
      Day 01

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <h5>Bombay</h5>
        <img src={img1} className="img-fluid mr-3" />
        <img src={img2} className="img-fluid mr-3" />
        <img src={img3} className="img-fluid mr-3" />
        <p className="text-justify">Arrive Bombay in the night and transfer to the hotel. OR arrive in the afternoon drive around bustling Bombay visiting the Prince of Wales Museum, see the Gateway of India; the splendid Hanging Gardens and the Crawford Market</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
      Day 02

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
      <h5>Bombay - Cochin</h5>
      <img src={img4} className="img-fluid mr-3" />
        <img src={img5} className="img-fluid mr-3" />
        <img src={img6} className="img-fluid mr-3" />
        <p className="text-justify">In the morning drive to the airport to connect with flight to Cochin. On arrival transfer to hotel. In the afternoon take a city tour of Cochin, the oldest European settlement in India. The tour includes the Portuguese Church, Jewish Synagogue and Fort Cochin. In the evening witness the Kathakali dance performance.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
      Day 03

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body>
      <h5>Cochin</h5>
      <img src={img7} className="img-fluid mr-3" />
        <img src={img8} className="img-fluid mr-3" />
        <img src={img9} className="img-fluid mr-3" />
        <p className="text-justify">This morning embark on a full day cruise of the backwaters of the area. Begin at Alleppey and cruise to Kottayam, passing ancient temples, mosques and the wharf market. Return in the evening to Cochin.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="3">
        Day 04
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="3">
      <Card.Body>
      <h5>Cochin - Goa</h5>
      <img src={img10} className="img-fluid mr-3" />
        <img src={img11} className="img-fluid mr-3" />
        <img src={img12} className="img-fluid mr-3" />
        <p className="text-justify">In the evening take a flight to Goa. After checking in, enjoy beside the Arabian Sea beaches of Goa perhaps the finest in India. Sands shaded by palms and washed by warm seas stretch for miles without the crowds.</p>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  </Accordion>
</Col>

<Col lg="6">
<Accordion  defaultActiveKey="0">

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="4">
      Day 05
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="4">
      <Card.Body>
      <h5>Goa</h5>
      <img src={img13} className="img-fluid mr-3" />
        <img src={img14} className="img-fluid mr-3" />
        <img src={img15} className="img-fluid mr-3" />
        <p className="text-justify">Spend the day at leisure with a choice of optional tours in this former 400 year old Portuguese Colony.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="5">
      Day 06
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="5">
      <Card.Body>
      <h5>Goa - Bombay</h5>
      <img src={img16} className="img-fluid mr-3" />
        <img src={img17} className="img-fluid mr-3" />
        <img src={img18} className="img-fluid mr-3" />
        <p className="text-justify">Morning at leisure. In the afternoon drive to the airport to connect to Bombay. On arrival transfer to hotel.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="6">
      Day 07
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="6">
      <Card.Body>
      <h5>Bombay</h5>
      <img src={img19} className="img-fluid mr-3" />
        <img src={img20} className="img-fluid mr-3" />
        <img src={img21} className="img-fluid mr-3" />
        <p className="text-justify">This morning cruise through busy Bombay Harbour and visit the sculpted cave temples on nearby Elephanta Island, the site of ancient rock carved Hindu temples dedicated to the god Shiva. Afternoon free for independent activities.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="7">
      Day 08
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="7">
      <Card.Body>
      <h5>Bombay</h5>
      <img src={img22} className="img-fluid mr-3" />
        <img src={img23} className="img-fluid mr-3" />
        <img src={img24} className="img-fluid mr-3" />
        <p className="text-justify">Transfer to the airport to connect with flight to onward destination.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
</Col>
</Row>
</Container>
</>
);
}
export default SurfSand;
