import React, { Component } from "react";
import {Container, Col, Row, Button, Card, Accordion} from "react-bootstrap";
import banner from "../images/banners/palace-on-wheels.jpg";

import img1 from "../images/train.jpg";
import img2 from "../images/train2.jpg";
import img3 from "../images/train3.jpg";

import img4 from "../images/amber-fort1.jpg";
import img5 from "../images/jantarmantar.jpg";
import img6 from "../images/ram-bagh-palaca.jpg";

import img7 from "../images/camel-ride.jpg";
import img8 from "../images/dinner.jpg";
import img9 from "../images/dances.jpg";

import img10 from "../images/amber-fort1.jpg";
import img11 from "../images/amber-fort2.jpg";
import img12 from "../images/shopping.jpg";

import img13 from "../images/jhoomar-baori-hotel.jpg";
import img14 from "../images/game-sanctuary.jpg";
import img15 from "../images/desert-jaisalmer.jpg";

import img16 from "../images/lake-city.jpg";
import img17 from "../images/lake-pichola.jpg";
import img18 from "../images/maharani.jpg";

import img19 from "../images/bird-sanctuary.jpg";
import img20 from "../images/fatehpur-sikri2.jpg";
import img21 from "../images/taj-mahal.jpg";

import img22 from "../images/delhi.jpg";
import img23 from "../images/delhi2.jpg";
import img24 from "../images/delhi3.jpg";
import { useHistory } from "react-router-dom";
import placeonwheelspdf from "../doc/PalaceOnWheels.pdf";

function  PalaceonWheels(){
  const history = useHistory();
  
  const routeChange = () =>{ 
    let path = `/customTour`; 
    history.push(path);
  }

 return( <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    Palace on  <strong> Wheels</strong>
    </h1>
    
  </Col>

  <Col lg="12">
    <p className="text-justify mt-2 tour-para"><span>A remarkable journey </span> through an extraordinary region of India aboard the Palace on Wheels. The Palace on Wheels train that crosses the State of Rajasthan travels back in time to a world of maharajas, palaces, and legendary warriors. The Palace on Wheels with our Luxury India Tours is equipped with 14 air-conditioned coaches, each named after a former Princely State; they are a recreation of the personal railway cars of the royals.
    </p>

    <p className="text-justify tour-para">
    The coaches for this luxury India tour consist of four cabins with double or twin beds, attached bathroom and showers, plush decor, and personal attendants at your service.
    </p>

    <p className="text-justify tour-para">
    The Palace on Wheels has a lounge area, a library, and a bar with two dining cars serving Continental, Indian, and Rajasthani cuisine, all to ensure an unforgettable experience fit for Royalty!
    </p>
    
    <p className="text-right mb-5"><Button className="mr-5" onClick={routeChange}>Book Now</Button>
  <Button href={placeonwheelspdf} target = "_blank"> Read Itinerary Details </Button> </p>
  </Col>
</Row>

<Row>

<Col lg="6">
<Accordion  defaultActiveKey="0">
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
      Day 01

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <h5>Delhi</h5>
        <img src={img1} className="img-fluid mr-3" />
        <img src={img2} className="img-fluid mr-3" />
        <img src={img3} className="img-fluid mr-3" />
        <p className="text-justify">Evening welcome on board the Palace on Wheels reporting time 1600 Hrs , Departs at 18:30 for the followed by dinner at 19:30. Overnight journey to Jaipur.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
      Day 02

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
      <h5>Jaipur</h5>
      <img src={img4} className="img-fluid mr-3" />
        <img src={img5} className="img-fluid mr-3" />
        <img src={img6} className="img-fluid mr-3" />
        <p className="text-justify">Arrive jaipur at 02:00 - Coach sightseeing tour of Jaipur via Hawamahal to Amber Palace, Lunch at Ram Bagh Palace. Afternoon visit of City Palace and Jantar Mantar Observatory. Evening tea on Palace on Wheels, then departure for Nahargarh for dinner and cultural program. Departure for railway station for a 19:30 departure.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
      Day 03

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body>
      <h5>Jaisalmer</h5>
      <img src={img7} className="img-fluid mr-3" />
        <img src={img8} className="img-fluid mr-3" />
        <img src={img9} className="img-fluid mr-3" />
        <p className="text-justify">Arrive 8:15 AM after breakfast set to see Jaisalmer go for an exciting camel ride on the sand dunes of Sam, before sipping a refreshing cup of tea. Enjoy the dinner and the cultural porgramme at a Five Star hotel.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="5">
        Day 04
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="5">
      <Card.Body>
      <h5>Jodhpur</h5>
      <img src={img10} className="img-fluid mr-3" />
        <img src={img11} className="img-fluid mr-3" />
        <img src={img12} className="img-fluid mr-3" />
        <p className="text-justify">Arrive 7:00 AM a city ringed by a high stone wall with seven gates and several bastions. After lunch at a Palace hotel, return to the Palace-on-Wheels. While in Jodhpur shop for exquisite handicraft articles</p>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  </Accordion>
</Col>

<Col lg="6">
<Accordion  defaultActiveKey="0">

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="5">
      Day 05
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="5">
      <Card.Body>
      <h5>Sawai - Madhopur</h5>
      <img src={img13} className="img-fluid mr-3" />
        <img src={img14} className="img-fluid mr-3" />
        <img src={img15} className="img-fluid mr-3" />
        <p className="text-justify">Breakfast at Jhoomar Baori Hotel followed by a visit to the Game Sanctuary. Noon return to Palace on Wheels for trip across the Thar desert to Jaisalmer (lunch and dinner en route.)</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="6">
      Day 06
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="6">
      <Card.Body>
      <h5>Chittorgarh</h5>
      <img src={img16} className="img-fluid mr-3" />
        <img src={img17} className="img-fluid mr-3" />
        <img src={img18} className="img-fluid mr-3" />
        <p className="text-justify">Breakfast on board prior to a 09:00 arrival at Jodhpur. Coach visit to Udaipur the Lake City. Boat trip on the Lake Pichola, Lunch at the Hotel. On return visit the Maharani of Udaipur. Tea and Dinner on board</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="7">
      Day 07
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="7">
      <Card.Body>
      <h5>Bharatpur</h5>
      <img src={img19} className="img-fluid mr-3" />
        <img src={img20} className="img-fluid mr-3" />
        <img src={img21} className="img-fluid mr-3" />
        <p className="text-justify">Bharatpur arrive early morning, After breakfast depart to visit the bird sanctuary. Coach trip to Agra via Fatehpur-Sikri. Afternoon visit to Agra's Taj Mahal and the Fort. Dinner on board the train and a 20:00 departure for Delhi.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="8">
      Day 08
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="8">
      <Card.Body>
      <h5>Delhi</h5>
      <img src={img22} className="img-fluid mr-3" />
        <img src={img23} className="img-fluid mr-3" />
        <img src={img24} className="img-fluid mr-3" />
        <p className="text-justify">Breakfast on board prior to arrival in Delhi.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
</Col>
</Row>
</Container>
</>
);
}
export default PalaceonWheels;
