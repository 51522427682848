import React, { Component } from "react";
import {Container, Col, Row, Button } from "react-bootstrap";
import banner from "../images/banners/information.jpg";
  const GeneralInfo = () => ( 

    <>
       <section>
              <Row>
                <Col lg="12">
                  <img src={banner} className="img-fluid" />
                </Col>
              </Row>
            </section>

    <Container className="general-info">
    <Row className="mb-3">
      <Col lg="12">
        <h1 className="mb-4 mt-5">
        General <strong> Information </strong>
        </h1>
      </Col>
  
      
    </Row>
    
    <Row className="mb-3"> 
    <Col lg="6">
      <h4 className="icon"><i className="fa fa-cc-visa"></i></h4>
      <p><strong>Visas</strong> : Canadian citizens do need a visa to enter India. In Canada, India is represented by the High Commissioner of India, which is located in Ottawa. In addition, there are two consular offices, one in Vancouver and the other in Toronto. </p></Col>
    <Col lg="6">
    <h4 className="icon"><i className="fa fa-plug"></i></h4>
      <p><strong>Electricity</strong> : In most places the current is AC 220 volts, 50 cycles. Some "off- the-beaten-path" areas still have Direct Current (DC). It is advisable to check with the hotel before using any electrical appliance - including immersion heaters used by many for early morning beverage. </p></Col>
    </Row> 

    <Row className="mb-3"> 

    <Col lg="6" className="address" ><p className="font-weight-bold location">Ottawa</p> 

   <h6 className="mt-2 font-italic">High Commission of India</h6>
    <p className="font-italic"><i className="fa fa-home mr-1"></i> 10 Springfield Road, Ottawa, Ontario K1M 1C9</p>
    <p className="font-italic"><i className="fa fa-phone mr-1"></i> 613-744-3751</p>
    <p className="font-italic"><i className="fa fa-fax mr-1"></i> 613-744-0913</p>
    <p className="font-italic"><i className="fa fa-envelope mr-1"></i> hicomind@hciottawa.ca</p>
    <p className="font-italic"><a href="https://www.hciottawa.ca/" target="_blank"> http://www.hciottawa.ca </a> http://www.hciottawa.ca</p> 
    
    </Col>

   
    <Col lg="6">
    <h4 className="icon"><i className="fa fa-globe"></i></h4>
      <p><strong>The Seasons</strong> : North American tourists will find the months from Sept. to March climatically the most comfortable. India, being the vast sub-continent that it is, has varying temperatures from region to region. The monsoon season is between June and September and after that the rainfall is negligible. On the S.E. Coast (Madras, Mahabalipuram, etc.) there is no monsoon season and the rainy season is during the winter months. </p></Col>
    </Row>


    <Row className="mb-3"> 
    <Col lg="6" className="address"><p className="font-weight-bold location">Toronto</p> 
    <h6 className="mt-2 font-italic">Consulate-General of India</h6>
    <p className="font-italic"><i className="fa fa-home mr-1"></i> Suite 700, 365 Bloor Street East, Toronto, Ontario M4W 3L4</p>
    <p className="font-italic"><i className="fa fa-phone mr-1"></i> 416-960-0751</p>
    <p className="font-italic"><i className="fa fa-fax mr-1"></i> 416-960-9812</p>
    <p className="font-italic"><i className="fa fa-envelope mr-1"></i> cgindia@cgitoronto.ca</p>
    <p className="font-italic"><i className="fa fa-globe mr-1"></i> <a href="http://www.cgitoronto.ca/" target="_blank"> http://www.cgitoronto.ca </a></p>
    </Col>
    <Col lg="6">
    <h4 className="icon"><i className="fa fa-plane"></i></h4>
      <p><strong>The Airlines</strong> : The airlines concerned are not to be held responsible for any act, omission or event during the time passengers are not aboard their aircraft. The passenger tickets in use by the airlines, when issued, shall constitute the sole contract between airline and the purchaser of these tickets and/or passenger. These tours may be sold in conjunction with the services of any IATA or ATC airline. </p></Col>
    </Row>

    <Row className="mb-3"> 
    <Col lg="6" className="address"><p className="font-weight-bold location">Vancouver</p> 
    <h6 className="mt-2 font-italic">Consulate-General of India</h6>
    <p className="font-italic"><i className="fa fa-home mr-1"></i> 325 Howe Street, Vancouver, BC V6C 1Z7</p>
    <p className="font-italic"><i className="fa fa-phone mr-1"></i> 604-662-8811</p>
    <p className="font-italic"><i className="fa fa-fax mr-1"></i> 604-682-2471</p>
    <p className="font-italic"><i className="fa fa-envelope mr-1"></i> indiapscg@telus.net</p>
    <p className="font-italic"><i className="fa fa-globe mr-1"></i> <a href="http://www.cgivancouver.com/" target="_blank"> http://www.cgivancouver.com </a></p>
    </Col>
    <Col lg="6">
    <h4 className="icon"><i className="fa fa-line-chart"></i></h4>
      <p><strong>Rates</strong> : All rates mentioned in this brochure are in United States Dollars and based on tariffs, and rates of exchange in effect at time of publication. The International economic situation and the fluctuation of prices and exchange rates could modify these rates without notice; TAJ TOURS reserves the right to revise these prices depending on the economic situation. There will be no price increase, however, once the tour cost is paid to the tour operator.</p></Col>
    </Row>

    <Row className=" mb-3"> 
    <Col lg="6">
    <h4 className="icon"><i className="fa fa-medkit"></i></h4>
    <p><strong>Vaccinations</strong> : The India Government Health Authorities do not require arriving Canadians to have any vaccinations or inoculations except when coming from a yellow fever infested area, Details of where these are located may be obtained from the Department of Health offices in your area. We do, however, recommend cholera shots, anti malaria pills and keep tetanus shots up to date. These are a wise precautionary measure no matter where you plan to travel. Please consult your personal physician.</p>
    </Col>
    <Col lg="6">
    <h4 className="icon"><i className="fa fa-credit-card-alt"></i></h4>
      <p><strong>Deposits, Refunds & Final Payment</strong> : A Non-refundable deposit of $400.00 per person, is payable at time of booking. Cancellation must be received in writing not later than 30 days before commencement of tour services. Travel, baggage and medical insurance coverage are strongly recommended. No refunds within 30 days of departure date, No refunds for unused portion (s) or cancellation during the tour. No claim for refund can be accepted after tour has commenced. Final Payment is due 30 days before departure. </p></Col>
    </Row>

    <Row className=" mb-3"> 
    <Col lg="6"> 
    <h4 className="icon"><i className="fa fa-money"></i></h4>
    <p><strong>Currency</strong> : There are no restrictions as to the amount of foreign currency a visitor may bring into the country. However, if the sum is in excess of $10,000 it must be declared on the form provided by the authorities at the time of entry. Foreign currency may only be exchanged at banks or authorized money changers. Most hotels - certainly all those frequented by foreign tourists have this facility and the rate is the same as those given by the banks, It is strictly forbidden to either import or export Indian rupees. Exchanging foreign currency at other than the authorized places is a punishable offense. At the time of exchange a receipt is issued and this should be kept in the event that you have to reconvert to foreign currency upon departure. You can perform interactive foreign exchange rate calculations using the following website: <a href="http://www.xe.com/currencyconverter/" target="_blank">http://www.xe.com/ucc.</a></p>
    
    <h4 className="icon"><i className="fa fa-check-square-o"></i></h4>
    <p><strong>Customs Regulations</strong> : Visitors may bring into the country the following: 1 bottle of spirits (liquor or wine), 200 cigarettes or 50 cigars, sports equipment, personal effects, 2 still cameras, 25 rolls of film. Additional film may be brought in if duly entered on a tourist baggage re-export form. Cigarettes and film are quite readily available in the hotels, although more expensive than North America. Liquor is served in the hotels - all hotels have bars, dining rooms are licensed and liquor may be purchased in specialty stores. Please note that certain areas of India are "dry", however, the visitor upon obtaining a liquor permit from either the India Government Tourist Office in Toronto or at his hotel in India, may purchase drinks in licensed premises.</p>

    </Col>
    <Col lg="6"> 
    <h4 className="icon"><i className="fa fa-user"></i></h4>
    <p><strong>Responsibilities</strong> : Taj Tours their agents and officers and/or suppliers of services pursuant to or in connection with any itinerary, shall act only as agent of the suppliers in making arrangements for hotel, transportation, restaurants, or any other services and do not assume any liability what so ever for any injury, damage, death, loss, accident or delay to person or property due to an act of default of any hotel, carrier, restaurant, company or person rendering any other services included in the tour, or by force-majeure, Further, no responsibilities are accepted for any damage or delay due to sickness, pilferage, labour disputes, machinery breakdown, quarantine, government restraints, weather or other causes beyond their personal control. No responsibilities are accepted for any additional expenses, omissions, delays, rerouting or acts of any government or authority. The right is reserved to refuse or retain any member of the group should circumstances so demand. The schedules of the tours are subject to change without notice. The right is reserved to substitute hotels for hotels in similar categories. Tour members are held responsible for being in sufficiently good health to undertake the tour. The payments for a reservation on a trip shall constitute consent to all provisions as published in this tour brochure. The prices of these tours are based on prices effective March 1, 2007 and are subject to change without notice. While every effort is taken to provide the best quality of services, we can not guarantee North American or European standards. </p>
    
    <h4 className="icon"><i className="fa fa-suitcase"></i></h4>
    <p><strong>Tour Minimums</strong> : A minimum of only 2 passengers is required in order to operate the main tour. </p>
    </Col>
    </Row>

    

    
    </Container>
      </>
       );

export default GeneralInfo;
