import React, { Component } from "react";
import { Container, Col, Row, Form, Button, Alert } from "react-bootstrap";
import banner from "../images/banners/why-us.jpg";

import img1 from "../images/choose-tour.jpg";
import img2 from "../images/reserve-hote.jpg";
import img3 from "../images/reserve-flights.jpg";
import { useHistory } from "react-router-dom";


function CityEscapes(){
    const history = useHistory();
  
    const routeChange = () =>{ 
      let path = `/customTour`; 
      history.push(path);
    }
    const selectTour = () =>{ 
        let path = `/tours`; 
        history.push(path);
      }
  
  
  return(
    <>
        <section>
            <Row>
                <Col lg="12">
                    <img src={banner} className="img-fluid" />
                </Col>
            </Row>
        </section>
        <Container>
            <Row>
                <Col lg="12">
                    <h1 className="mb-4 mt-5">
                        City <strong> Escapes </strong>
                    </h1>
                </Col>

                <Col lg="12">
                    <p>Since 1980,Taj tour has been providing the ESCAPES . Escape may be just to one city or may involve many cities or destinations It may involve a small group or a family. Our experienced Consultants will be happy to help you to arrange your happy ESCAPES.</p>
                    <p className="customer-care">For booking click on <a onClick={routeChange}><span> custom tours </span> </a> Or call us at 613 731 0012 or 1855 604 8687 (tour)</p>
                </Col>
            </Row>

            <Row className="my-5">
                <Col lg="4 text-center">
                    <h4 className="mb-4">Choose a Tour</h4>
                    <img src={img1} className="img-fluid" />
                    <p className="reserve-info mt-3 ">There are plenty of package in our website</p>
                    <Button onClick={selectTour} className="reserve-btn" >select tours  <i className="fa fa-angle-double-right fa-lg ml-2"></i></Button>
                   
                </Col>

                <Col lg="4 text-center">
                <h4 className="mb-4 ">Reserve hotels</h4>
                    <img src={img2} className="img-fluid" />
                    <p className="reserve-info mt-3">Please click here to reserve hotel rooms for the specific dates.</p>
                    <Button href={'https://www.handatravel.com/Hotels.aspx'} target = "_blank" className="reserve-btn" >reserve hotel <i className="fa fa-angle-double-right fa-lg ml-2"></i></Button>
                </Col>

                <Col lg="4 text-center">
                <h4 className="mb-4 ">Reserve flights</h4>
                    <img src={img3} className="img-fluid" />
                    <p className="reserve-info mt-3">Please click here to book tickets instantly or call us 1-800-419-1277</p>
                    <Button className="reserve-btn" href={'https://www.handatravel.com/SearchBooking.aspx'} target = "_blank" >reserve flight <i className="fa fa-angle-double-right fa-lg ml-2"></i></Button>
                </Col>

            </Row>

        </Container>
    </>
);
  }
export default CityEscapes;
