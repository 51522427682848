import React, { Component, Fragment } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import banner from "../images/banners/contact-us.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import MapSection from './map' 
import $ from "jquery";


const location = {
  address: '2269 Riverside Drive Billings Bridge Plaza Ottawa, Ontario',
  lat: 45.385921,
  lng: -75.678221,
}

//const ContactUs = () => ();
class ContactUs extends Component {
  recaptchaRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      contactUs: {
        telephone: "",
        name: "",
        emailAddress: "",
        address: "",
        message: "",
      },
      recapthares: {
        success: false,
      },
      loading: false,
      validated: false,
      emailSuccess: false,
      buttonclicked: false,
    };
  }

  render() {
    let alertcontent;
    if (this.state.emailSuccess && this.state.buttonclicked) {
      alertcontent = (
        <Alert variant="success" className="mt-4">
          Thank you for contacting us. A team member will reply to you shortly.
        </Alert>
      );
    } else if (this.state.buttonclicked) {
      alertcontent = (
        <Alert variant="danger" className="mt-4">
          Sorry.. please try again or please contact us: 1-800-304-8714.
        </Alert>
      );
    }

    return (
      <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

        <Container>
          <Row>
            <Col>
              <h1 className="mb-4 mt-5">
                Contact <strong> Us </strong>
              </h1>
              
            </Col>
          </Row>

          <Row className="contact-details">
            <Col lg md="4" sm xs="12" className="basic">
              <p className="text-justify"><i className="fa fa-phone mr-2" aria-hidden="true"></i> Toll Free: 1-855-604-8687</p>
              <p className="text-justify"><i className="fa fa-phone mr-2" aria-hidden="true"></i> Local: 613 688 3227</p>
              <p className="text-justify"><i className="fa fa-fax mr-2" aria-hidden="true"></i> Fax: 1-866-570-1885</p>
              <p className="text-justify"><i className="fa fa-envelope mr-2" aria-hidden="true"></i> Email: sales@tajtour.com</p>
            </Col>

            <Col lg md="4" sm xs="12">
              <p className="text-center">
                <strong> <i className="fa fa-map-marker mr-2" aria-hidden="true"></i> Canada</strong>
              </p>
              <p className="text-center">2269 Riverside Drive</p>
              <p className="text-center">Billings Bridge Plaza </p>
              <p className="text-center">Ottawa, Ontario</p>
              <p className="text-center">Canada-K1H8K2</p>
            </Col>

            <Col lg md="4" sm xs="12">
              <p className="text-center">
                {" "}
                <strong> <i className="fa fa-map-marker mr-2" aria-hidden="true"></i> India</strong>
              </p>
              <p className="text-center">Bestfares Private Limited</p>
              <p className="text-center">D-151, Ground Floor</p>
              <p className="text-center">New Rajinder Nagar, Near HDFC Bank</p>
              <p className="text-center">New Delhi-110060</p>
              <p className="text-center">India</p>
            </Col>
          </Row>

          <Form
            className="my-5"
            noValidate
            validated={this.state.validated}
            onSubmit={this.sendEmail}
          >
            <Form.Row className="my-3">
              <Col>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  id="name"
                  type="text"
                  value={this.state.contactUs.name}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter name
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  placeholder="Email"
                  id="emailAddress"
                  type="email"
                  value={this.state.contactUs.emailAddress}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter email address
                </Form.Control.Feedback>
              </Col>
            </Form.Row>

            <Form.Row className="my-3">
              <Col>
                <Form.Label>Telephone</Form.Label>
                <Form.Control
                  placeholder="Telephone"
                  id="telephone"
                  type="text"
                  value={this.state.contactUs.telephone}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter telephone number
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  placeholder="Address"
                  id="address"
                  type="text"
                  value={this.state.contactUs.address}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter address
                </Form.Control.Feedback>
              </Col>
            </Form.Row>

            <Form.Row className="my-3">
              <Col>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Message"
                  id="message"
                  type="text"
                  value={this.state.contactUs.message}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter message
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Verification</Form.Label>
                <ReCAPTCHA
                  sitekey="6LfMkzUaAAAAAEXdEB_IhmE3hbHZ40aGHa93e5_G"
                  onChange={this.googleOnchange}
                  ref={(r) => (this.recaptcha = r)}
                />
              </Col>
            </Form.Row>

            <Button
              type="submit"
              className="mt-4"
              variant="primary"
              size="lg"
              block
            >
              {this.state.loading ? (
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                ""
              )}
              SEND
            </Button>
            {/* <div
              class="alert alert-danger mt-4"
              role="alert"
              id="lblmessageContact"
            ></div> */}

            {
              /*this.state.emailSuccess ? (
              <Alert variant="success" className="mt-4">
                Thank you for contacting us. A team member will reply to you
                shortly.
              </Alert>
            ) :(
              <Alert variant="danger" className="mt-4">
                Sorry.. please try again or please contact us: 1-800-304-8714.
              </Alert>
            )*/
              alertcontent
            }
          </Form>
        </Container>
        <div>
        <MapSection location={location} zoomLevel={15} />
        </div>
      </>
    );
  }

  handleChange = (event) => {
    //const { id, value } = event.target;

    let id = event.target.id;
    let value = event.target.value;

    this.setState((prevState) => ({
      contactUs: {
        ...prevState.contactUs,

        [id]: value,
      },
      recapthares: {
        ...prevState.recapthares,

        [id]: value,
      },
    }));
  };

  googleOnchange = (event) => {
    let response = event;

    fetch(`api/SampleData/VerifyGoogleCaptcha`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(response),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data != null) {
          let res = JSON.parse(data);

          this.setState({
            recapthares: { success: res.success },
          });
        }
        // console.log("SUCCESS STATUS IS " + this.state.recapthares.success);
      });
  };

  sendEmail = (event) => {
    this.setState({ buttonclicked: false });

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      console.log("Check validity is false");
    } else {
      event.preventDefault();
      console.log("Check validity is true");

      if (this.state.recapthares.success) {
        this.setState({ loading: true });
        fetch("api/SampleData/ContactUs", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: [
            JSON.stringify(
              this
                .state /*{
          contact: this.state.contactUs,
          employee: this.state.employee,
        }*/
            ),
            //JSON.stringify(this.state.employee),
          ],
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              loading: false,
              emailSuccess: true,
              validated: false,
              buttonclicked: true,
              contactUs: {
                telephone: "",
                name: "",
                emailAddress: "",
                address: "",
                message: "",
              },
            });
            this.recaptcha.reset();
            console.log(data);
          });
      } else {
        this.setState({
          emailSuccess: false,
          buttonclicked: true,
        });
      }
    }

    this.setState({ validated: true });
  };
}

export default ContactUs;
