import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
 
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';

class CalendarNew extends Component {
    state = {  } 
    constructor (props) {
        super(props)
       {/* this.state = {
          selectDate: new Date()
        };
        this.onDateChange = this.onDateChange.bind(this);
    */}
      
      }

    render() { 
        return (
            <DatePicker
            id={ this.props.id }
            name={ this.props.id }
            className="form-control"
            selected={ this.props.value }
            onChange={ this.props.onChangeValue }
            dateFormat="dd MMMM yyyy"
        />
       /* <input
        
        id={ this.props.id }
        name={ this.props.id }
        value={this.props.value}
        onChange={this.props.onChangeValue}
      />*/
        );
    }
    /*onDateChange(date) {
        this.setState({
          selectDate: date
        })
      }
    */
}
 {/* selected={ this.state.selectDate }
onChange={ this.onDateChange }*/}
export default CalendarNew;