import React, { Component } from "react";
import {Container, Col, Row, Button, Card, Accordion} from "react-bootstrap";
import banner from "../images/banners/exotic-nepal.jpg";

import img1 from "../images/kathmandu-airport.jpg";
import img2 from "../images/crowne-plaza.jpg";
import img3 from "../images/hotel-everest.jpg";

import img4 from "../images/kathmandu.jpg";
import img5 from "../images/swayambhunath.jpg";
import img6 from "../images/swayambhunath2.jpg";

import img7 from "../images/patan-city.jpg";
import img8 from "../images/patan-city2.jpg";
import img9 from "../images/refugee-camp.jpg";

import img10 from "../images/delhi2.jpg";
import img11 from "../images/hotel-centure.jpg";
import img12 from "../images/hotel-centure2.jpg";

import img13 from "../images/airport3.jpg";
import img14 from "../images/airport2.jpg";
import img15 from "../images/bangalore-airport.jpg";
import { useHistory } from "react-router-dom";
import exoticnepalpdf from "../doc/ExoticNepal.pdf";


function  ExoticNepal(){
  const history = useHistory();
  
  const routeChange = () =>{ 
    let path = `/customTour`; 
    history.push(path);
  }

  return(<>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    Exotic   <strong>Nepal </strong>
    </h1>
    
  </Col>

  <Col lg="12">
    <p className="text-justify mt-2 tour-para"><span>Nepal is a beautiful country </span> and a must visit for every nature lover. With this in mind, Taj Tour brings you Nepal Tours. Travel to Nepal with our well planned trip covering all the exotic tourist spots, including trekking. The journey is made comfortable with excellent travel arrangements and accommodation facilities.
    </p>

    
      <p className="text-right mb-5"><Button className="mr-5" onClick={routeChange}>Book Now</Button>
  <Button href={exoticnepalpdf} target = "_blank"> Read Itinerary Details </Button> </p>
  </Col>
</Row>

<Row>

<Col lg="6">
<Accordion  defaultActiveKey="0">
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
      Day 01

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <h5>Kathmandu</h5>
        <img src={img1} className="img-fluid mr-3" />
        <img src={img2} className="img-fluid mr-3" />
        <img src={img3} className="img-fluid mr-3" />
        <p className="text-justify">Transfer to Airport for flight to Kathmandu. Transfer to hotel, Soaltee Crowne Plaza or Everest.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
      Day 02

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
      <h5>Kathmandu</h5>
      <img src={img4} className="img-fluid mr-3" />
        <img src={img5} className="img-fluid mr-3" />
        <img src={img6} className="img-fluid mr-3" />
        <p className="text-justify">In the morning, take a sightseeing tour of Kathmandu and Swayambhunath. Afternoon is free to explore the city.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
      Day 03

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body>
      <h5>Kathmandu - Patan City</h5>
      <img src={img7} className="img-fluid mr-3" />
        <img src={img8} className="img-fluid mr-3" />
        <img src={img9} className="img-fluid mr-3" />
        <p className="text-justify">In the morning, take a tour of Patan City and the Tibetan Refugee camp. Afternoon is free.

</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  </Accordion>
</Col>

<Col lg="6">
<Accordion  defaultActiveKey="0">

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="4">
      Day 04
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="4">
      <Card.Body>
      <h5>Kathmandu - Delhi</h5>
      <img src={img10} className="img-fluid mr-3" />
        <img src={img11} className="img-fluid mr-3" />
        <img src={img12} className="img-fluid mr-3" />
        <p className="text-justify">Transfer for flight back to Delhi. On arrival check into Hotel.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="5">
      Day 05
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="5">
      <Card.Body>
      <h5>Delhi</h5>
      <img src={img13} className="img-fluid mr-3" />
        <img src={img14} className="img-fluid mr-3" />
        <img src={img15} className="img-fluid mr-3" />
        <p className="text-justify">In the morning, transfer to International airport for flight back.

</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

</Accordion>
</Col>
</Row>
</Container>
</>
);
}
export default ExoticNepal;
