import React, { Component } from "react";
import { Container, Col, Row, Button, Card, Accordion } from "react-bootstrap";
import banner from "../images/banners/india-explorer.jpg";

import img1 from "../images/qutab-minar.jpg";
import img2 from "../images/humayuns-tomb.jpg";
import img3 from "../images/laxmi-narayan.jpg";

import img4 from "../images/Jama Masjid.jpg";
import img5 from "../images/red-fort.jpg";
import img6 from "../images/mahatma-gandhi-cremate.jpg";

import img7 from "../images/tomb-emperor-akbar.jpg";
import img8 from "../images/tomb-ltmad-ud-daulah.jpg";
import img9 from "../images/taj-mahal.jpg";

import img10 from "../images/fatehpur-sikri.jpg";
import img11 from "../images/fatehpur-sikri2.jpg";
import img12 from "../images/fatehpur-sikri3.jpg";

import img13 from "../images/amber-fort1.jpg";
import img14 from "../images/city-palace.jpg";
import img15 from "../images/hawa-mahal.jpg";

import img16 from "../images/jagdish-temple.jpg";
import img17 from "../images/jagdish-temple.jpg";
import img18 from "../images/fateh-sagar-lake.jpg";

import img19 from "../images/wales-museum.jpg";
import img20 from "../images/india-gate.jpg";
import img21 from "../images/hanging-gardens.jpg";

import img22 from "../images/harbour.jpg";
import img23 from "../images/sculpted-cave.jpg";
import img24 from "../images/sculpted-cave2.jpg";
import { Link, withRouter } from "react-router-dom";
import indiaexlore from "../doc/IndiaExplorer.pdf";

class IndiaExplorer extends Component {
  render() {
    return (
      <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>
        <Container>
          <Row>
            <Col lg="12">
              <h1 className="mb-4 mt-5">
                India <strong> Explorer</strong>
              </h1>
            </Col>

            <Col lg="12">
              <p className="text-justify mt-2 tour-para">
                Explore the magnificent cities of India like Delhi, Bombay,
                Udaipur, Jaipur, and Agra. The tour takes you to the most famous
                tourist spots of all the cities included. The itinerary is well
                planned for a comf
              </p>
              <p className="text-justify tour-para">
                Check out our other tours :{" "}
                <Button onClick={() => this.nextPath("/mumbaitours")}>
                  Mumbai Tours
                </Button>{" "}
                |{" "}
                <Button onClick={() => this.nextPath("/grouptours")}>
                  Group Tours
                </Button>{" "}
                |{" "}
                <Button onClick={() => this.nextPath("/tailormadetours")}>
                  Tailor-Made Tours
                </Button>{" "}
                |{" "}
                <Button onClick={() => this.nextPath("/wildlifesafaris")}>
                  Wildlife Safaris
                </Button>
              </p>

              <p className="text-right mb-5">
                <Button className="mr-5" onClick={() => this.nextPath("/customTour")}>
                  Book Now
                </Button>
                <Button href={indiaexlore} target = "_blank"> Read Itinerary Details </Button>{" "}
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      Day 01
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <h5>Delhi</h5>
                      <img src={img1} className="img-fluid mr-3" />
                      <img src={img2} className="img-fluid mr-3" />
                      <img src={img3} className="img-fluid mr-3" />
                      <p className="text-justify">
                        Arrive Delhi in the night and transfer to hotel. OR
                        arrive in afternoon take a tour of the elegant avenues
                        of Edwin Lutyens spacious New Delhi visiting Qutab
                        Minar, Humayun's Tomb, India Gate, Laxmi Narain Temple,
                        President's House, Government Buildings, Parliament
                        House, and downtown Connaught Place.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      Day 02
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <h5>Delhi</h5>
                      <img src={img4} className="img-fluid mr-3" />
                      <img src={img5} className="img-fluid mr-3" />
                      <img src={img6} className="img-fluid mr-3" />
                      <p className="text-justify">
                        In the morning, take a tour of Old Delhi visiting the
                        imposing Red Fort, the Jama Masjid, largest mosque; the
                        fascinating bazaars and shops of Chandni Chowk and
                        Rajghat, Gandhi's cremation site.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                      Day 03
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <h5>Delhi - Agra</h5>
                      <img src={img7} className="img-fluid mr-3" />
                      <img src={img8} className="img-fluid mr-3" />
                      <img src={img9} className="img-fluid mr-3" />
                      <p className="text-justify">
                        Drive out to Agra this morning in a deluxe coach or
                        private air conditioned car. Enroute visit the tomb of
                        Emperor Akbar at Sikandra. In the afternoon visit the
                        tomb of Itmad-ud-Daulah, the Red Fort with its palaces
                        and mosques, and the highlight, Agra's most famous and
                        magnificent monument: the Taj Mahal.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                      Day 04
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <h5>Agra - Jaipur</h5>
                      <img src={img10} className="img-fluid mr-3" />
                      <img src={img11} className="img-fluid mr-3" />
                      <img src={img12} className="img-fluid mr-3" />
                      <p className="text-justify">
                        Drive this morning by coach or private air conditioned
                        car to Jaipur via the ghostly deserted red sandstone
                        city of Fatehpur-Sikri. On arrival in Jaipur check in at
                        hotel. Rest of the day is at leisure.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>

            <Col lg="6">
              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="4">
                      Day 05
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <h5>Jaipur</h5>
                      <img src={img13} className="img-fluid mr-3" />
                      <img src={img14} className="img-fluid mr-3" />
                      <img src={img15} className="img-fluid mr-3" />
                      <p className="text-justify">
                        Drive to the fabled Amber Fort and make ascent on
                        elephants. In the afternoon visit the Maharaja's City
                        Palace, with its fabulous collection of miniature
                        paintings; the Royal Observatory; the Hawa Mahal and
                        drive past the Albert Hall; the Ram Niwas Gardens and
                        the business and residential area of this enchanting
                        city.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="5">
                      Day 06
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body>
                      <h5>Jaipur - Udaipur</h5>
                      <img src={img16} className="img-fluid mr-3" />
                      <img src={img17} className="img-fluid mr-3" />
                      <img src={img18} className="img-fluid mr-3" />
                      <p className="text-justify">
                        Transfer to the airport for the flight to Udaipur. In
                        the afternoon visit the opulent Palace of the Maharana
                        and the beautiful Sahelion-Ki-Bari, Jagdish Temple and
                        the shimmering Fateh Sagar Lake.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="6">
                      Day 07
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="6">
                    <Card.Body>
                      <h5>Udaipur - Bombay</h5>
                      <img src={img19} className="img-fluid mr-3" />
                      <img src={img20} className="img-fluid mr-3" />
                      <img src={img21} className="img-fluid mr-3" />
                      <p className="text-justify">
                        Drive to airport to fly to Bombay. In the afternoon
                        drive around bustling Bombay; visit the Prince of Wales
                        Museum; see the Gateway of India; the splendid Hanging
                        Gardens and the Crawford Market.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="7">
                      Day 08
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="7">
                    <Card.Body>
                      <h5>Bombay</h5>
                      <img src={img22} className="img-fluid mr-3" />
                      <img src={img23} className="img-fluid mr-3" />
                      <img src={img24} className="img-fluid mr-3" />
                      <p className="text-justify">
                        This morning cruise through busy Bombay Harbour and
                        visit the sculpted cave temples on nearby Elephanta
                        Island, the site of ancient rock carved Hindu temples
                        dedicated to the god Shiva. Rest of the day is free.
                        Later transfer to the airport to connect with outbound
                        flight.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  nextPath(path) {
    this.props.history.push(path);
  }
}

export default withRouter(IndiaExplorer);
