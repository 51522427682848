import React, { Component } from "react";
import {Container, Col, Row, Button} from "react-bootstrap";
import banner from "../images/banners/group-tours.jpg";


const   GroupTours = () => ( 
  <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    <strong>Group </strong> Tours  
    </h1>
    
  </Col>

  <Col lg="12">
  
<p className="text-justify mt-2 tour-para">
Vacation is more fun when you have a big group for company. Many locations in India are perfect to be visited in groups, and enjoy the various activities one can do with friends and family. Group Tours arranged by our efficient team lets you soak in the essence of the place you tour completely in the company of your favorite fun-loving people.</p>

<p className="text-justify tour-para">Taj Tour employs a team of expert professionals who can plan you the best India Group Tours ever. Small group tours, or big group tours, we take care of them all. Our experts not only make the arrangements for the group, but we also suggest to you new and exotic locations for a memorable tour. With our years of experience in India Group Tours we can ensure that you experience the very best of India's rich culture and history on your holiday.</p>

<p className="text-justify tour-para">All you have to do is let us know the kind of trip you are looking out for like beach, hill station, historical etc; the places you would like to visit, and the time you want to spend there, the standard of accommodation you need, the travel mode you want, etc. We will take care of all the bookings that need to be made for each requirement. You can completely relax for the entire trip with no worries about the arrangements to be made.</p>

<p className="text-justify tour-para">Our India Group Tours itineraries hold a careful arrangement of essential sights such as the mesmerizing Taj Mahal, visit of the royal pink city of Jaipur, trip to the holy ghats of Varanasi, the beaches of Goa, trek though the majestic Himalayas, ride in the backwaters of Kerala and much more, all within your budget. Yes! We truly believe that the combination of fun and luxury should come at an affordable cost acceptable to all</p>

<p className="text-justify tour-para">Our Group Tours also let you meet new people and make lasting friends for life. Our tour managers take care of all daily activities, hotel check-ins, and provide general information about each area that you'll visit. Also, the tour guides make sure that you get the complete essence of the site in your visit with its history and specialty.</p>

<p className="text-justify tour-para">Moreover, you can enjoy the various activities each place has to offer from river rafting, to paragliding, mountain trekking to boating. Try all of them to have lovely memories of the experience.</p>

    
    <p className="text-right mb-5"><Button className="mr-5" href="" target="_blank">Book Now</Button> 
    <Button href=""> Read Itinerary Details </Button> </p>
  </Col>
</Row>


</Container>
</>
);

export default GroupTours;
