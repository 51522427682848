import React, { Component } from "react";
import { Container, Col, Row, Tab, Nav, TabContainer,TabContent } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import banner from "../images/banners/price.jpg";

const Price  = () => ( 

<>
   <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>
<Container>
<Row className="why-us mb-5">
  <Col lg="12">
    <h1 className="mb-2 mt-5">
    <strong> Date & </strong> Prices
    </h1>
  </Col>

</Row>

     
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
  <Row className="price-details">
    <Col sm={3} className="price-tab">
      <Nav variant="pills" className="flex-column">
        <Nav.Item>
          <Nav.Link eventKey="first">Glimpses Of India</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="second">Golden Triangle</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="third">Magic of India</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="fourth">Tranquil Kerala</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="fifth">Surf and Sand</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="sixth">Rajasthan Explorer</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="seventh">India Explorer</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="eighth">Northeastern Hills</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="nineth">Exotic Nepal</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="tenth">Taj Highlights</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="eleventh">Best of India</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="twelfth">Romancing the Taj</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="thirtieth">Royal Rajasthan on Wheels</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="fourteenth">Taj & Wilderness of North India</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="fifteenth">Essence of Sri Lanka</Nav.Link>
        </Nav.Item>
      </Nav>
    </Col>
    <Col sm={9} className="price-info">
    
      <Tab.Content>

        {/* Glimpses Of India */}
        <Tab.Pane eventKey="first">
        <Row>
          <Col><p className="text-right font-weight-bold"><i className="fa fa-calendar-o mr-2"></i> 10 Day Tour </p></Col>
          <Row>
          <Col lg="6" className="deluxe-pack">
            <h3>Deluxe Package</h3>
            <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - Le Meridien | Jaipur - Hilton Jaipur | Agra - Double tree by Hilton | Khajuraho - Radisson Jass | Varanasi - Radisson</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 1697 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 3099 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">16th April' 2018 to 30th Sept 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to Monuments and Museums.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
             </div>
          </Col>
          <Col lg="6" className="firstclass-pack">
          <h3>First Class Package</h3>
          <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - The Surya | Jaipur - Holiday Inn City Centre Agra - Four Points by Sheraton | Khajuraho - Ramada | Varanasi - Rivatas by Ideal</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 1549 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 2816 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 April' 18 to 30 September 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to Monuments and Museums.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
            </div>
          </Col>
          <h6 className="pl-3" >* Cost not valid during Christmas and New Year period. Please call in for pricing</h6>
          </Row>
        </Row>
        </Tab.Pane>

        {/* Golden Triangle */}
        <Tab.Pane eventKey="second">
        <Row>
          <Col><p className="text-right font-weight-bold"><i className="fa fa-calendar-o mr-2"></i> 06 Day Tour </p></Col>
          <Row>
          <Col lg="6" className="deluxe-pack">
            <h3>Deluxe Package</h3>
            <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - Le Meridien | Agra - Double Tree by Hilton | Jaipur - Hilton</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 836 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 1463 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 April' 18 to 30 September 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to Monuments and Museums.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
             </div>
          </Col>
          <Col lg="6" className="firstclass-pack">
          <h3>First Class Package</h3>
          <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - The Surya | Agra - Four Point by Sheraton | Jaipur - Holiday Inn City Center</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 734 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 1316 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 April' 18 to 30 September 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to Monuments and Museums.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
            </div>
          </Col>
          <h6 className="pl-3" >* Cost not valid during Christmas and New Year period. Please call in for pricing</h6>
          </Row>
        </Row>
        </Tab.Pane>

        {/* Magic of India */}
        <Tab.Pane eventKey="third">
        <Row>
          <Col><p className="text-right font-weight-bold"><i className="fa fa-calendar-o mr-2"></i> 10 Day Tour </p></Col>
          <Row>
          <Col lg="6" className="deluxe-pack">
            <h3>Deluxe Package</h3>
            <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - Le Meridien | Udaipur - Fateh Niwas Jodhpur - Ajit Bhawan | Jaipur - Hilton Jaipur | Agra - Double Tree by Hilton Khajuraho - Radisson Jass Hotel | Varanasi - Radisson Hotel</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 1983 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 3672 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 April 18 to 30 th September 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to Monuments and Museums.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
             </div>
          </Col>
          <Col lg="6" className="firstclass-pack">
          <h3>First Class Package</h3>
          <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - Le Meridien | Udaipur - Fateh Niwas Jodhpur - Ajit Bhawan | Jaipur - Hilton Jaipur | Agra - Double Tree by Hilton Khajuraho - Radisson Jass Hotel | Varanasi - Radisson Hotel</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 1396 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 2515 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 April 18 to 30 th September 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to Monuments and Museums.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
            </div>
          </Col>
          <h6 className="pl-3" >* Cost not valid during Christmas and New Year period. Please call in for pricing</h6>
          </Row>
        </Row>
        </Tab.Pane>
        
        {/* Tranquil Kerala */}
        <Tab.Pane eventKey="fourth">
        <Row>
          <Col><p className="text-right font-weight-bold"><i className="fa fa-calendar-o mr-2"></i> 08 Day Tour </p></Col>
          <Row>
          <Col lg="6" className="deluxe-pack">
            <h3>Deluxe Package</h3>
            <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Cochin - Vivanta By Taj | Munnar - Windermere Estate | Periyar - Spice Village Kumarakom - Coconut Lagoon | Kovalam - Vivanta By Taj</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 1436 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 2559 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 April' 18 to 30 September 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">Sightseeing with a local English speaking guide.</p>
            <p className="details">Exploring backwater canals on a houseboat.</p>
            <p className="details">Visit The Dutch Palace & Jewish Synagogue.</p>
            <p className="details">Visit the Santa Cruz basilica built in 1557.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
             </div>
          </Col>
          <Col lg="6" className="firstclass-pack">
          <h3>First Class Package</h3>
          <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Cochin - Vivanta By Taj | Munnar - Windermere Estate | Periyar - Spice Village Kumarakom - Coconut Lagoon | Kovalam - Vivanta By Taj</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 1089 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 1931 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 April' 18 to 30 September 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">Sightseeing with a local English speaking guide.</p>
            <p className="details">Exploring backwater canals on a houseboat.</p>
            <p className="details">Visit The Dutch Palace & Jewish Synagogue.</p>
            <p className="details">Visit the Santa Cruz basilica built in 1557.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
            </div>
          </Col>
          <h6 className="pl-3" >* Cost not valid during Christmas and New Year period. Please call in for pricing</h6>
          </Row>
        </Row>
        </Tab.Pane>

        {/* Surf and Sand */}
        <Tab.Pane eventKey="fifth">
        <Row>
          <Col><p className="text-right font-weight-bold"><i className="fa fa-calendar-o mr-2"></i> 08 Day Tour </p></Col>
          <Row>
          <Col lg="6" className="deluxe-pack">
            <h3>Deluxe Package</h3>
            <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Mumbai - ITC Grand Central | Cochin - Crown Plaza Kochi Goa - The Zuri White Sand Goa Resort</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 1506 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 2693 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 April to 30 September 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to Monuments and Museums as per Itinerary</p>
            <p className="details">Portage at Airports and Hotels</p>
            <p className="details">Services of local English speaking guide.</p>
            <p className="details">Kathakali Dance show in Cochin, Day houseboat cruise at Alleppey, & Boat excursion to Elephanta Island</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
             </div>
          </Col>
          <Col lg="6" className="firstclass-pack">
          <h3>First Class Package</h3>
          <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Mumbai - ITC Grand Central | Cochin - Crown Plaza Kochi Goa - The Zuri White Sand Goa Resort</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 1238 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 2300 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 April to 30 September 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to Monuments and Museums as per Itinerary</p>
            <p className="details">Portage at Airports and Hotels</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
            </div>
          </Col>
          <h6 className="pl-3" >* Cost not valid during Christmas and New Year period. Please call in for pricing</h6>
          </Row>
        </Row>
        </Tab.Pane>

        {/* Rajasthan Explorer */}
        <Tab.Pane eventKey="sixth">
        <Row>
          <Col><p className="text-right font-weight-bold"><i className="fa fa-calendar-o mr-2"></i> 15 Day Tour </p></Col>
          <Row>
          <Col lg="6" className="deluxe-pack">
            <h3>Deluxe Package</h3>
            <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - Le Meridien | Mandawa - Castle Mandawa | Bikaner - Laxmi Niwas Palace Jaisalmer - Fort Rajwada | Jodhpur - Ajit Bhawan | Udaipur - Fateh Niwas Pushkar - Jagat Palace | Jaipur - Hilton Jaipur | Agra - Double Tree by Hilton</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 2221 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 3906 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 April' 18 to 30 th September' 18</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to the places of visit as per itinerary.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide..</p>
            <p className="details">Kathakali Dance show in Cochin, Day houseboat cruise at Alleppey, & Boat excursion to Elephanta Island</p>
            <p className="details">1 hr CAMEL ride on the sand dunes while visiting SAM in Jaisalmer</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
             </div>
          </Col>
          <Col lg="6" className="firstclass-pack">
          <h3>First Class Package</h3>
          <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - Le Meridien | Mandawa - Castle Mandawa | Bikaner - Laxmi Niwas Palace Jaisalmer - Fort Rajwada | Jodhpur - Ajit Bhawan | Udaipur - Fateh Niwas Pushkar - Jagat Palace | Jaipur - Hilton Jaipur | Agra - Double Tree by Hilton</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 2088 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 3636 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 April' 18 to 30 th September' 18</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to the places of visit as per itinerary.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">Rickshaw/ Bicycle ride at Delhi.</p>
            <p className="details">Kathakali Dance show in Cochin, Day houseboat cruise at Alleppey, & Boat excursion to Elephanta Island</p>
            <p className="details">1 hr CAMEL ride on the sand dunes while visiting SAM in Jaisalmer</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
            </div>
          </Col>
          <h6 className="pl-3" >* Cost not valid during Christmas and New Year period. Please call in for pricing</h6>
          </Row>
        </Row>
        </Tab.Pane>
        
        {/* India Explorer */}
        <Tab.Pane eventKey="seventh">
        <Row>
          <Col><p className="text-right font-weight-bold"><i className="fa fa-calendar-o mr-2"></i> 09 Day Tour </p></Col>
          <Row>
          <Col lg="6" className="deluxe-pack">
            <h3>Deluxe Package</h3>
            <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - Le Meridien | Agra - Radisson Hotel Jaipur - Hilton | Udaipur - Fateh Niwas | Mumbai - ITC Grand Central</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 1795 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 3240 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 April to 30 September 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to the places of visit as per itinerary.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
             </div>
          </Col>
          <Col lg="6" className="firstclass-pack">
          <h3>First Class Package</h3>
          <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - The Suryaa | Agra - Four Points by Sheraton Jaipur - Holiday Inn City Center | Udaipur - Ananta Resort & Spa | Mumbai - Suba Palace</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 1556 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 2820 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 April to 30 September 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to the places of visit as per itinerary.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
            </div>
          </Col>
          <h6 className="pl-3" >* Cost not valid during Christmas and New Year period. Please call in for pricing</h6>
          </Row>
        </Row>
        </Tab.Pane>

        {/* Northeastern Hills */}
        <Tab.Pane eventKey="eighth">
        <Row>
          <Col><p className="text-right font-weight-bold"><i className="fa fa-calendar-o mr-2"></i> 08 Day Tour </p></Col>
          <Row>
          <Col lg="6" className="deluxe-pack">
            <h3>Deluxe Package</h3>
            <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - Le Meridien | Darjeeling - May Fair Hill Resort Gangtok - Norkhill | Kalimpong - Silver Oak | Delhi - Le Meridien</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 1199 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 2300 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 October' 17 to 31 March 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to the places of visit as per itinerary.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
             </div>
          </Col>
          <Col lg="6" className="firstclass-pack">
          <h3>Superior Package</h3>
          <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - The Suryaa | Darjeeling - New Elgin Gangtok - Norkhill | Kalimpong - Silver Oak | Delhi - The Suryaa</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 1328 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 2564 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 October' 17 to 31 March 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to the places of visit as per itinerary.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
            </div>
          </Col>
          <h6 className="pl-3" >* Cost not valid during Christmas and New Year period. Please call in for pricing</h6>
          </Row>
        </Row>
        </Tab.Pane>

        {/* Exotic Nepal */}
        <Tab.Pane eventKey="nineth">
        <Row>
          <Col><p className="text-right font-weight-bold"><i className="fa fa-calendar-o mr-2"></i> 05 Day Tour </p></Col>
          <Row>
          <Col lg="6" className="deluxe-pack">
            <h3>Deluxe Package</h3>
            <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - Le Meridien | Kathmandu - Soaltee Crown Plaza</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 639 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 1186 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 April to 30 September 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to the places of visit as per itinerary.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
             </div>
          </Col>
          <Col lg="6" className="firstclass-pack">
          <h3>First Class Package</h3>
          <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - The Suryaa | Kathmandu - Hotel Himalaya</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 522 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 922 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 April to 30 September 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to the places of visit as per itinerary.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
            </div>
          </Col>
          <h6 className="pl-3" >* Cost not valid during Christmas and New Year period. Please call in for pricing</h6>
          </Row>
        </Row>
        </Tab.Pane>
 
        {/* Taj Highlights */}
        <Tab.Pane eventKey="tenth">
        <Row>
          <Col><p className="text-right font-weight-bold"><i className="fa fa-calendar-o mr-2"></i> 03 Day Tour </p></Col>
          <Row>
          <Col lg="6" className="deluxe-pack">
            <h3>Deluxe Package</h3>
            <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - Le Meridien | Agra - Double tree by Hilton</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 313 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 553 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01 April 18 to 30 th September 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transportation by air-conditioned transportation</p>
            <p className="details">Tonga ride (horse carriage) in Agra from nearest parking of Taj Mahal.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
             </div>
          </Col>
          <Col lg="6" className="firstclass-pack">
          <h3>First Class Package</h3>
          <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - The Surya | Agra - Four Points by Sheraton</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 277 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 491 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">01st April 18 to 30 th September 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transportation by air-conditioned transportation</p>
            <p className="details">Tonga ride (horse carriage) in Agra from nearest parking of Taj Mahal.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
            </div>
          </Col>
          <h6 className="pl-3" >* Cost not valid during Christmas and New Year period. Please call in for pricing</h6>
          </Row>
        </Row>
        </Tab.Pane>

        {/* Best of India */}
        <Tab.Pane eventKey="eleventh">
        <Row>
          <Col><p className="text-right font-weight-bold"><i className="fa fa-calendar-o mr-2"></i> 22 Day Tour </p></Col>
          <Row>
          <Col lg="6" className="deluxe-pack">
            <h3>Deluxe Package</h3>
            <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - Le Meridien | Varanasi - Radisson | Khajuraho - Radisson Jass Hotel Agra - Double Tree by Hilton | Jaipur - Hilton / Udaipur - Fateh Niwas Mumbai - ITC Grand Central | Chennai - Vivanta by Taj Connemara / Madurai - TheGateway Hotel Thekkady - Shalimar Spice Garden | Cochin - Crown Plaza / Mysore - Radisson BLU Plaza Bangalore - ITC Winsdor | Goa - The Zuri White Sand Goa Resort</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 4074 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 7554 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">16 April' 2018 to 30 Sept 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Entrance fees to the places of visit as per itinerary.</p>
            <p className="details">Rickshaw/Bicycle ride at Delhi.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
             </div>
          </Col>
          <Col lg="6" className="firstclass-pack">
          <h3>First Class Package</h3>
          <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - The Suryaa | Varanasi - Rivatas by Ideal | Agra - Four Points by Sheraton Khajuraho - Ramada | Jaipur - Holiday Inn City Center | Udaipur - Ananta Resort & Spa Mumbai - Suba Palace | Chennai - The Raintree Hotel St. Mary | Thekkady - The Elephant Court Madurai - Sangam | Cochin - Casino Hotel | Mysore - Wildflower Spa & Resort Bangalore - The Park Hotel | Goa - Goan Heritage</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 3636 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 6732 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">16 April' 2018 to 30 Sept 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Entrance fees to the places of visit as per itinerary.</p>
            <p className="details">Rickshaw/Bicycle ride at Delhi.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
            </div>
          </Col>
          <h6 className="pl-3" >* Cost not valid during Christmas and New Year period. Please call in for pricing</h6>
          </Row>
        </Row>
        </Tab.Pane>

        {/* Romancing the Taj */}
        <Tab.Pane eventKey="twelfth">
        <Row>
          <Col><p className="text-right font-weight-bold"><i className="fa fa-calendar-o mr-2"></i> 08 Day Tour </p></Col>
          <Row>
          <Col lg="6" className="deluxe-pack">
            <h3>Deluxe Package</h3>
            <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - Le Meridien | Agra - Double Tree by Hilton Jaipur - Hilton Jaipur | Delhi - Le Meridien</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 941 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 1715 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">16 April' 2018 to 30th Sept 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">Accommodation of 07 nights on TWIN sharing basis at all hotels</p>
            <p className="details">Daily buffet breakfast at all hotels.</p>
            <p className="details">Transportation by air-conditioned vehicle as per the itinerary.</p>
            <p className="details">Rickshaw ride in Delhi & Tonga ride in Agra.</p>
            <p className="details">Elephant ride at Amber fort in Jaipur.</p>
            <p className="details">Services of English speaking local guides during sightseeing tours.</p>
            <p className="details">Entrance fees to the places of visit as per the itinerary. All applicable taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
             </div>
          </Col>
          <Col lg="6" className="firstclass-pack">
          <h3>First Class Package</h3>
          <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - The Surya | Agra - Four Points by Sheraton Jaipur - Holiday Inn City Center | Delhi - The Surya</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 830 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 1500 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">16 April' 2018 to 30th Sept 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to Monuments and Museums.</p>
            <p className="details">Portage at Airports and Hotels</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
            </div>
          </Col>
          <h6 className="pl-3" >* Cost not valid during Christmas and New Year period. Please call in for pricing</h6>
          </Row>
        </Row>
        </Tab.Pane>

        {/* Royal Rajasthan on Wheels */}
        <Tab.Pane eventKey="thirtieth">
        <Row>
          <Col><p className="text-right font-weight-bold"><i className="fa fa-calendar-o mr-2"></i> 08 Day Tour </p></Col>
          <Row>
          <Col lg="6" className="deluxe-pack">
            <h3>Deluxe Package</h3>
            <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - Jodhpur - Chittorgarh - Udaipur - Ranthambore - Jaipur - Khajuraho - Varanasi - Agra - Delhi</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost </p>
            <p className="details">$1700 USD + Taxes per suite per night</p>

            {/* <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 7554 USD</p> */}

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">Specific dates of Departure October 2015 - March 2016(Sunday)</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">All Travel via Royal Rajasthan on Wheels & Accommodation</p>
            <p className="details">Soft beverages,Complimentary tea coffee and mineral water on board.</p>
            <p className="details">Butler service,transport & English speaking guide services for sightseeing.</p>
            <p className="details">Entrance Fees(excluding video camera fee) for monuments,Palaces & Entertainment.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
             </div>
          </Col>
          <Col lg="6" className="firstclass-pack">
          <h3>First Class Package</h3>
          <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - Jodhpur - Chittorgarh - Udaipur - Ranthambore - Jaipur - Khajuraho - Varanasi - Agra - Delhi</p>

            <p className="header"><i className="fa fa-users mr-2"></i>  Cost Double/Twin</p>
            <p className="details">$625 USD + Taxes P.P night</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$875 USD per night</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">Specific dates of Departure October 2015 - March 2016(Sunday)</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">All Travel via Royal Rajasthan on Wheels & Accommodation</p>
            <p className="details">Soft beverages,Complimentary tea coffee and mineral water on board.</p>
            <p className="details">Butler service,transport & English speaking guide services for sightseeing.</p>
            <p className="details">Entrance Fees(excluding video camera fee) for monuments,Palaces & Entertainment.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
            </div>
          </Col>
          <h6 className="pl-3" >* Cost not valid during Christmas and New Year period. Please call in for pricing</h6>
          </Row>
        </Row>
        </Tab.Pane>

        {/* Taj & Wilderness of North India */}
        <Tab.Pane eventKey="fourteenth">
        <Row>
          <Col><p className="text-right font-weight-bold"><i className="fa fa-calendar-o mr-2"></i> 13 Day Tour </p></Col>
          <Row>
          <Col lg="6" className="deluxe-pack">
            <h3>Deluxe Package</h3>
            <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - Le Meridien | Agra - Radisson | Chambal - Chambal Safari Lodge Bharatpur - Laxmi Vilas Palace | Ranthambore - Taj Sawai Madhopur | Jaipur - Hilton Jaipur</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 2730 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 4536 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">16 April to 30 September 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to the places of visit as per itinerary.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
             </div>
          </Col>
          <Col lg="6" className="firstclass-pack">
          <h3>First Class Package</h3>
          <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Delhi - The Surya | Agra - Four Points by Sheraton | Chambal - Chambal Safari Lodge Bharatpur - Laxmi Vilas Palace | Ranthambore - Tiger Den Resort | Jaipur - Holiday Inn City Center</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 2544 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 4194 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">16 April to 30 September 2018</p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to the places of visit as per itinerary.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
            </div>
          </Col>
          <h6 className="pl-3" >* Cost not valid during Christmas and New Year period. Please call in for pricing</h6>
          </Row>
        </Row>
        </Tab.Pane>

        {/* Essence of Sri Lanka */}
        <Tab.Pane eventKey="fifteenth">
        <Row>
          <Col><p className="text-right font-weight-bold"><i className="fa fa-calendar-o mr-2"></i> 08 Day Tour </p></Col>
          <Row>
          <Col lg="6" className="deluxe-pack">
            <h3>Deluxe Package</h3>
            <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Colombo - The Kingsbury | Habarana - Cinnamon Lodge Kandy - Cinnamon Citadel | Nuwara Eliya - Grand Hotel | Beruwala - Cinnamon Bey Beruwala</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 1464 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 2652 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">16 th April' 18 to 30 th Sep' 18 <span className="special"> [Supplement for peak season i.e. from 01 st Jul' 18 to 31 st Aug' 18 would be applicable] </span></p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to the places of visit as per itinerary.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
             </div>
          </Col>
          <Col lg="6" className="firstclass-pack">
          <h3>First Class Package</h3>
          <p className="header"><i className="fas fa fa-map-marker mr-2"></i> locations</p>
            <p className="details">Colombo - Ozo Colombo | Habarana - Aliya Resort Kandy - Ozo Kandy | Nuwara Eliya - Araliya Green Hills | Beruwela/ Bentota - Bentota Beach Hotel</p>

            <p className="header"><i className="fa fa-users mr-2"></i> Cost per person based on Twin Share</p>
            <p className="details">$ 1332 USD</p>

            <p className="header"><i className="fa fa-user mr-2"></i> Single Passenger</p>
            <p className="details">$ 2376 USD</p>

            <p className="header"><i className="fa fa-plug mr-2"></i> A/C</p>
            <p className="details">Included</p>

            <p className="header"><i className="fa fa-calendar mr-2"></i> Validity Dates</p>
            <p className="details">16 th April' 18 to 30 th Sep' 18 <span className="special"> [Supplement for peak season i.e. from 01 st Jul' 18 to 31 st Aug' 18 would be applicable] </span></p>

            <p className="header"><i className="fa fa-suitcase mr-2"></i> Tour Prices Include</p>
            <div className="options">
            <p className="details">American (full) breakfast daily.</p>
            <p className="details">Transfers and sightseeing tours by Car/Coach.</p>
            <p className="details">Entrance fees to the places of visit as per itinerary.</p>
            <p className="details">Portage at Airports and Hotels.</p>
            <p className="details">Services of English speaking guide.</p>
            <p className="details">All local taxes.</p>
            <p className="details">Services of our Taj Tours <a href="http://www.tajtour.com/contact-us.php"> Office in Delhi </a></p>
            </div>
          </Col>
          <h6 className="pl-3" >* Supplement for peak season applies : 01 July 2016 - 31 August 2016</h6>
          </Row>
        </Row>
        </Tab.Pane>
      </Tab.Content>
    </Col>
  </Row>
</Tab.Container> 

           

    

</Container>
  </>
   );
export default Price;

