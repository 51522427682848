import React, { Component } from "react";
import {Container, Col, Row, Button} from "react-bootstrap";
import banner from "../images/banners/mumbai-tours.jpg";


const   MumbaiTours = () => ( 
  <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    <strong>Mumbai </strong> Tours  
    </h1>
    
  </Col>

  <Col lg="12">
  

    <p className="text-justify  mt-2 tour-para">
Mumbai - the greatest metropolitan city of India is well worth a visit. In spite of the daily hustle and bustle, the city of Mumbai is a beautiful city to tour. Mumbai has more to offer than career building, business and living the tough life. A Mumbai City Tour reveals all the wonders of the city</p>

<p className="text-justify our-para">The city of Mumbai is nestled within historic, economic, and scenic beauty. Mumbai has evidence of the British Raj with majestic architecture that take you into the freedom struggle era. The gateway of India, Prince of Wales museum, and more, give you the feeling of the early 1900's. Famous temples and shrines like Mahalaxmi Temple, Haji Ali, and Siddhi Vinayak show that the city is close to its spiritual side as well. Other tourist attractions are Nariman Point, Marine Drive, Hanging Garden, Kamla Nehru Park, Chowpatty Beach, Taraporwalla Aquarium, World Trade Centre, Jehangir Art Gallery, Bandra - Worli Sea Link, Race Course, Nehru Planetarium, and much more.</p>

<p className="text-justify our-para">Mumbai is also famous for the presence of Bollywood. There are Mumbai Tours that will also take you to the residences of famous Bollywood actors and actresses. This is a very popular attraction among tourists now-a-days</p>

<p className="text-justify our-para">Did we mention that Mumbai is also a shopper's paradise. Being a major metropolis in India, Mumbai is home to some of the world's leading brands of clothes, accessories, electronics, and more. There are many malls that can be raided by shopaholics, but the famous shopping areas in Mumbai are Fashion street, Linking road, Hill Road, and Crawford market. These have been satisfying the city's need for fashion and style for many years and are famous for their rock bottom prices and bargaining.</p>

<p className="text-justify our-para">The best time for a Mumbai City Tour would be any day, but the city tends to show different sides of itself during occasions like rains, Ganesh Chaturti, Dussehra, Diwali, and New Years.</p>

<p className="text-justify our-para">Call us for all the requirements of your Mumbai City Tour. We are experts at making the required travel, accommodation, and sightseeing arrangements for you and your group.</p>

    
    <p className="text-right mb-5"><Button className="mr-5" href="http://www.tajtour.com/docs/IndiaExplorer.pdf" target="_blank">Book Now</Button> 
    <Button href=""> Read Itinerary Details </Button> </p>
  </Col>
</Row>


</Container>
</>
);

export default MumbaiTours;
