import React, { Component } from "react";
import {Container, Col, Row, Form, Button, Alert } from "react-bootstrap";
import banner from "../images/banners/why-us.jpg";
const Whyus = () => ( 
<>
   <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>
<Container>
<Row className="why-us mb-5">
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    Why Choose <strong>Taj Tour </strong>
    </h1>
  </Col>

  <Col lg="12">
  <h4 className="mb-3 mt-5">Our Company</h4>
    <p>Taj Tour is a privately owned company that has been selling tours to India since 1976. We are a direct Tour Operator, which means that when you deal with us there is no middleman. In addition, unlike with the large companies we work with you personally. You as a customer can contact our representative directly and arrange a tour itinerary that is best suitable for you.</p>
  </Col>

  <Col lg="12">
  <h4 className="mb-3 mt-4">Custom Made Tours</h4>
    <p>In addition to the prepared tour packages that we offer, we can also prepare a personalized tour that fits you. Whatever the need may be want to visit specific sites? Or may be arrange a tour with emphasis on arts or organize a group tour for seniors? Perhaps you only want to see places of cultural significance? We go every step of the way with you, listening to your concerns and preferences, and arrange it directly with our contacts on location. Plus, you only need a minimum of two passengers to operate the tour as all our tour is private escorted tours.</p>
  </Col>

  <Col lg="12">
  <h4 className="mb-3 mt-4">License</h4>
    <p>We are registered with the Travel Industry Council of Ontario ( TICO ). That means that your trip is organized by registered travel consultants and you are protected as a consumer.</p>
  </Col>

  <Col lg="12">
  <h4 className="mb-3 mt-4">Years of Experience</h4>
    <p>We have been creating tours to India & Sub Continent for many years. Making us one of the oldest tour operators specializing in this specific location. Unlike large tour operators offering tours in many different locations, we specialize only in India, making us knowledgeable in our product. We know India & Sub Continent very well.</p>
  </Col>

  <Col lg="12">
  <h4 className="mb-3 mt-4">Your funds</h4>
    <p>Because you deal directly with the Taj Tour's representative, your money goes straight to the company, no hidden extra costs; you pay for what you arrange.</p>
    <p>We also think about the various elements of how to make your trip most cost effective. For example, we do not publish brochures with expensive magazine paper, saving your trip cost and trees & we give you value for money also less expensive than other operator. All of the tour information is easily available to everyone on our website.</p>
  </Col>

  <Col lg="12">
  <h4 className="mb-3 mt-4">Air Fares</h4>
    <p>To facilitate with organizing of your trip, Taj Tour arranges not only the land portion of the tour but the airline ticket of the journey as well. So you don't have to worry, as we will find the most suitable airfare with your preferred airline.</p>
  </Col>

  <Col lg="12">
  <h4 className="mb-3 mt-4">Our Staff</h4>
    <p>We have offices in Canada as well as in India. We are easily accessible by phone and via e-mail and in person. Our qualified and experienced staff will organize your trip for you.</p>
    <p>On location in India as well, from the minute you leave the airport: transportation, accommodation, a local English-speaking guide and even restaurants, everything is arranged and taken care of. There is always someone to contact and to guide you, every step of the way.</p>
    <p>We only use trusted land suppliers for our tours and transportation in India, so our customers need not worry about their personal safety or the safety of their luggage while on our tours.</p>
  </Col>

  <Col lg="12">
  <h4 className="mb-3 mt-4">Client Satisfaction</h4>
    <p>We strive for your complete satisfaction. You can depend on us to make your trip memorable and unique.</p>
  </Col>
</Row>

</Container>
  </>
  );

export default Whyus;
