import React, { Component } from "react";
import {Container, Col, Row, Button, Card, Accordion} from "react-bootstrap";
import banner from "../images/banners/romancing-the-taj.jpg";

import img1 from "../images/delhi-airport.jpg";
import img2 from "../images/hotel2.jpg";
import img3 from "../images/hotel3.jpg";

import img4 from "../images/red-fort.jpg";
import img5 from "../images/president-house.jpg";
import img6 from "../images/mahatma-gandhi-cremate.jpg";

import img7 from "../images/agra-hotel.jpg";
import img8 from "../images/agra-hotel2.jpg";
import img9 from "../images/agra-hotel3.jpg";

import img10 from "../images/taj-mahal.jpg";
import img11 from "../images/taj-mahal2.jpg";
import img12 from "../images/agra-fort.jpg";

import img13 from "../images/fatehpur-sikri.jpg";
import img14 from "../images/fatehpur-sikri2.jpg";
import img15 from "../images/fatehpur-sikri3.jpg";

import img16 from "../images/amber-fort1.jpg";
import img17 from "../images/castle-riding.jpg";
import img18 from "../images/hawa-mahal.jpg";

import img19 from "../images/delhi.jpg";
import img20 from "../images/delhi2.jpg";
import img21 from "../images/delhi3.jpg";

import img22 from "../images/delhi-airport.jpg";
import img23 from "../images/delhi-airport2.jpg";
import img24 from "../images/airport3.jpg";
import { useHistory } from "react-router-dom";
import romancing from "../doc/RomancingTheTaj.pdf";


function RomancingtheTaj(){
  const history = useHistory();

  const routeChange = () =>{ 
    let path = `/customTour`; 
    history.push(path);
  }


return(
  <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    Romancing <strong>the Taj</strong>
    </h1>
    
  </Col>

  <Col lg="12">
    
  <p className="text-right mb-5"><Button className="mr-5" onClick={routeChange}>Book Now</Button>
  <Button href={romancing} target = "_blank"> Read Itinerary Details </Button> </p>
  </Col>
</Row>

<Row>

<Col lg="6">
<Accordion  defaultActiveKey="0">
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
      Day 01

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <h5>Delhi</h5>
        <img src={img1} className="img-fluid mr-3" />
        <img src={img2} className="img-fluid mr-3" />
        <img src={img3} className="img-fluid mr-3" />
        <p className="text-justify">Today early morning you will arrive at Delhi's International Airport. Following customs, immigration formalities and baggage collection, your representative will meet you as you exit the arrivals terminal building and transfer you to your hotel. Where you will proceed for check-in</p>

<p>The first impressions for any visitor traveling in from the airport are of a specious, garden city, tree-lined with a number of beautiful parks.</p>

<p>Today rest of the day is free for Indepandent activities.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
      Day 02

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
      <h5>Delhi</h5>
      <img src={img4} className="img-fluid mr-3" />
        <img src={img5} className="img-fluid mr-3" />
        <img src={img6} className="img-fluid mr-3" />
        <p className="text-justify">Breakfast at the Hotel.</p>

<p>Proceed for Full day combined city tour of Old and New Delhi. You will visit the 17th century Jama Masjid and walk through the narrow, winding lanes of colourful and bustling Chandni Chowk...Also visit the Mughal Empire's most opulent fort, the Red Fort for a photo stop. Visit Raj Ghat where Mahatma Gandhi was cremated.</p>

<p>Afternoon proceed for New Delhi. Visit Presidential Palace, the one-time imperial residence of the British viceroys; the India Gate.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
      Day 03

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body>
      <h5>Delhi - Agra</h5>
      <img src={img7} className="img-fluid mr-3" />
        <img src={img8} className="img-fluid mr-3" />
        <img src={img9} className="img-fluid mr-3" />
        <p className="text-justify">Breakfast at the Hotel & after that you will be driven to Agra. Upon arrival in Agra you will check-in at your hotel and our represantative will assist you to complete your check-in formalities.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="3">
        Day 04
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="3">
      <Card.Body>
      <h5>Agra</h5>
      <img src={img10} className="img-fluid mr-3" />
        <img src={img11} className="img-fluid mr-3" />
        <img src={img12} className="img-fluid mr-3" />
        <p className="text-justify">Morning, proceed to visit Taj Mahal, (Closed on Fridays). On the inner walls, jewel-like stones create beautiful designs in white marble.</p>

<p>Afternoon you will proceed to visit Agra Fort, where the ageing Emperor Shah Jahan, imprisoned by his son, watched from a distance the fulfillment of his life's dream-the building of the Taj Mahal in memory of his beloved wife.</p>

<p>You have an option to visit the Taj Mahal at your own at the time of sunset.</p>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  </Accordion>
</Col>

<Col lg="6">
<Accordion  defaultActiveKey="0">

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="4">
      Day 05
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="4">
      <Card.Body>
      <h5>Agra - Jaipur</h5>
      <img src={img13} className="img-fluid mr-3" />
        <img src={img14} className="img-fluid mr-3" />
        <img src={img15} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast you will drive to Jaipur enroute you will visit Fatehpur Sikri.</p>

<p>Fatehpur Sikri, the deserted red sandstone city, built by the Great Mughal Emperor Akbar as his capital and palace in the late 16th century.</p>

<p>On arrival in Jaipur, proceed to check in at your hotel. Rest of the day is at leisure.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="5">
      Day 06
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="5">
      <Card.Body>
      <h5>Jaipur</h5>
      <img src={img16} className="img-fluid mr-3" />
        <img src={img17} className="img-fluid mr-3" />
        <img src={img18} className="img-fluid mr-3" />
        <p className="text-justify">Following buffet breakfast you will proceed to visit the capital of Amber to see the fabulous Amber Fort. Maharaja Mansingh, Mughal Emperor Akbar's most successful General.</p>

<p>An elephant (alternatively by jeeps) will spare you the trouble of reaching up to the fortress.</p>

<p>En-route to Amber Fort you will stop and see the `Palace of Winds', otherwise known as Hawa Mahal. It is really an elaborate facade behind which the ladies of the court used to watch the daily goings on in the street below.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="6">
      Day 07
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="6">
      <Card.Body>
      <h5>Jaipur - Gurgaon</h5>
      <img src={img19} className="img-fluid mr-3" />
        <img src={img20} className="img-fluid mr-3" />
        <img src={img21} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast you will be driven back to Delhi. The rest of the day is at leisure for independent activities and last minute shopping in Delhi.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="7">
      Day 08
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="7">
      <Card.Body>
      <h5>Depart Gurgaon</h5>
      <img src={img22} className="img-fluid mr-3" />
        <img src={img23} className="img-fluid mr-3" />
        <img src={img24} className="img-fluid mr-3" />
        <p className="text-justify">In time our representative will meet you and assist you to transfer the airport for your flight back home.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
</Col>
</Row>
</Container>
</>
);
}
export default RomancingtheTaj;
