import React, { Component } from "react";
import {Container, Col, Row, Button, Card, Accordion} from "react-bootstrap";
import banner from "../images/banners/taj-highlights.jpg";

import img1 from "../images/hotel1.jpg";
import img2 from "../images/hotel2.jpg";
import img3 from "../images/hotel3.jpg";

import img4 from "../images/agra-fort.jpg";
import img5 from "../images/taj-mahal.jpg";
import img6 from "../images/taj-mahal2.jpg";

import img7 from "../images/delhi.jpg";
import img8 from "../images/delhi2.jpg";
import img9 from "../images/bus.jpg";
import { useHistory } from "react-router-dom";
import tajhighlights from "../doc/TajHighlights.pdf";


function TajHighlights(){
  const history = useHistory();

  const routeChange = () =>{ 
    let path = `/customTour`; 
    history.push(path);
  }


return(
  <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    Taj   <strong>Highlights </strong>
    </h1>
    
  </Col>

  <Col lg="12">
    <p className="text-justify mt-2 tour-para"><span>Taj Tour  brings you a complete package </span> for Delhi tours. Our Delhi tour package consists of a well planned itinerary that includes a visit to the most celebrated wonder of the world, "Taj Mahal" You will receive a detailed tour of the monument of love. Travel with complete comfort for a journey to remember.
    </p>

    
    <p className="text-right mb-5"><Button className="mr-5" onClick={routeChange}>Book Now</Button>
  <Button href={tajhighlights} target = "_blank"> Read Itinerary Details </Button> </p>
  </Col>
</Row>

<Row>

<Col lg="6">
<Accordion  defaultActiveKey="0">
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
      Day 01

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <h5>Delhi</h5>
        <img src={img1} className="img-fluid mr-3" />
        <img src={img2} className="img-fluid mr-3" />
        <img src={img3} className="img-fluid mr-3" />
        <p className="text-justify">Arrival in Delhi and transfer to Hotel. Spend rest of day at leisure.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
      Day 02

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
      <h5>Delhi - Agra</h5>
      <img src={img4} className="img-fluid mr-3" />
        <img src={img5} className="img-fluid mr-3" />
        <img src={img6} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast depart for Agra by bus, a distance of 203Km. - about 4 1/2 Hours. After arriving at hotel in the late afternoon, .visit the massive Agra Fort and the Taj Mahal; one of the greatest wonders of the world, built by Emperor Shah-Jehan in memory of his beloved wife Mumtaz Mahal.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  </Accordion>
</Col>

<Col lg="6">
<Accordion  defaultActiveKey="0">

<Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
      Day 03

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body>
      <h5>Agra - Delhi</h5>
      <img src={img7} className="img-fluid mr-3" />
        <img src={img8} className="img-fluid mr-3" />
        <img src={img9} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast depart for Delhi by bus, a distance of 203Km. - about 4 1/2 Hours

</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

</Accordion>
</Col>
</Row>
</Container>
</>
);
}
export default TajHighlights;
