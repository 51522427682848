import React, { Component } from "react";
import {Container, Col, Row, Button, Card, Accordion} from "react-bootstrap";
import banner from "../images/banners/tranquil-kerala.jpg";

import img1 from "../images/hotel1.jpg";
import img2 from "../images/hotel2.jpg";
import img3 from "../images/hotel3.jpg";

import img4 from "../images/new-delhi.jpg";
import img5 from "../images/dutch-palace.jpg";
import img6 from "../images/st-francis-churc.jpg";

import img7 from "../images/jaipur.jpg";
import img8 from "../images/jaipur2.jpg";
import img9 from "../images/jaipur3.jpg";

import img10 from "../images/periyar-sanctuary.jpg";
import img11 from "../images/wild-animal2.jpg";
import img12 from "../images/wild-animal.jpg";

import img13 from "../images/lake-periyar.jpg";
import img14 from "../images/boat-ride.jpg";
import img15 from "../images/bird-sanctuary.jpg";

import img16 from "../images/ketuvallam.jpg";
import img17 from "../images/boat-ride1.jpg";
import img18 from "../images/boat-ride-lunch.jpg";

import img19 from "../images/kovalam-beach1.jpg";
import img20 from "../images/kovalam-beach.jpg";
import img21 from "../images/kovalam-beach2.jpg";

import img22 from "../images/trivandrum-airport.jpg";
import img23 from "../images/trivandrum-airport2.jpg";
import img24 from "../images/trivandrum-airport3.jpg";
import { useHistory } from "react-router-dom";
import tranquilkerala from "../doc/Tranquil-Kerala-Itinerary.pdf";

function TranquilKerala(){
  
  const history = useHistory();
  
  const routeChange = () =>{ 
    let path = `/customTour`; 
    history.push(path);
  }
  return(<>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    Tranquil  <strong> Kerala </strong>
    </h1>
    
  </Col>

  <Col lg="12">
    <p className="text-justify mt-2 tour-para"><span>Capture the magic of Tranquil Kerala </span> with our Southern travels plan. Visit the 5 sensational cities in the south of India to experience what the ancient and modern blend of India is like. The South India Tours by Taj Tour include the cities - Cochin, Munnar, Periyar, Kumarakom, and Kovalam. Take a complete tour of each city and witness their richness and new developments. The journey is well planned with complete itinerary and comfortable travelling options.</p>
    
    <p className="text-right mb-5"><Button className="mr-5" onClick={routeChange}>Book Now</Button>
  <Button href={tranquilkerala} target = "_blank"> Read Itinerary Details </Button> </p>
  </Col>
</Row>

<Row>

<Col lg="6">
<Accordion  defaultActiveKey="0">
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
      Day 01

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <h5>Cochin</h5>
        <img src={img1} className="img-fluid mr-3" />
        <img src={img2} className="img-fluid mr-3" />
        <img src={img3} className="img-fluid mr-3" />
        <p className="text-justify">Arrive Cochin. Met by our representative at the 'Meet & Greet' lounge who will transfer you to your Hotel and check in.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
      Day 02

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
       <h5>Cochin - Munnar</h5>
      <img src={img4} className="img-fluid mr-3" />
        <img src={img5} className="img-fluid mr-3" />
        <img src={img6} className="img-fluid mr-3" />
        <p className="text-justify">Breakfast at the hotel. Proceed for half day sightseeing tour of Kochi with an English speaking guide will include visits to: The Dutch Palace at Mattancherry is renowned for its wall murals depicting scenes from the Ramayana. The Jewish Synagogue at the heart of what is locally called the Jew Town. Here you find numerous spice traders and many curio shops The St. Francis Church, the oldest European church in India. The Santa Cruz Basilica, originally built in 1557 by the Portuguese.
 <br/>
Afternoon you will be driven to Munnar. On arrival, check in to your hotel.Munnar is situated at the confluence of three mountain streams - Mudrapuzha, Nallathanni and Kundala. 1600 m above sea level, this hill station was once the summer resort of the erstwhile British Government in South India. Sprawling tea plantations, picture-book towns, winding lanes and holiday facilities make this a popular resort town found in the Munnar also has the highest peak in South India, Anamudi, which towers over 2695 m.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
      Day 03

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body>
      <h5>Munnar</h5>
      <img src={img7} className="img-fluid mr-3" />
        <img src={img8} className="img-fluid mr-3" />
        <img src={img9} className="img-fluid mr-3" />
        <p className="text-justify">Proceed to see the Nilgiri Tahr Goat which is now endangered species and thus protected.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="5">
        Day 04
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="5">
      <Card.Body>
      <h5>Munnar - Periyar</h5>
      <img src={img10} className="img-fluid mr-3" />
        <img src={img11} className="img-fluid mr-3" />
        <img src={img12} className="img-fluid mr-3" />
        <p className="text-justify">Breakfast at the hotel.Drive to Periyar.
        <br/>

Periyar is South India's most popular wildlife sanctuary, encompassing an area of 777 sq. km. Set on the attractive man-made Periyar Lake, it attracts over 300,000 visitors a year. Though the sanctuary was made a part of Project Tiger in 1973, it is better known for its elephants.</p>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  </Accordion>
</Col>

<Col lg="6">
<Accordion  defaultActiveKey="0">

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="5">
      Day 05
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="5">
      <Card.Body>
      <h5>Periyar - Kumarakom</h5>
      <img src={img13} className="img-fluid mr-3" />
        <img src={img14} className="img-fluid mr-3" />
        <img src={img15} className="img-fluid mr-3" />
        <p className="text-justify">Early morning take a boat ride on Lake Periyar, and see the wildlife around the lake as you cruise along its banks. Apart from elephants, bison, sambar, wild boar, langur, otter and flying squirrel are common. There are also 246 species of birds and 112 species of butterfly. Return to your hotel for breakfast. Later, you will be driven to Kumarakom. On arrival, check-in to your hotel. Afternoon, visit the bird sanctuary.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="6">
      Day 06
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="6">
      <Card.Body>
      <h5>Kumarakom - Alleppey-Kovalam</h5>
      <img src={img16} className="img-fluid mr-3" />
        <img src={img17} className="img-fluid mr-3" />
        <img src={img18} className="img-fluid mr-3" />
        <p className="text-justify">Breakfast at the hotel.Embark on the houseboat for day cruise.. Houseboats are also known as the "Kettuvallam" or the Rice Boat. Kettuvallam ride can be the most romantic ride you can ever have with a night halt in the middle of the lake. Vembanad Lake/canal. A backwater comprises of a unique web of rivers, lakes, bays, lagoons and canals extending into the villages and town of the city. Backwaters are a central part of Kerala's Tourism Lunch will be served onboard.Disembark houseboat and drive to Kovalam .Overnight on the houseboat.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="7">
      Day 07
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="7">
      <Card.Body>
      <h5>Kovalam</h5>
      <img src={img19} className="img-fluid mr-3" />
        <img src={img20} className="img-fluid mr-3" />
        <img src={img21} className="img-fluid mr-3" />
        <p className="text-justify">Breakfast at the hotel.Rest of the day is free to relax by the beach. Overnight at the Hotel</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="8">
      Day 08
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="8">
      <Card.Body>
      <h5>Kovalam - Trivandrum Airport</h5>
      <img src={img22} className="img-fluid mr-3" />
        <img src={img23} className="img-fluid mr-3" />
        <img src={img24} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast drive to Trivandrum Airport. Our representative will meet you and assist you to check in for your flight for onward destination.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
</Col>
</Row>
</Container>
</>
);
  }
export default TranquilKerala;
