import React, { Component } from "react";
import { Container, Col, Row, Form, Button, Alert } from "react-bootstrap";
import banner from "../images/banners/tour-packages.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";
import CalendarNew from "./calendarNew";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from 'react-datepicker';
class CustomTour extends Component {
  recaptchaRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      customTour: {
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        city: "",
        country: "",
        province: "",
        tourtype: "",
        travelDate:  new Date(),
        category: 3,
        adultPassenger: 1,
        childPassenger: 0,
        comments: "",
      },
      recapthares: {
        success: false,
      },
      loading: false,
      validated: false,
      emailSuccess: false,
      buttonclicked: false,
    };
  }

  render() {
    let alertcontent;
    if (this.state.emailSuccess && this.state.buttonclicked) {
      alertcontent = (
        <Alert variant="success" className="mt-4">
          Thank you for contacting us. A team member will reply to you shortly.
        </Alert>
      );
    } else if (this.state.buttonclicked) {
      alertcontent = (
        <Alert variant="danger" className="mt-4">
          Sorry.. please try again or please contact us: 1-800-304-8714.
        </Alert>
      );
    }

    return (
      <React.Fragment>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>
        <Container>
          <Row>
            <Col lg="12">
              <h1 className="mb-4 mt-5">
                Custom<strong> Tours </strong>
              </h1>
            </Col>
            <Col lg="12">
              <p>
                Enter the trip details of the destination(s) that you would like
                to get more information on. An agent will be in contact with you
                from the closest office located in your area. You are not
                required to make any payment to receive quote on this trip and
                you are under no obligation to book this with Taj Tour.
              </p>
            </Col>
          </Row>

          <Form
            className="my-5"
            noValidate
            validated={this.state.validated}
            onSubmit={this.sendEmailcustom}
          >
            <Form.Row className="my-3">
              <Col>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  placeholder="First name"
                  type="text"
                  id="firstName"
                  value={this.state.customTour.firstName}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your first name
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  placeholder="Last name"
                  type="text"
                  id="lastName"
                  value={this.state.customTour.lastName}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your last name
                </Form.Control.Feedback>
              </Col>
            </Form.Row>

            <Form.Row className="my-3">
              <Col>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  placeholder="Email"
                  type="text"
                  id="email"
                  value={this.state.customTour.email}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your email
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Telephone</Form.Label>
                <Form.Control
                  placeholder="Telephone"
                  type="text"
                  id="telephone"
                  value={this.state.customTour.telephone}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your telephone number.
                </Form.Control.Feedback>
              </Col>
            </Form.Row>

            <Form.Row className="my-3">
              <Col>
                <Form.Label>City</Form.Label>
                <Form.Control
                  placeholder="City"
                  type="text"
                  id="city"
                  value={this.state.customTour.city}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your city
                </Form.Control.Feedback>
              </Col>

              <Col>
                <Form.Label>State (or Province) in US or CA</Form.Label>
                <Form.Control
                  as="select"
                  id="province"
                  value={this.state.customTour.province}
                  onChange={this.handleChange}
                >
                  <option value="">State (or Province) in US or CA</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AB">Alberta</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="BC">British Columbia</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MB">Manitoba</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NB">New Brunswick</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NL">Newfoundland and Labrador</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="NT">Northwest Territories</option>
                  <option value="NS">Nova Scotia</option>
                  <option value="NU">Nunavut</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="ON">Ontario</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="PE">Prince Edward Island</option>
                  <option value="QC">Quebec</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SK">Saskatchewan</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                </Form.Control>
              </Col>
            </Form.Row>

            <Form.Row className="my-3">
              <Col>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  placeholder="Country"
                  as="select"
                  id="country"
                  value={this.state.customTour.country}
                  onChange={this.handleChange}
                  required
                >
                  <option value="">Country</option>
                  <option value="AFG">Afghanistan </option>
                  <option value="ALB">Albania </option>
                  <option value="DZA">Algeria </option>
                  <option value="ASM">American Samoa </option>
                  <option value="AND">Andorra </option>
                  <option value="AGO">Angola </option>
                  <option value="AIA">Anguilla </option>
                  <option value="ATA">Antarctica </option>
                  <option value="ATG">Antigua/Barbuda </option>
                  <option value="ARG">Argentina </option>
                  <option value="ARM">Armenia </option>
                  <option value="ABW">Aruba </option>
                  <option value="AUS">Australia </option>
                  <option value="AUT">Austria </option>
                  <option value="AZE">Azerbaijan </option>
                  <option value="BHS">Bahamas </option>
                  <option value="BHR">Bahrain </option>
                  <option value="BGD">Bangladesh </option>
                  <option value="BRB">Barbados </option>
                  <option value="BLR">Belarus </option>
                  <option value="BEL">Belgium </option>
                  <option value="BLZ">Belize </option>
                  <option value="BEN">Benin </option>
                  <option value="BMU">Bermuda </option>
                  <option value="BTN">Bhutan </option>
                  <option value="BOL">Bolivia </option>
                  <option value="BIH">Bosnia/Herz. </option>
                  <option value="BWA">Botswana </option>
                  <option value="BVT">Bouvet Island </option>
                  <option value="BRA">Brazil </option>
                  <option value="IOT">Br. Indian Ocean T. </option>
                  <option value="BRN">Brunei Darussalam </option>
                  <option value="BGR">Bulgaria </option>
                  <option value="BFA">Burkina Faso </option>
                  <option value="BDI">Burundi </option>
                  <option value="KHM">Cambodia </option>
                  <option value="CMR">Cameroon </option>
                  <option value="CAN">Canada </option>
                  <option value="CPV">Cape Verde </option>
                  <option value="CYM">Cayman Is </option>
                  <option value="CAF">Central African Rep </option>
                  <option value="TCD">Chad </option>
                  <option value="CHL">Chile </option>
                  <option value="CHN">China </option>
                  <option value="CXR">Christmas Island </option>
                  <option value="CCK">Cocos (Keeling) Is </option>
                  <option value="COL">Colombia </option>
                  <option value="COM">Comoros </option>
                  <option value="COG">Congo </option>
                  <option value="COD">Congo </option>
                  <option value="COK"> Dem Rep </option>
                  <option value="CRI">Cook Is </option>
                  <option value="CIV">Costa Rica </option>
                  <option value="HRV">Cote D'Ivoire </option>
                  <option value="CUB">Croatia </option>
                  <option value="CYP">Cuba </option>
                  <option value="CZE">Cyprus </option>
                  <option value="DNK">Czech Republic </option>
                  <option value="DJI">Denmark </option>
                  <option value="DMA">Djibouti </option>
                  <option value="DOM">Dominica </option>
                  <option value="TMP">Dominican Rep. </option>
                  <option value="ECU">East Timor </option>
                  <option value="EGY">Ecuador </option>
                  <option value="SLV">Egypt </option>
                  <option value="GNQ">El Salvador </option>
                  <option value="ERI">Equatorial Guinea </option>
                  <option value="EST">Eritrea </option>
                  <option value="ETH">Estonia </option>
                  <option value="FLK">Ethiopia </option>
                  <option value="FRO">Falkland Is </option>
                  <option value="FJI">Faroe IsFiji </option>
                  <option value="FIN">Finland </option>
                  <option value="FRA">France </option>
                  <option value="FXX">France (Metro) </option>
                  <option value="GUF">French Guiana </option>
                  <option value="PYF">French Polynesia </option>
                  <option value="ATF">French Sou. Terr. </option>
                  <option value="GAB">Gabon </option>
                  <option value="GMB">Gambia </option>
                  <option value="GEO">Georgia </option>
                  <option value="DEU">Germany </option>
                  <option value="GHA">Ghana </option>
                  <option value="GIB">Gibraltar </option>
                  <option value="GRC">Greece </option>
                  <option value="GRL">Greenland </option>
                  <option value="GRD">Grenada </option>
                  <option value="GLP">Guadeloupe </option>
                  <option value="GUM">Guam </option>
                  <option value="GTM">Guatemala </option>
                  <option value="GIN">Guinea </option>
                  <option value="GNB">Guinea-Bissau </option>
                  <option value="GUY">Guyana </option>
                  <option value="HTI">Haiti </option>
                  <option value="HMD">Heard/McDonald </option>
                  <option value="VAT">Vatican </option>
                  <option value="HND">Honduras </option>
                  <option value="HKG">Hong Kong </option>
                  <option value="HUN">Hungary </option>
                  <option value="ISL">Iceland </option>
                  <option value="IND">India </option>
                  <option value="IDN">Indonesia </option>
                  <option value="IRN">Iran </option>
                  <option value="IRQ">Iraq </option>
                  <option value="IRL">Ireland </option>
                  <option value="ISR">Israel </option>
                  <option value="ITA">Italy </option>
                  <option value="JAM">Jamaica </option>
                  <option value="JPN">Japan </option>
                  <option value="JOR">Jordan </option>
                  <option value="KAZ">Kazakhstan </option>
                  <option value="KEN">Kenya </option>
                  <option value="KIR">Kiribati </option>
                  <option value="PRK">Korea (DPR) </option>
                  <option value="KOR">Korea (Republic) </option>
                  <option value="KWT">Kuwait </option>
                  <option value="KGZ">Kyrgyzstan </option>
                  <option value="LAO">Laos </option>
                  <option value="LVA">Latvia </option>
                  <option value="LBN">Lebanon </option>
                  <option value="LSO">Lesotho </option>
                  <option value="LBR">Liberia </option>
                  <option value="LBY">Libya </option>
                  <option value="LIE">Liechtenstein </option>
                  <option value="LTU">Lithuania </option>
                  <option value="LUX">Luxembourg </option>
                  <option value="MAC">Macau </option>
                  <option value="MKD">Macedonia </option>
                  <option value="MDG">Madagascar </option>
                  <option value="MWI">Malawi </option>
                  <option value="MYS">Malaysia </option>
                  <option value="MDV">Maldives </option>
                  <option value="MLI">Mali </option>
                  <option value="MLT">Malta </option>
                  <option value="MHL">Marshall Is </option>
                  <option value="MTQ">Martinique </option>
                  <option value="MRT">Mauritania </option>
                  <option value="MUS">Mauritius </option>
                  <option value="MYT">Mayotte </option>
                  <option value="MEX">Mexico </option>
                  <option value="FSM">Micronesia </option>
                  <option value="MDA">Moldova Rep </option>
                  <option value="MCO">Monaco </option>
                  <option value="MNG">Mongolia </option>
                  <option value="MSR">Montserrat </option>
                  <option value="MAR">Morocco </option>
                  <option value="MOZ">Mozambique </option>
                  <option value="MMR">Myanmar </option>
                  <option value="NAM">Namibia </option>
                  <option value="NRU">Nauru </option>
                  <option value="NPL">Nepal </option>
                  <option value="NLD">Netherlands </option>
                  <option value="ANT">Neth Antilles </option>
                  <option value="NCL">New Caledonia </option>
                  <option value="NZL">New Zealand </option>
                  <option value="NIC">Nicaragua </option>
                  <option value="NER">Niger </option>
                  <option value="NGA">Nigeria </option>
                  <option value="NIU">Niue </option>
                  <option value="NFK">Norfolk Island </option>
                  <option value="MNP">No. Mariana Is </option>
                  <option value="NOR">Norway </option>
                  <option value="OMN">Oman </option>
                  <option value="PAK">Pakistan </option>
                  <option value="PLW">Palau </option>
                  <option value="PAN">Panama </option>
                  <option value="PNG">Papua New Guinea </option>
                  <option value="PRY">Paraguay </option>
                  <option value="PER">Peru </option>
                  <option value="PHL">Philippines </option>
                  <option value="PCN">Pitcairn </option>
                  <option value="POL">Poland </option>
                  <option value="PRT">Portugal </option>
                  <option value="PRI">Puerto Rico </option>
                  <option value="QAT">Qatar </option>
                  <option value="REU">Reunion </option>
                  <option value="ROM">Romania </option>
                  <option value="RUS">Russia </option>
                  <option value="RWA">Rwanda </option>
                  <option value="KNA">St Kitts/Nevis </option>
                  <option value="LCA">St Lucia </option>
                  <option value="VCT">St Vincent/Gren. </option>
                  <option value="WSM">Samoa </option>
                  <option value="SMR">San Marino </option>
                  <option value="STP">Sao Tome/Principe </option>
                  <option value="SAU">Saudi Arabia </option>
                  <option value="SEN">Senegal </option>
                  <option value="SYC">Seychelles </option>
                  <option value="SLE">Sierra Leona </option>
                  <option value="SGP">Singapore </option>
                  <option value="SVK">Slovakia </option>
                  <option value="SVN">Slovenia </option>
                  <option value="SLB">Solomon Is </option>
                  <option value="SOM">Somalia </option>
                  <option value="ZAF">South Africa </option>
                  <option value="SGS">S Georgia/Sand Is </option>
                  <option value="ESP">Spain </option>
                  <option value="LKA">Sri Lanka </option>
                  <option value="SHN">St Helena </option>
                  <option value="SPM">St Pierre/Miquelon </option>
                  <option value="SDN">Sudan </option>
                  <option value="SUR">Suriname </option>
                  <option value="SJM">Svalbard/J. Mayen </option>
                  <option value="SWZ">Swaziland </option>
                  <option value="SWE">Sweden </option>
                  <option value="CHE">Switzerland </option>
                  <option value="SYR">Syria </option>
                  <option value="TWN">Taiwan </option>
                  <option value="TJK">Tajikistan </option>
                  <option value="TZA">Tanzania </option>
                  <option value="THA">Thailand </option>
                  <option value="TGO">Togo </option>
                  <option value="TKL">Tokelau </option>
                  <option value="TON">Tonga </option>
                  <option value="TTO">Trinidad/Tobago </option>
                  <option value="TUN">Tunisia </option>
                  <option value="TUR">Turkey </option>
                  <option value="TKM">Turkmenistan </option>
                  <option value="TCA">Turks/Caicos Is </option>
                  <option value="TUV">Tuvalu </option>
                  <option value="UGA">Uganda </option>
                  <option value="UKR">Ukraine </option>
                  <option value="ARE">United Arab Emir. </option>
                  <option value="GBR">United Kingdom </option>
                  <option value="USA">United States </option>
                  <option value="UMI">US Outlying Is </option>
                  <option value="URY">Uruguay </option>
                  <option value="UZB">Uzbekistan </option>
                  <option value="VUT">Vanuatu </option>
                  <option value="VEN">Venezuela </option>
                  <option value="VNM">Viet Nam </option>
                  <option value="VGB">Virgin Is (Br.) </option>
                  <option value="VIR">Virgin Is (U.S.) </option>
                  <option value="WLF">Wallis/Futuna Is </option>
                  <option value="ESH">Western Sahara </option>
                  <option value="YEM">Yemen </option>
                  <option value="YUG">Yugoslavia </option>
                  <option value="ZMB">Zambia </option>
                  <option value="ZWE">Zimbabwe </option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please enter country
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Tour Type</Form.Label>
                <Form.Control
                  as="select"
                  id="tourtype"
                  value={this.state.customTour.tourtype}
                  onChange={this.handleChange}
                >
                  <option value="">Tour Type</option>
                  <option value="Custom Package">Custom Package</option>
                  <option value="Best of India">Best of India</option>
                  <option value="Essence of Sri Lanka">
                    Essence of Sri Lanka
                  </option>
                  <option value="Exotic Nepal">Exotic Nepal</option>
                  <option value="Glimspe of India">Glimpse of India</option>
                  <option value="Golden Triangle">Golden Triangle</option>
                  <option value="India Explorer">India Explorer</option>
                  <option value="Magic of India">Magic of India</option>
                  <option value="Northeastern Hills">Northeastern Hills</option>
                  <option value="Palace on Wheels">Palace on Wheels</option>
                  <option value="Rajasthan Explorer">Rajasthan Explorer</option>
                  <option value="Romancing the Taj">Romancing the Taj</option>
                  <option value="Royal Rajasthan on Wheels">
                    Royal Rajasthan on Wheels
                  </option>
                  <option value="Surf and Sand">Surf and Sand</option>
                  <option value="Taj Exotica Resort &amp; Spa">
                    Taj Exotica Resort &amp; Spa
                  </option>
                  <option value="Taj Highlights">Taj Highlights</option>
                  <option value="Taj &amp; Wilderness">
                    Taj &amp; Wilderness
                  </option>
                  <option value="Tranquil Kerala">Tranquil Kerala</option>
                </Form.Control>
              </Col>
            </Form.Row>

            <Form.Row className="my-3">
              <Col>
                <Form.Label>Travel Date</Form.Label>
               <CalendarNew id="travelDate" value={this.state.customTour.travelDate} onChangeValue={this.onDateChange}/>
             
                {/*<Form.Control
                  placeholder="Travel dates"
                  id="travelDate"
                  type="text"
                  value={this.state.customTour.travelDate}
                  onChange={this.handleChange}
                  required
                />*/} 

                <Form.Control.Feedback type="invalid">
                  Please enter travel dates.
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  id="category"
                  type="text"
                  value={this.state.customTour.category}
                  onChange={this.handleChange}
                  required
                >
                  <option value="3">3 Star</option>
                  <option value="4">4 Star</option>
                  <option value="5">5 Star</option>
                </Form.Control>
              </Col>

              <Col>
                <Form.Label>Passenger (Adult age 12+)</Form.Label>
                <Form.Control
                  as="select"
                  id="adultPassenger"
                  value={this.state.customTour.adultPassenger}
                  onChange={this.handleChange}
                >
                  <option value="">Passenger (Adult age 12+)</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                </Form.Control>
              </Col>

              <Col>
                <Form.Label>Passenger (Child age 2-11)</Form.Label>
                <Form.Control
                  as="select"
                  id="childPassenger"
                  value={this.state.customTour.childPassenger}
                  onChange={this.handleChange}
                >
                  <option value="">Passenger (Child age 2-11)</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                </Form.Control>
              </Col>
            </Form.Row>

            <Form.Row className="my-3">
              <Col>
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Comments"
                  id="comments"
                  value={this.state.customTour.comments}
                  onChange={this.handleChange}
                />
              </Col>
             
              <Col>
              <Form.Label>Verification</Form.Label>
                {" "}
                <ReCAPTCHA
                  sitekey="6LfMkzUaAAAAAEXdEB_IhmE3hbHZ40aGHa93e5_G"
                  onChange={this.googleOnchange}
                  ref={(r) => (this.recaptcha = r)}
                />
              </Col>
            </Form.Row>

            <Row className="mt-4">
              <Col>
                <Button variant="primary" size="lg" block type="submit">
                  {this.state.loading ? (
                    <span
                      className="spinner-border spinner-border-sm mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    ""
                  )}
                  Submit
                </Button>
              </Col>

              <Col>
                <Button
                  variant="primary"
                  size="lg"
                  block
                  onClick={this.resetHandle}
                >
                  Reset
                </Button>
              </Col>
            </Row>

            {alertcontent}
          </Form>
        </Container>
      </React.Fragment>
    );
  }

  resetHandle = (event) => {
    this.setState({
      customTour: {
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        city: "",
        country: "",
        province: "",
        tourtype: "",
        travelDate: new Date(),
        category: "",
        adultPassenger: 1,
        childPassenger: 0,
        comments: "",
      },
      loading: false,
      emailSuccess: false,
      validated: false,
      buttonclicked: false,
    });
  };

  handleChange = (event) => {
  
    if(event.target!=null)
    {
    let id = event.target.id;
    let value = event.target.value;

    this.setState((prevState) => ({
      customTour: {
        ...prevState.customTour,

        [id]: value,
      },
      recapthares: {
        ...prevState.recapthares,

        [id]: value,
      },
    }));
  }
  };

  onDateChange =(date)=> {
    this.setState((prevState) => ({
      customTour: {
        ...prevState.customTour,

        travelDate: date,
      }
    }));
  };

  googleOnchange = (event) => {
    let response = event;

    fetch(`api/SampleData/VerifyGoogleCaptcha`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(response),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data != null) {
          let res = JSON.parse(data);

          this.setState({
            recapthares: { success: res.success },
          });
        }
        // console.log("SUCCESS STATUS IS " + this.state.recapthares.success);
      });
  };

  sendEmailcustom = (event) => {
    this.setState({ buttonclicked: false });

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      console.log("Check validity is false");
    } else {
      event.preventDefault();
      console.log("Check validity is true");

      if (this.state.recapthares.success) {
        this.setState({ loading: true });
        fetch("api/SampleData/CustomTours", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: [
            JSON.stringify(
              this
                .state /*{
          contact: this.state.contactUs,
          employee: this.state.employee,
        }*/
            ),
            //JSON.stringify(this.state.employee),
          ],
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              customTour: {
                firstName: "",
                lastName: "",
                email: "",
                telephone: "",
                city: "",
                country: "",
                province: "",
                tourtype: "",
                travelDate: "",
                category: "",
                adultPassenger: 1,
                childPassenger: 0,
                comments: "",
              },
              loading: false,
              emailSuccess: true,
              validated: false,
              buttonclicked: true,
            });

            this.recaptcha.reset();
            console.log(data);
          });
      } else {
        this.setState({
          emailSuccess: false,
          buttonclicked: true,
        });
      }
    }

    this.setState({ validated: true });
  };
}
export default CustomTour;
