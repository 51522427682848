import React, { Component } from "react";
import {Container, Col, Row, Button, Card, Accordion} from "react-bootstrap";
import banner from "../images/banners/taj-exotica.jpg";

import img1 from "../images/maldives.jpg";
import img2 from "../images/maldives2.jpg";
import img3 from "../images/maldives3.jpg";

import img4 from "../images/maldives7.jpg";
import img5 from "../images/maldives8.jpg";
import img6 from "../images/maldives9.jpg";

import img7 from "../images/maldives4.jpg";
import img8 from "../images/maldives5.jpg";
import img9 from "../images/maldives6.jpg";
import { useHistory } from "react-router-dom";
import tajexoticares from "../doc/SurfAndSand.pdf";


function TajExoticaResort(){
  const history = useHistory();

  const routeChange = () =>{ 
    let path = `/customTour`; 
    history.push(path);
  }


return(
  <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    Taj Exotica Resort &  <strong>Spa Maldives </strong>
    </h1>
    
  </Col>

  <Col lg="12">
    <p className="text-justify mt-2 tour-para"><span>Taj Exotica Resort & Spa, Maldives, </span> is an exclusive, private and romantic island resort lush with tropical plants and encircled by clear blue waters of one of the largest lagoons of the Maldives.
    </p>

    <p className="text-justify tour-para">Taj Exotica Resort & Spa spreads idyllically along a pristine beach and serene lagoon. The award-winning resort seamlessly extends the magic of the island's blessed natural beauty in its design even as it offers the finest in contemporary luxuries and services. Jiva Grande Spa, an authentic Indian spa, offers a range of signature wellness experiences. A choice of gourmet cuisine, water sports, and recreation make for a complete and exclusive destination.
    </p>

    <p className="text-justify tour-para">The resort is only 15 minutes by a private luxury speedboat, available 24 hours, from MalÃ© International Airport.</p>
    
    <p className="text-justify  tour-para"><span>Additional Information</span> : Departures are year round. / Honeymoon packages are also available.</p>

    <p className="text-justify tour-para"><span>Quote Includes </span> : 3 Nights accommodation, Breakfast daily, Return Speed Boat Transfers.</p>

    <p className="text-justify  tour-para"><span>Options Available </span> : Half Board(Lunch) or Full board(Lunch & Dinner)</p>


    <p className="text-right mb-5"><Button className="mr-5" onClick={routeChange}>Book Now</Button></p>

  </Col>
</Row>

<Row>

<Col lg="6">
<Accordion  defaultActiveKey="0">
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
      Day 01

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <h5>Maldives </h5>
        <img src={img1} className="img-fluid mr-3" />
        <img src={img2} className="img-fluid mr-3" />
        <img src={img3} className="img-fluid mr-3" />
        <p className="text-justify">On arrival you will transferred to your exclusive private island resort rest of the day is free.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
      Day 02 & Day 03
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
      <h5>Maldives </h5>
      <img src={img4} className="img-fluid mr-3" />
        <img src={img5} className="img-fluid mr-3" />
        <img src={img6} className="img-fluid mr-3" />
        <p className="text-justify">At Leisure, Maldives is made up approximately 1000 tiny coral islands, you can swim the in the clear lagoons and view a wide species of rainbow colored fish and Coral reefs, or just relax on the sun drenched beach.</p>

<p>Meals : Breakfast</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
 
  </Accordion>
</Col>

<Col lg="6">
<Accordion  defaultActiveKey="0">

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
      Day 04
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body>
      <h5>Maldives </h5>
      <img src={img7} className="img-fluid mr-3" />
        <img src={img8} className="img-fluid mr-3" />
        <img src={img9} className="img-fluid mr-3" />
        <p className="text-justify">Morning is free prior to your transfer to the airport.</p>

        <p>Meals : Breakfast</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>



</Accordion>
</Col>
</Row>
</Container>
</>
);
}
export default TajExoticaResort;
