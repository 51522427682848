import React, { Component } from "react";
import {Container, Col, Row, Button } from "react-bootstrap";
import banner from "../images/banners/yoga-banner.jpg";

import img1 from "../images/yoga2.jpg";
import img2 from "../images/yoga-class.jpg";
import img3 from "../images/oil-treatment.jpg";
import img4 from "../images/activities.jpg";
import mercury from "../doc/MercureGoaDevaayaRetreat.pdf";
import naturopathy from "../doc/naturopathy_package.pdf"
import ayurveda from "../doc/AyurvedaTherapyPackage.pdf"
import wellness from "../doc/wellness-package.pdf"

const Yoga  = () => ( 

<>
   <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>
<Container>
<Row className="why-us mb-5">
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    <strong> Mercure </strong> Goa Devaaya Retreat 
    </h1>
  </Col>

  <Col lg="12">
    <p>Your body is a temple and when it is in good health, it radiates good vibrations. At Mercure Devaaya, we use ancient knowledge to improve your body's health quotient by offering it nourishment in the purest natural form; by healing it with traditional Ayurvedic remedies; and by creating a mind - body - spirit connection through yoga and meditation, that raises your vibrations to a divine level. Come, experience this healing at Mercure Devaaya, an island of divine healing where Ayurveda, Naturopathy and Yoga come together to create the perfect balance your body needs and the perfect peace your mind wants.</p>
     <p>Mercure Devaaya, an authentic Ayurveda and Nature Cure Centre, is situated on Divar island. Surrounded by the healing waters of the river Mandovi, this nature's paradise, untouched by the hustle and bustle of city life, will immediately soothe your nerves and still your mind. Its rustic, old world charm will leave you feeling calm and relaxed.</p>
  </Col>
</Row>

      <Row className="my-5">
                <Col lg="3 text-center">
                    <img src={img1} className="img-fluid" />
                    <Button href={mercury} target="_blank" className="reserve-btn mt-4" >Daily Itinerary  <i className="fa fa-angle-double-right fa-lg ml-2"></i></Button>
                   
                </Col>

                <Col lg="3 text-center">
                    <img src={img2} className="img-fluid" />
                    <Button href={mercury} target="_blank" href={naturopathy} target="_blank" className="reserve-btn mt-4" > Naturopathy Package <i className="fa fa-angle-double-right fa-lg ml-2"></i></Button>
                </Col>

                <Col lg="3 text-center">
                    <img src={img3} className="img-fluid" />
                    <Button className="reserve-btn mt-4" target="_blank" href={ayurveda} > Ayurveda Therapy Package <i className="fa fa-angle-double-right fa-lg ml-2"></i>  </Button>
                </Col>

                <Col lg="3 text-center">
                    <img src={img4} className="img-fluid" />
                    <Button className="reserve-btn mt-4" target="_blank" href={wellness} >Wellness Package <i className="fa fa-angle-double-right fa-lg ml-2"></i> </Button>
                </Col>

            </Row>

            <Row className="my-5">
            <Col lg="12 daily-itinerary">

              <h4 className="mb-4">Daily itinerary</h4>
              <p>- Between 6:30 - 7:00 am kriya session.</p>
              <p>- Between 7:00 - 8:00 am Common Yoga Session that teaches the Surya namaskar (sun salute), and Yogasanas.</p>
              <p>- Between 8:30 - 9:30 am Breakfast is served. Our food is vegetarian, low in fat, salt and spices</p>
              <p>- Between 9:00 am - 1:00 pm Your Major Therapy (45 min-60 min.) is scheduled by the (Doctor).</p>
              <p>- Between 11:00 - 11:30 am Fresh Fruits will be served</p>
              <p>- Between 1:00 - 2:00 pm A wholesome, nutritionally balanced lunch is served.</p>
              <p>- Between 2:00 - 5:30 pm Your Minor Therapy (25min.-30min.) is scheduled.</p>
              <p>- Between 3:30 - 4:00 pm Talks on - Ayurveda ( Mon ), Naturopathy ( Wed ) & Yoga ( Fri )</p>
              <p>- Between 4:00 - 4:30 pm Tea will be served.</p>
              <p>- Between 4:30 - 5:15 pm You can join the Advanced Yoga session.</p>
              <p>- Between 5:15 - 5:45 pm A Common Breathing, Meditation and Relaxation Session in the Meditation adopting various techniques, yogic breathing. Etc. After it is leisure time.</p>
              <p>- Between 7:00 - 8:30 pm Dinner.</p>
            </Col>
            </Row>

</Container>
  </>
   );
export default Yoga;
