import React, { Component } from "react";
import {Container, Col, Row, Button, Card, Accordion} from "react-bootstrap";
import banner from "../images/banners/northeastern-hills.jpg";

import img1 from "../images/hotel1.jpg";
import img2 from "../images/hotel2.jpg";
import img3 from "../images/hotel3.jpg";

import img4 from "../images/Jama Masjid.jpg";
import img5 from "../images/india-gate.jpg";
import img6 from "../images/laxmi-narayan.jpg";

import img7 from "../images/bagdogra.jpg";
import img8 from "../images/darjeeling.jpg";
import img9 from "../images/darjeeling2.jpg";

import img10 from "../images/tiger-hill.jpg";
import img11 from "../images/toy-train.jpg";
import img12 from "../images/lebong-village.jpg";

import img13 from "../images/gangtok.jpg";
import img14 from "../images/white-memorial-hall.jpg";
import img15 from "../images/research-institute-of-tibetology.jpg";

import img16 from "../images/kalimpong.jpg";
import img17 from "../images/kalimpong2.jpg";
import img18 from "../images/rumtek-monastery.jpg";

import img19 from "../images/bagdogra2.jpg";
import img20 from "../images/bagdogra-ariport.jpg";
import img21 from "../images/bagdogra-ariport2.jpg";

import img22 from "../images/delhi.jpg";
import img23 from "../images/delhi2.jpg";
import img24 from "../images/delhi3.jpg";
import { useHistory } from "react-router-dom";
import nothernhills from "../doc/NortheasternHills.pdf";



function NortheasternHills(){
  const history = useHistory();

  const routeChange = () =>{ 
    let path = `/customTour`; 
    history.push(path);
  }


return(
  <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    Northeastern  <strong>Hills </strong>
    </h1>
    
  </Col>

  <Col lg="12">
    <p className="text-justify mt-2 tour-para"><span>The North East India tour </span> is like a dream. The mountainous terrain is bound to win the hearts of every tourist. Our North India tours encompass top destinations like Delhi, Darjeeling, Gangtok, and Kalimpong via the Rumtek Monastery. The tour itinerary covers every tourist spot of the location including scenic sights, historical monuments, shopping centres and much more.
    </p>

    
    <p className="text-right mb-5"><Button className="mr-5" onClick={routeChange}>Book Now</Button>
  <Button href={nothernhills} target = "_blank"> Read Itinerary Details </Button> </p>
  </Col>
</Row>

<Row>

<Col lg="6">
<Accordion  defaultActiveKey="0">
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
      Day 01

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <h5>Delhi</h5>
        <img src={img1} className="img-fluid mr-3" />
        <img src={img2} className="img-fluid mr-3" />
        <img src={img3} className="img-fluid mr-3" />
        <p className="text-justify">Arrival in Delhi and transfer to Hotel. Check in at Noon. Relax for the rest of the day.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
      Day 02

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
      <h5>Delhi</h5>
      <img src={img4} className="img-fluid mr-3" />
        <img src={img5} className="img-fluid mr-3" />
        <img src={img6} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast, take a sightseeing tour of Old and New Delhi, visiting the Jama Masjid (1465 A.D.); then drive through the fabled Chandni Chowk (Silver Street) and see the Red Fort, Rajghat, where the "Father of the nation", Mahatma Gandhi, was cremated; the 72 metre high Qutab Minar, the 1500-year-old Iron Pillar, government buildings, the President's House, Parliament House, India Gate, Laxmi Narayan Temple, and last but not least Delhi's main shopping centre, Connaught Place. Ample time will be available to appreciate this lovely city which resembles a vast and beautiful park.

</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
      Day 03

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body>
      <h5>Delhi - Darjeeling</h5>
      <img src={img7} className="img-fluid mr-3" />
        <img src={img8} className="img-fluid mr-3" />
        <img src={img9} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast, fly to Bagdogra, a three hour drive from Bagdogra airport; an area covered with tea gardens and buildings that are a legacy of the days of British rule in India, Darjeeling is still unspoiled and pristine. After check-in at the hotel, the rest of the day is free.

</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="3">
        Day 04
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="3">
      <Card.Body>
      <h5>Darjeeling</h5>
      <img src={img10} className="img-fluid mr-3" />
        <img src={img11} className="img-fluid mr-3" />
        <img src={img12} className="img-fluid mr-3" />
        <p className="text-justify">Very early wake-up call to watch the sunrise from Tiger Hill. After breakfast, visit the Yiga Choeling Buddhist Monastery at Ghoom - 6 km. above Darjeeling, pure and unspoiled , standing at an altitude of 7,407 ft. In the afternoon, take a sightseeing tour of Darjeeling, including Lebong Village which has the highest race course in the world, and the Mountaineering Institute on Birch Hill, which trains climbers of any nation. After lunch take the "Toy Train" ride up to Ghoom and back by Car/Jeep (Please note that the train is subject to being operational)</p>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  </Accordion>
</Col>

<Col lg="6">
<Accordion  defaultActiveKey="0">

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="4">
      Day 05
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="4">
      <Card.Body>
      <h5>Darjeeling - Gangtok</h5>
      <img src={img13} className="img-fluid mr-3" />
        <img src={img14} className="img-fluid mr-3" />
        <img src={img15} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast, drive to Gangtok. In the afternoon, take a city tour of Gangtok, the capital of Sikkim. Visit the White Memorial Hall, the Residency, which is the house for the Political Officer of the Government of India. An excellent view of the mountains can be had from this point. Visit the White Memorial Hall, the Research Institute of Tibetology which has a library of more than 30,000 books on Buddhism, astrology, medicine, philosophy and Tankhas (religious paintings on cloth), Deer Park - a walking excursion - built to commemorate the Buddha's reincarnation as a musk deer, Enchey Monastery, Cottage Industry, Do Drul Chorten and the Market.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="5">
      Day 06
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="5">
      <Card.Body>
      <h5>Gangtok- Kalimpong via the Rumtek Monastery</h5>
      <img src={img16} className="img-fluid mr-3" />
        <img src={img17} className="img-fluid mr-3" />
        <img src={img18} className="img-fluid mr-3" />
        <p className="text-justify">In the morning visit the Rumtek monastery with its many historical documents on Buddhism and then continue to Kalimpong. In the afternoon take a city tour of Kalimpong, which was once a starting point for the land route to Tibet and still retains its Tibetan flavour. It is noted for its good schools and cultural institutions and for its Tibetan Monastery on Tirupati history. Bhutias and Tibetans trade their wares in the central market square.

</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="6">
      Day 07
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="6">
      <Card.Body>
      <h5>Kalimpong-Darjeeling-Delhi</h5>
      <img src={img19} className="img-fluid mr-3" />
        <img src={img20} className="img-fluid mr-3" />
        <img src={img21} className="img-fluid mr-3" />
        <p className="text-justify">Transfer from Kalimpong to Bagdogra for flight to Delhi. On arrival, transfer to hotel. Rest of the day free.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="7">
      Day 08
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="7">
      <Card.Body>
      <h5>Delhi</h5>
      <img src={img22} className="img-fluid mr-3" />
        <img src={img23} className="img-fluid mr-3" />
        <img src={img24} className="img-fluid mr-3" />
        <p className="text-justify">Leave Delhi for onward destination in the afternoon.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
</Col>
</Row>
</Container>
</>
);
}

export default NortheasternHills;
