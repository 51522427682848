import React, { Component, useEffect, useState } from "react";
import { Navbar, Nav, Container, Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../logo.jpg";
/*class Header extends Component {
  state = {};
  constructor(props) {
    super(props);
  }*/
  function Header(){
    const location = useLocation();
   
    const [isTours, setTours] = useState(false);
    const [isCustomTours, setCustomTours] = useState(false);
    
    /*useEffect(() => {
    false
  }, isTours);*/

    //console.log("location value is first "+location.pathname);
    //console.log("First Tour value is "+isTours);

    if(!isTours && (location.pathname.indexOf('glimpseofindia')>=0 || location.pathname.indexOf('goldentriangle')>=0 || location.pathname.indexOf('magicofindia')>=0 || location.pathname.indexOf('tranquilkerala')>=0 || location.pathname.indexOf('palaceonwheels')>=0
    || location.pathname.indexOf('surfsand')>=0 || location.pathname.indexOf('rajasthanexplorer')>=0 || location.pathname.indexOf('indiaexplorer')>=0 || location.pathname.indexOf('northeasternhills')>=0 || location.pathname.indexOf('exoticnepal')>=0
    || location.pathname.indexOf('tajhighlights')>=0 || location.pathname.indexOf('bestofindia')>=0 || location.pathname.indexOf('romancingthetaj')>=0 || location.pathname.indexOf('royalrajasthanonwheels')>=0 || location.pathname.indexOf('tajwildernessofnorthindia')>=0
    || location.pathname.indexOf('tajexoticaresort')>=0 || location.pathname.indexOf('essenceofsriLanka')>=0))
     { 
     
     // console.log("Tour value inside before "+isTours);
     // console.log("Custom Tour value inside before "+isCustomTours);
       setTours(true);
       setCustomTours(false);
      // console.log("Tour value inside after "+isTours);
      // console.log("Custom Tour value inside after "+isCustomTours);
     }
     else if(!isCustomTours && location.pathname.indexOf('customTour')>=0)
     {
      //console.log("Custom Tour value inside "+isCustomTours);
      setTours(false);
      setCustomTours(true);
     }
   

    const openExternalURL=()=> {
      window.open("http://www.handatravel.com", "_parent");
    }

    const setdefaultActivestatus=()=>{
      setTours(false);
      setCustomTours(false);

      //console.log("Button clicked ");
    }

 // render() {
    return (
      <header>
        <Container>
          <Row className="py-2">
            <Col>
              <img src={logo} />
            </Col>
            <Col>
              <p className="number"><i className="fa fa-phone mr-2"></i> Toll free: 1-855-604-8687</p>
            
            </Col>
            <Col>
              <p className="number"><i className="fa fa-envelope-o mr-2"></i> sales@tajtour</p>
            </Col>
          </Row>
        </Container>
        <Navbar expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav activeKey="/">
              <LinkContainer exact to="/">
                <Nav.Link onClick={() => setdefaultActivestatus()} active={false}>HOME</Nav.Link>
              </LinkContainer>

              <LinkContainer exact to="/tours" >
                <Nav.Link onClick={() => setdefaultActivestatus()} active={isTours}>TOURS</Nav.Link>
              </LinkContainer>
              <LinkContainer exact to="/cityEscapes">
                <Nav.Link onClick={() => setdefaultActivestatus()} active={false}>CITY ESCAPES</Nav.Link>
              </LinkContainer>
              <LinkContainer exact to="/yoga">
                <Nav.Link onClick={() => setdefaultActivestatus()} active={false}>YOGA</Nav.Link>
              </LinkContainer>
              <LinkContainer exact to="/price">
                <Nav.Link onClick={() => setdefaultActivestatus()} active={false}>PRICE</Nav.Link>
              </LinkContainer>
              <LinkContainer exact to="/whyus">
                <Nav.Link onClick={() => setdefaultActivestatus()} active={false}>WHY US</Nav.Link>
              </LinkContainer>
              <LinkContainer exact to="/customTour">
                <Nav.Link onClick={() => setdefaultActivestatus()} active={isCustomTours}>CUSTOM TOUR</Nav.Link>
              </LinkContainer>
              <LinkContainer exact to="/testimonials">
                <Nav.Link onClick={() => setdefaultActivestatus()} active={false} >TESTIMONIALS</Nav.Link>
              </LinkContainer>
              <LinkContainer exact to="/tellaFriend">
                <Nav.Link onClick={() => setdefaultActivestatus()} active={false} >TELL A FRIEND</Nav.Link>
              </LinkContainer>
              <LinkContainer exact to="/generalInfo">
                <Nav.Link onClick={() => setdefaultActivestatus()} active={false}>GENERAL INFO</Nav.Link>
              </LinkContainer>
              <LinkContainer
                exact
                to="/handatravel"
                onClick={openExternalURL}
              >
                <Nav.Link>HANDA TRAVEL</Nav.Link>
              </LinkContainer>
              <LinkContainer exact to="/contactUs">
                <Nav.Link onClick={() => setdefaultActivestatus()} active={false}>CONTACT US</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    );
  //}
  /*openExternalURL() {
    window.open("http://www.handatravel.com", "_parent");
  }
  openExternalHome() {
    window.open("http://www.tajtour.com/", "_parent");
  }
  openExternalTours() {
    window.open("http://www.tajtour.com/tour-packages.php", "_parent");
  }
  openExternalCityEscapes() {
    window.open("http://www.tajtour.com/fit.php", "_parent");
  }
  openExternalYoga() {
    window.open("http://www.tajtour.com/yoga.php", "_parent");
  }
  openExternalPrice() {
    window.open("http://www.tajtour.com/price.php", "_parent");
  }
  openExternalWhyus() {
    window.open("http://www.tajtour.com/why-us.php", "_parent");
  }
  openExternalTestimonial() {
    window.open("http://www.tajtour.com/testimonials.php", "_parent");
  }
  openExternalTellafriend() {
    window.open("http://www.tajtour.com/refer-us.php", "_parent");
  }
  openExternalGeneralInfo() {
    window.open("http://www.tajtour.com/information.php", "_parent");
  }
  openExternalGeneralInfo() {
    window.open("http://www.tajtour.com/information.php", "_parent");
  }*/
}

export default Header;
