import React, { Component } from "react";
import {Container, Col, Row, Button, Card, Accordion} from "react-bootstrap";
import banner from "../images/banners/best-of-india.jpg";

import img1 from "../images/hotel1.jpg";
import img2 from "../images/hotel2.jpg";
import img3 from "../images/old-delhi.jpg";

import img4 from "../images/delhi.jpg";
import img5 from "../images/delhi2.jpg";
import img6 from "../images/delhi3.jpg";

import img7 from "../images/varanasi4.jpg";
import img8 from "../images/varanasi5.jpg";
import img9 from "../images/sarnath.jpg";

import img10 from "../images/varanasi.jpg";
import img11 from "../images/varanasi6.jpg";
import img12 from "../images/varanasi5.jpg";

import img13 from "../images/khajuraho.jpg";
import img14 from "../images/khajuraho2.jpg";
import img15 from "../images/khajuraho3.jpg";

import img16 from "../images/taj-mahal.jpg";
import img17 from "../images/agra-fort2.jpg";
import img18 from "../images/fatehpur-sikri3.jpg";

import img19 from "../images/castle-riding.jpg";
import img20 from "../images/hawa-mahal.jpg";
import img21 from "../images/pink-city.jpg";

import img22 from "../images/city-palace.jpg";
import img23 from "../images/jagdish-temple.jpg";
import img24 from "../images/sahelion-ki-badi.jpg";

import img25 from "../images/rajabai-clock-tower.jpg";
import img26 from "../images/mani-bhawan.jpg";
import img27 from "../images/aquarium.jpg";

import img28 from "../images/valluvar-kottam.jpg";
import img29 from "../images/sathome-cathedral.jpg";
import img30 from "../images/kapaleeswarar-temple.jpg";

import img31 from "../images/mahabalipuram.jpg";
import img32 from "../images/mahabalipuram2.jpg";
import img33 from "../images/kanchipuram.jpg";

import img34 from "../images/thirumalai-nayak-palace.jpg";
import img35 from "../images/meenakshi-temple.jpg";
import img36 from "../images/temple-ceremony.jpg";

import img37 from "../images/lake-periyar.jpg";
import img38 from "../images/periyar-sanctuary.jpg";
import img39 from "../images/boat-ride.jpg";

import img40 from "../images/ketuvallam.jpg";
import img41 from "../images/boat-ride1.jpg";
import img42 from "../images/kathakali-dance.jpg";

import img43 from "../images/mattancheery-palace.jpg";
import img44 from "../images/jewish-synagogue.jpg";
import img45 from "../images/fort-cochin.jpg";

import img46 from "../images/brindavan-gardens.jpg";
import img47 from "../images/brindavan-gardens2.jpg";
import img48 from "../images/tippu-palace.jpg";

import img49 from "../images/shravanvbelgol.jpg";
import img50 from "../images/vidhana-soudha.jpg";
import img51 from "../images/tippu-palace.jpg";

import img52 from "../images/goa2.jpg";
import img53 from "../images/goa3.jpg";
import img54 from "../images/goa4.jpg";

import img55 from "../images/goa-beach4.jpg";
import img56 from "../images/goa5.jpg";
import img57 from "../images/goa.jpg";

import img58 from "../images/goa-beach.jpg";
import img59 from "../images/goa-beach2.jpg";
import img60 from "../images/goa-beach3.jpg";

import img61 from "../images/bombay.jpg";
import img62 from "../images/bombay2.jpg";
import img63 from "../images/airport3.jpg";
import { useHistory } from "react-router-dom";
import bestofindia from "../doc/BestOfIndia.pdf";


function BestofIndia(){
  const history = useHistory();

  const routeChange = () =>{ 
    let path = `/customTour`; 
    history.push(path);
  }


return( 
  <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    Best of  <strong>India </strong>
    </h1>
    
  </Col>

  <Col lg="12">
    <p className="text-justify mt-2 tour-para"><span>With our India travel packages </span> gather the best of India in 21 days. On our tours and trips visit the 15 top tourist destinations in India. The destinations in this package include Delhi, Varanasi, Kahjuraho, Agra, Jaipur, Udaipur, Mumbai, Madras, Madurai, Periyar, Aleppey, Cochin, Mysore, Bangalore, and Goa. The trip is well prepared with complete itinerary and comfortable travel and accommodation facilities.
    </p>

    
    <p className="text-right mb-5"><Button className="mr-5" onClick={routeChange}>Book Now</Button>
  <Button href={bestofindia} target = "_blank"> Read Itinerary Details </Button> </p>
  </Col>
</Row>

<Row>

<Col lg="6">
<Accordion  defaultActiveKey="0">
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
      Day 01

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <h5>Delhi</h5>
        <img src={img1} className="img-fluid mr-3" />
        <img src={img2} className="img-fluid mr-3" />
        <img src={img3} className="img-fluid mr-3" />
        <p className="text-justify">Arrival in Delhi and transfer to hotel. Morning free to relax. Afternoon sightseeing tour of Old Delhi.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
      Day 02

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
      <h5>Delhi</h5>
      <img src={img4} className="img-fluid mr-3" />
        <img src={img5} className="img-fluid mr-3" />
        <img src={img6} className="img-fluid mr-3" />
        <p className="text-justify">Sightseeing tour of the New Delhi. Afternoon free.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
      Day 03

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body>
      <h5>Delhi - Varanasi</h5>
      <img src={img7} className="img-fluid mr-3" />
        <img src={img8} className="img-fluid mr-3" />
        <img src={img9} className="img-fluid mr-3" />
        <p className="text-justify">In the morning, fly to Varanasi and transfer to hotel. In afternoon excursion to Sarnath.</p>



      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="3">
        Day 04
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="3">
      <Card.Body>
      <h5>Varanasi - Khajuraho</h5>
      <img src={img10} className="img-fluid mr-3" />
        <img src={img11} className="img-fluid mr-3" />
        <img src={img12} className="img-fluid mr-3" />
        <p className="text-justify">Early wake up and depart for boat ride on River Ganges. Return to the hotel for breakfast. Afterwards, take a city tour of Varanasi. Late afternoon fly to Khajuraho.</p>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="4">
      Day 05
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="4">
      <Card.Body>
      <h5>Khajuraho - Agra</h5>
      <img src={img13} className="img-fluid mr-3" />
        <img src={img14} className="img-fluid mr-3" />
        <img src={img15} className="img-fluid mr-3" />
        <p className="text-justify">Morning city sightseeing of Khajuraho Temples. Evening drive to Jhansi railway station board train to Agra. Evening, at leisure.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="5">
      Day 06
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="5">
      <Card.Body>
      <h5>Agra - Jaipur</h5>
      <img src={img16} className="img-fluid mr-3" />
        <img src={img17} className="img-fluid mr-3" />
        <img src={img18} className="img-fluid mr-3" />
        <p className="text-justify">In the morning, visit the Taj Mahal and Agra Fort. In the afternoon, travel by coach to Jaipur. Enroute visit Fatehpur-Sikri.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
   
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="6">
      Day 07

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="6">
      <Card.Body>
      <h5>Jaipur</h5>
      <img src={img19} className="img-fluid mr-3" />
        <img src={img20} className="img-fluid mr-3" />
        <img src={img21} className="img-fluid mr-3" />
        <p className="text-justify">Breakfast at hotel and climb to the Amber Fort on the back of an elephant as the Fort is built on a hill. Afternoon city tour of Jaipur, visit The Palace of Winds or Hawa Mahal, Cit Palace and Observatory and an orientation drive through Jaipur, which is known as the "pink" city</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="7">
      Day 08
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="7">
      <Card.Body>
      <h5>Jaipur - Udaipur</h5>
      <img src={img22} className="img-fluid mr-3" />
        <img src={img23} className="img-fluid mr-3" />
        <img src={img24} className="img-fluid mr-3" />
        <p className="text-justify">Morning transfer to Airport for flight to Bombay. On arrival transfer to "GATEWAY OF INDIA" monument for an excursion to Elephanta Caves. After check-in, take a sightseeing tour of Bombay, visiting Dhobi Ghat, Rajabai Clock tower and the High court, Marine Drive, The Aquarium, Kamla Nehru Park, Hanging Gardens, Mahalaxmi Temple, Haji Ali`s Tomb, Mani Bhawan and Crawford Market.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="8">
      Day 09

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="8">
      <Card.Body>
      <h5>Udaipur - Bombay</h5>
      <img src={img25} className="img-fluid mr-3" />
        <img src={img26} className="img-fluid mr-3" />
        <img src={img27} className="img-fluid mr-3" />
        <p className="text-justify">Morning transfer to Airport for flight to Bombay. On arrival transfer to "GATEWAY OF INDIA" monument for an excursion to Elephanta Caves. After check-in, take a sightseeing tour of Bombay, visiting Dhobi Ghat, Rajabai Clock tower and the High court, Marine Drive, The Aquarium, Kamla Nehru Park, Hanging Gardens, Mahalaxmi Temple, Haji Ali`s Tomb, Mani Bhawan and Crawford Market.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="9">
      Day 10
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="9">
      <Card.Body>
      <h5>Bombay - Madras</h5>
      <img src={img28} className="img-fluid mr-3" />
        <img src={img29} className="img-fluid mr-3" />
        <img src={img30} className="img-fluid mr-3" />
        <p className="text-justify">Morning flight to Madras. Transfer to hotel and sightseeing tour of Madras. visiting Valluvar Kottam, Fort St. George, Santhome Cathedral, Kapaleeswarar temple. Evening enjoy at Marina Beach.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  </Accordion>
</Col>

<Col lg="6">
<Accordion  defaultActiveKey="0">


  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="10">
      Day 11
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="10">
      <Card.Body>
      <h5>Madras</h5>
      <img src={img31} className="img-fluid mr-3" />
        <img src={img32} className="img-fluid mr-3" />
        <img src={img33} className="img-fluid mr-3" />
        <p className="text-justify">Full day excursion to Mahabalipuram and Kanchipuram.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="11">
      Day 12
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="11">
      <Card.Body>
      <h5>Madurai</h5>
      <img src={img34} className="img-fluid mr-3" />
        <img src={img35} className="img-fluid mr-3" />
        <img src={img36} className="img-fluid mr-3" />
        <p className="text-justify">Late morning fly to Madurai. Afternoon sightseeing tour of Madurai. visit to the Thirumalai Nayak Palace and the famous Meenakshi Temple Complex. Evening stay at the Temple to witness various ceremonies.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>


  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="12">
      Day 13
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="12">
      <Card.Body>
      <h5>Madurai - Periyar</h5>
      <img src={img37} className="img-fluid mr-3" />
        <img src={img38} className="img-fluid mr-3" />
        <img src={img39} className="img-fluid mr-3" />
        <p className="text-justify">Morning departure by coach to Periyar. On arrival check-in at hotel. Evening visit to Game Sanctuary by boat.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="13">
      Day 14
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="13">
      <Card.Body>
      <h5>Periyar - Aleppey - Cochin</h5>
      <img src={img40} className="img-fluid mr-3" />
        <img src={img41} className="img-fluid mr-3" />
        <img src={img42} className="img-fluid mr-3" />
        <p className="text-justify">Morning visit to Game Sanctuary. Later departure by coach to Aleppey. In Aleppey, board a Rice-boat to cruise through the exotic backwaters to Cochin. On arrival check-in at hotel. Evening visit to a local Theatre to witness "KATHAKALI" dance show.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="14">
      Day 15
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="14">
      <Card.Body>
      <h5>Cochin - Mysore</h5>
      <img src={img43} className="img-fluid mr-3" />
        <img src={img44} className="img-fluid mr-3" />
        <img src={img45} className="img-fluid mr-3" />
        <p className="text-justify">In the morning, take a sightseeing tour of Cochin. Visit the Mattancherry Palace, the oldest Synagogue in the British Commonwealth, and Chinese fishing nets. At noon transfer to Airport for flight to Bangalore. On arrival take coach to Mysore.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="15">
      Day 16
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="15">
      <Card.Body>
      <h5>Mysore</h5>
      <img src={img46} className="img-fluid mr-3" />
        <img src={img47} className="img-fluid mr-3" />
        <img src={img48} className="img-fluid mr-3" />
        <p className="text-justify">Sightseeing tour of Mysore. Evening visit to Brindavan Gardens.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="16">
      Day 17
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="16">
      <Card.Body>
      <h5>Mysore - Bangalore</h5>
      <img src={img49} className="img-fluid mr-3" />
        <img src={img50} className="img-fluid mr-3" />
        <img src={img51} className="img-fluid mr-3" />
        <p className="text-justify">Morning departure by coach to Bangalore. Enroute visit Shravanabelagola. Afternoon sightseeing tour of Bangalore visiting Vidhana Soudha, The Bull Temple, Tipu's Palace and Lalbagh Garden.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="17">
      Day 18
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="17">
      <Card.Body>
      <h5>Bangalore - Goa</h5>
      <img src={img52} className="img-fluid mr-3" />
        <img src={img53} className="img-fluid mr-3" />
        <img src={img54} className="img-fluid mr-3" />
        <p className="text-justify">Morning flight to Goa. On arrival check-in at hotel. Day free in Goa.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="18">
      Day 19
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="18">
      <Card.Body>
      <h5>Goa</h5>
      <img src={img55} className="img-fluid mr-3" />
        <img src={img56} className="img-fluid mr-3" />
        <img src={img57} className="img-fluid mr-3" />
        <p className="text-justify">Day free in Goa.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="19">
      Day 20
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="19">
      <Card.Body>
      <h5>Goa</h5>
      <img src={img58} className="img-fluid mr-3" />
        <img src={img59} className="img-fluid mr-3" />
        <img src={img60} className="img-fluid mr-3" />
        <p className="text-justify">Day free in Goa.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>


  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="20">
      Day 21
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="20">
      <Card.Body>
      <h5>Goa - Bombay</h5>
      <img src={img61} className="img-fluid mr-3" />
        <img src={img62} className="img-fluid mr-3" />
        <img src={img63} className="img-fluid mr-3" />
        <p className="text-justify">In the afternoon fly to Bombay. On arrival transfer to International Airport for return flight.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
</Col>
</Row>
</Container>
</>
);
}
export default BestofIndia;
