import React, { Component } from "react";
import {Container, Col, Row, Blockquote  } from "react-bootstrap";
import banner from "../images/banners/refer-us.jpg";
  const Testimonials = () => ( 
    <>
      <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>
        <Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    Testimonials
    </h1>
  </Col>

  <Col lg="12">
  <blockquote className="blockquote mt-5">
    <p>After months of preparation it's all over. We're back home after a couple of days in Amsterdam and have gotten over the effects of jet lag. The trip was exceptional in all respects. Not a moment of disappointment anywhere.</p>
    <p>We loved India, ate well,saw interesting places, learnt a little of the culture and met nice people like our driver, Vikram, our safari guide Vijay and the city guides, like your friend Antonio. Would you please tell him that it was a pleasure meeting him and we appreciated his gift of cashews.</p>
    <p>The highlight of the trip was undoubtedly seeing tigers in Ranthambore. I am glad that we planned for city, temple and fort visits as well as a nature interlude in Ranthambore and finally, after the hustle and bustle of Mumba, i a 3 day stay on the beach at Goa. The place was perfect. We would return anytime and would probably visit Kerala, Pondicherry etc. next time.</p>
    <p>Many thanks for all your assistance throughout and particularly your calls along the way to see that all was going well. I haven't seen a tour organizer do that before. Once again the service of Taj Tours and Sita was outstanding and I would recommend you to our friends considering a little more exotic trip than the standard European vacation.</p>
    <p>Thanks again and best of luck.</p>
    <footer><cite title="Source Title">Jean-Paul Robitaille - February 18 - 04 March, 2016 - Saint Lambert Q.C</cite></footer>
    </blockquote>

    <blockquote className="blockquote mt-5">
    <p>Hello Desmond - Long overdue, but just to let you know our time in India was fantastic and a real adventure. Thank you for your assistance in making sure the Delhi, Agra, Jaipur, Cochin, Munnar visits were enjoyable.</p>
    <p>The drivers, hotels and guides arranged were excellent. All knowledgeable and helpful. Here is some specifics that may be useful in helping others build similarly fantastic trips </p>
    <p>The time at Elephantastic was an incredible experience, especially for our daughters (age 8 and 10). We would recommend that to everyone.</p>
    <p>The time in Munnar was lovely and such a contrast to our start in Delhi. The Spice Farm tour felt a bit like a tourist money grab. It is so interesting and has so much to offer it may be to everyone's advantage to change the way it is managed/presented. The tea plantation and hikes were amazing!</p>
    <p>Our final flight on SpiceJet was delayed ( approx 3 hours). The guide who met us at the airport had no idea of this and could not help us change to another airline to keep on our schedule. This result in the 1 bad day of the trip, since the delay meant we needed to continue traveling late into the night and several of us had bad reactions.</p>
    <p>On several other occasions - arranging a more central hotel for our second stay in Kochi, and reimbursing us for transportation costs to Choki Dhani in Jaipur - company was very helpful.</p>
    <p>Desmond - you really helped us create a fantastic trip, your expertise and knowledge and your pleasant personality were great! Many thanks.</p>
    <footer><cite title="Source Title">Cathy Callaghan - Toronto - Trip date 05 August - 18 August 2015</cite></footer>
    </blockquote>

    <blockquote className="blockquote mt-5">
  <p>
Desmond, we have returned weary and pleased with our trip. You provided what we asked for, tailoring the trip to our interests. SITA supported our trip almost seamlessly; agents meeting us at destinations, providing good guides, very competent drivers with comfortable up-to-date vehicles (with cold water at hand!). The hotels were all fine, even the more limited Mandawa Palace (which more than made up for less opulence with its historical uniqueness.)</p>

  <p>We opted for the hotel breakfast package, and that was quite enjoyable with a good sampling of Indian dishes as well as the Eurocentric ones. Drivers and guides took us to restaurants for lunch and they were good stops, reasonably priced.</p>

  <p>SITA agents had to do some quick action to deal with changes in domestic flights, which we understand are not at all unusual in India. All the people involved were unfailingly pleasant and attentive.</p>

  <p>Highlights were: the opportunity to visit the Taj Mahal 3 times - early morning, with a tour inside, afternoon view from the gardens across the river, and the night visit with a full moon; the Ajanta and Ellora caves, which everyone visiting India should see; a short walk through a farm village outside Agra with a local guide; Amber Fort; and an hours independent walk around a part of Udaipur's old city.</p>

  <p>We enjoyed the visit, we learned some things, we felt we got good value for our money. Thank you again</p>
  <footer><cite title="Source Title">Bill and PatLivingston Santa Rosa - CA - October 19, 2015</cite></footer>
</blockquote>


<blockquote className="blockquote mt-5">
  <p>
My wife and I completed our second trip to India organized by Taj Tour in Canada and SITA in India. This tour took us primarily to Rajasthan with a side trip to Nepal and lasted almost three weeks . Once again we found the organization to be outstanding. We were a group of just the two of us with our own driver for all of the India portion and a separate driver in Nepal. Our driver in India, Sonu, became like family to us since we were with him every day for about two weeks. He served as an excellent guide during the road trips between cities when we did not have a formal tour guide. The tour guides at the various cities and attractions were also excellent. In fact, our guide in Jaisalmer invited us into his home for some wonderful Chai prepared by his wife. It was a wonderful experience.</p>

  <p>Our accommodations were, without exception, excellent. We particularly enjoyed the heritage hotels in Mandawa, Bikaner, Pushkar and Jaipur.</p>

  <p>Our meals were all organized and paid for before the trip and were very good. The only downside was that some of the smaller hotels only had one or two main dishes for dinner and often they were continental when we would have preferred Indian. The larger hotels had a wide variety of dishes to suit all tastes. If we were to go again we might consider having our lunches less organized so that we could sample some "street food".</p>

  <p>There were many highlights to the trip and it would be hard to mention them all. Here are a few that stand out :</p>

  <p>Camel ride in the desert near Jaisalmer
Elephant ride in Jaipur
Jaisalmer Fort
Havelis in Mandawa
Flight to Mount Everest in Nepal
Our second visit to the Taj Mahal</p>

  <p>I could go on and on.</p>

  <p>We enjoyed ourselves so much and were so well taken care of that we are considering a third trip with Taj Tour and are thinking of possible itineraries. We feel confident that Taj Tour could come up with a trip to satisfy our needs at a reasonable price.</p>
  <p>We hope all is well Desmond.</p>

   <footer><cite title="Source Title">Dave Sigman - Napanee, Ontario - May 18, 2015</cite></footer>
</blockquote>

<blockquote className="blockquote mt-5">
  <p>
I wanted to take a few minutes to thank all of you for an amazing adventure- truly a once in a lifetime experience. Merry and Desmond, together we planned a fabulous itinerary - Adhijit, you and my Sita team made it happen. It is difficult to find the words to thank all of you so very much for making my trip to India, Nepal and Bhutan such an incredible journey.</p>

  <p>For many years I had wanted to go to India - to see and experience the culture. I did not want to go on a tour since I like flexibility and wish to really see and expiernce the local things in the country I am visiting. The Sita staff - drivers and guides made sure thatâ€™s exactly what I did. My team, particularly Marina and Benoy, made sure I got to where I needed to be. All staff members were professional and pleasant and made the trip even more enjoyable. My drivers were exceptional and I so appreciate all that they did for me. They played such an important role on the trip since I spent so much time with them - they were really remarkable and went out of their way to make certain I was comfortable.</p>

  <p>All are familiar with the Visa fiasco - without Sita, it most certainly would have been a disaster. Fortunately, after a bit of drama, it all worked out and I was able to complete the trip as planned. I am eternally grateful to Benoy and Marina for their hard work, professionalism and genuine concern in assisting me every step of the way of the Visa saga. Without their hands on assistance and persistence, I truly would have been lost. A special thanks to Marina for her hospitality in allowing me to celebrate Diwali with her and her sisters- it was the highlight of my time spent in Delhi and something I will never forget.</p>

  <p>As a single American woman traveling in India, I always felt safe and well cared for. The service I received from all concerned was superb. Nepal was also great and Bhutan exceptional. If ever any one I know wishes to travel to India, I would insist that they only use Merry, Desmond and Sita as their team. They would be guaranteed an outstanding vacation.</p>

  <p>Feel free to use my name as a reference. Also, I will be happy to speak to any one who is considering the trip and recommend that they go and use this team.</p>
  <p>Thank - you all again for such a wonderful adventure.</p>

  <footer><cite title="Source Title"> Shelley L. Berkowitz - Aventura, Florida - November 16, 2013 </cite></footer>
</blockquote>

<blockquote className="blockquote mt-5">
  <p>My wife and I recently returned from a three week tour of India organized by Taj Tour.</p>

  <p>The expression 'Incredible India' is true on so many levels. The palaces, forts and temples are incredible as are the markets and religious ceremonies. For a North American, the driving on the crowded roads filled with every manner of vehicle, people and animals is also incredible. While people immediately think of the Taj Mahal when they think of India, we can now say that the Taj Mahal is only one of the countless things to experience in India.</p>

  <p>Our tour took us from Delhi, Varanasi, Khajuraho, Agra, Jaipur and Udaipur in the North to Mumbai then on to Chennai, Madurai, Cochin, Mysore and Bangalore in the south. We had 8 internal flights, one train trip and countless car trips. We saw things by boat, car, foot and elephant.</p>

  <p>All of this was flawlessly organized by Taj Tour in Canada and there partners SITA in India. No matter where we went or when we arrived a representative, driver or knowledgeable tour guide was there to meet us. The hotels were mostly good to excellent as was the food. Considering all that we saw and experienced during a very hectic three weeks, the tour was remarkably stress free.</p>

  <p>We highly recommend that anyone planning such a trip should let Taj Tour organize it.</p>


   <footer><cite title="Source Title">David Sigman - Napanee, Ontario - December, 2012</cite></footer>
</blockquote>


<blockquote className="blockquote mt-5">
  <p>
Thank you Taj Tour for an outstanding tour of India & Nepal. Unparalled personal attention and well organized tours provided an exceptional holiday that I shall remember for the rest of my life. I was even provided with a personalized tour of Bhariperi (Benares) where my ancestors are from. When I return to India, it shall definitely be with Taj Tour.</p>

  <footer><cite title="Source Title"> Carlos Mahabir - Toronto - March 26, 2011 </cite></footer>
</blockquote>

<blockquote>
  <p>Thank you so much for the organizing you did for our trip.Everything went very smoothly. TAJ is a great organization. They efficiently took us through all the check-ins. The guides they had for us were very knowledgeable and gave us great tours. You are right - it was a hectic tour. We could have used a free day in Delhi before starting the Golden Triangle tour, but obviously we didn't have the time. We are so glad we were able to get to Ranthambore. We saw a tiger on our very first safari. The agent in Ranthambore went out of his way to ensure that everything went well. He was able to get us the jeep safaris for 3 of the 4 times. The switch to the Tiger Den on the second day was not an issue for us. The hotel facilities were actually better than the Ranthambore Regency, although the food wasn't quite as good. The other hotels were all top notch as far as food and accommodation were concerned. We were very impressed with our driver. He was a good driver and was able to answer our questions as we were going from city to city. I certainly would have no hesitation in using and recommending Taj Tour.</p>

  <p>Again, thanks so much.</p>

<footer><cite title="Source Title">Louise Bell - Owner/Travel Consultant - Stonetown Travel Ltd. - March 6, 2010</cite></footer>
</blockquote>


<blockquote className="blockquote mt-5">
  <p>The Golden Triangle tour provides me an opportunity for a brief acquaintance of India. To grasp the conception of India (or part of India) from reading or from TV images is far from realistic, and there is no comparison with a live witness.</p>
  <p>Delhi, the capital of the nation, typifies the soul of India, where many layers of civilizations flourished for more than three thousand years. There are many Mughal architectures and monuments reflecting different periods of history.</p>
  <p>Taj Mahal, near Agra, is not only magnificent, but also the arrangement of its whole complex of the related buildings and gardens is geometrically beautiful. Without being there, there is no way to appreciate its grandeur. No wonder Taj Mahal is one of the "new seven wonders of the world" chosen in a global poll.</p>
  <p>Jaipur is often called the pink city because many of its prominent buildings were built with pink sandstone. It was the first planned city in India with networks of gridded streets. An elephant ride reaching up to the Amber Fort is enjoyable. Once on top, strolling through the sprawling complex of courtyards and halls was fascinating. Jaipur is now one of the most important heritage cities in India, and is a must-see for tourists coming to India.</p>
  <p>The services for this tour were excellent. We were greeted warmly by the representative right on our arrival to Delhi's airport. Each link of the transportation, such as from airport to hotel, hotel to sightseeing, and from one city to other city, was well connected. The air-conditioned car used throughout the trip was comfortable. Our English speaking local guides and the driver are friendly and very knowledgeable. Not only they described thoroughly the places we visited, but also patiently answered our many questions. Consequently, we have learned more about the history and culture of India, and have better understanding of the daily life, religions, and customs of the Indian people. The accommodation in our tour was good, and particularly, the rooms in Holiday Inn in Agra and Jaipur were spacious, well equipped, and comfortable. The food suited us provided we chose the not too spicy one.</p>
  <p>Thank you for arranging this trip for us!</p>

  <footer><cite title="Source Title"> Chris Chan - Toronto - SEP 2008 </cite></footer>
</blockquote>

<blockquote className="blockquote mt-5">
  <p>
I am a travel consultant. Recently I travelled to India in Jan 2008. All arrangements were made with TAJ Tour. The arrangements were excellent. We were met and transported on time and with excellent drivers. Our guides were helpful and courteous. The hotels were fair to excellent depending on the category assigned. I would recommend 4 star hotels and never less than 3 1/2 stars.The itinerary selected by Taj Tour was comprehensive and gave us an excellent overview of India. I would recommend TAJ Tours to anyone wishing a well planned and executed tour of India.</p>



<footer><cite title="Source Title">Norma Shepherd - Toronto - Crusie Ship Centre</cite></footer>
</blockquote>


<blockquote className="blockquote mt-5">
  <p>
Taj Tour put together a fantastic tour of India in January 2007 - the "Golden Triangle" of Delhi, Agra and Jaipur, Kerela and Goa. Their suggestions for Kerala were right on, especially the visit to Munnar amidst the tea plantations. Our guides and drivers were the best. We especially appreciated their flexibility in allowing to get off the beaten path once in a while. Everything went so well, we're going back to India, with Taj Tour of course, in 2008.</p>

  <footer><cite title="Source Title"> Dave, Marcel - Ottawa </cite></footer>
</blockquote>

<blockquote className="blockquote mt-5">
  <p>
I've used Taj Tour Travel to arrange customized trips for me within India on a few occasions. I've found their personalized itineraries to be responsive to my needs and interests, their follow-up to be thorough and timely, and their services - including hotel, guide and travel arrangements on the ground in India - to be consistently reliable. I have no hesitation in recommending Taj Tour and the staff of Taj Tour Travel in Toronto to anyone wishing to arrange customized travel in India.</p>


<footer><cite title="Source Title"> Signed Joan McCalla - Toronto</cite></footer>
</blockquote>


<blockquote className="blockquote mt-5">
  <p>In November, my husband & I went to India on a trip of a lifetime. Neither of us had ever been before, and, while we knew something of India and what we might expect, it was a whole new experience for us. We contacted several agencies before deciding on Taj Tour. From the start, the staff of Taj Tour was very interested in just what we wanted to see and do, and made appropriate suggestions as needed. The staff at Taj Tour made us a part of each decision from hotels, route and answered every question we asked. Our trip went as completely as planned as is possible. The guides were informative, always on time, the hotels were fabulous and every place we visited had its own charm. We travelled by plane, train and automobile and had few delays, other than internal flights, which apparently is often the case. We had received an itinerary from Taj Tour , and this was follows almost to the letter. While India is not a place for every tourist, if you are interested in seeing an incredible world, are a bit adventurous and like to take people and things as they come, you could do no better than contacting Taj Tour. We are ready to go back and see more of this fantastic country. </p>
  <p>Thank You, Taj Tour</p>

  <footer><cite title="Source Title"> Catherine & Vassant Dabee - Ottawa </cite></footer>
</blockquote>
  </Col>

</Row>
</Container>
    </>
     );
export default Testimonials;
