import React, { Component } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import banner from "../images/banners/tour-packages.jpg";
import tourimg1 from "../images/picture1.jpg";
import tourimg2 from "../images/picture2.jpg";
import tourimg3 from "../images/picture3.jpg";
import tourimg4 from "../images/picture4.jpg";
import tourimg5 from "../images/picture5.jpg";
import tourimg6 from "../images/picture6.jpg";
import tourimg7 from "../images/picture7.jpg";
import tourimg8 from "../images/picture8.jpg";
import tourimg9 from "../images/picture9.jpg";
import tourimg10 from "../images/picture10.jpg";
import tourimg11 from "../images/picture11.jpg";
import tourimg12 from "../images/picture12.jpg";
import tourimg13 from "../images/picture13.jpg";
import tourimg14 from "../images/picture14.jpg";
import tourimg15 from "../images/picture15.jpg";
import tourimg16 from "../images/picture16.jpg";
import tourimg17 from "../images/picture17.jpg";
import { Link, withRouter } from "react-router-dom";

class Tours extends Component {
  render() {
    return (
      <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>
        <Container>
          <Row>
            <Col lg="12">
              <h1 className="mb-4 mt-5">
                Tour <strong>Packages</strong>
              </h1>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6">
              <img src={tourimg1} className="img-fluid" />
            </Col>
            <Col lg="6" className="tour-details">
              <h4 className="text-center mb-3 mt-3">Glimpses of India</h4>
              <p className="text-justify">
                Get a Glimpses of India with our tour of the most magnificent
                places in India. Taj Tours has put together a dream trip for
                travellers, with cities like Delhi, Jaipur, Agra, Khajuraho, and
                Varanasi. Visit the most mesmerizing monuments summarizing the
                historic richness ...
              </p>
              <p className="text-center mb-4">
                <Button onClick={() => this.nextPath("/glimpseofindia")}>
                  Read More
                </Button>
              </p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6">
              <img src={tourimg2} className="img-fluid" />
            </Col>
            <Col lg="6" className="tour-details">
              <h4 className="text-center mb-3 mt-3">Golden Triangle</h4>
              <p className="text-justify">
                This journey travels through the classic sights of India's most
                populist circuit - Delhi, Agra and Jaipur. This tour has been
                put together to represent the colourful shades that can be
                witnessed whilst travelling through the princely state...
              </p>
              <p className="text-center mb-4">
                <Button onClick={() => this.nextPath("/goldentriangle")}>
                  Read More
                </Button>
              </p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6">
              <img src={tourimg3} className="img-fluid" />
            </Col>
            <Col lg="6" className="tour-details">
              <h4 className="text-center mb-3 mt-3">Magic of India</h4>
              <p className="text-justify">
                See the magic of the seven awe inspiring cities of India: Delhi,
                Udaipur, Jodhpur, Jaipur, Agra, Khajuraho, and Varanasi in a 10
                day trip organized by our India Tours and travel service. With a
                complete itinerary planned for a smooth trip, we also make sure
                you are absolutely ....
              </p>
              <p className="text-center mb-4">
                <Button onClick={() => this.nextPath("/magicofindia")}>
                  Read More
                </Button>
              </p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6">
              <img src={tourimg4} className="img-fluid" />
            </Col>
            <Col lg="6" className="tour-details">
              <h4 className="text-center mb-3 mt-3">Tranquil Kerala</h4>
              <p className="text-justify">
                Capture the magic of Tranquil Kerala with our Southern travels
                plan. Visit the 5 sensational cities in the south of India to
                experience what the ancient and modern blend of India is like.
                The South India Tours by Taj Tour include the cities ....
              </p>
              <p className="text-center mb-4">
                <Button onClick={() => this.nextPath("/tranquilkerala")}>
                  Read More
                </Button>
              </p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6">
              <img src={tourimg5} className="img-fluid" />
            </Col>
            <Col lg="6" className="tour-details">
              <h4 className="text-center mb-3 mt-3">Palace on Wheels</h4>
              <p className="text-justify">
                A remarkable journey through an extraordinary region of India
                aboard the Palace on Wheels. The Palace on Wheels train that
                crosses the State of Rajasthan travels back in time to a world
                of maharajas, palaces, and legendary warriors.....
              </p>
              <p className="text-center mb-4">
                <Button onClick={() => this.nextPath("/palaceonwheels")}>
                  Read More
                </Button>
              </p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6">
              <img src={tourimg6} className="img-fluid" />
            </Col>
            <Col lg="6" className="tour-details">
              <h4 className="text-center mb-3 mt-3">Surf & Sand</h4>
              <p className="text-justify">
                The beaches of India are a must see. There are many famous beach
                tourist destinations in India and with that in mind, Taj tour
                gets you packages like Kerala holiday packages, Goa Tours etc.
                Experience the sunset on the magnificent ....
              </p>
              <p className="text-center mb-4">
                <Button onClick={() => this.nextPath("/surfsand")}>
                  Read More
                </Button>
              </p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6">
              <img src={tourimg7} className="img-fluid" />
            </Col>
            <Col lg="6" className="tour-details">
              <h4 className="text-center mb-3 mt-3">Rajasthan Explorer</h4>
              <p className="text-justify">
                Spend a vacation in the sea of sand; explore Rajasthan, the
                Indian state for the kings and royals. Our Rajasthan tour
                packages incorporate a comprehensive tour of the historically
                and culturally rich cities in India. The Rajasthan travel begins
                with .....
              </p>
              <p className="text-center mb-4">
                <Button onClick={() => this.nextPath("/rajasthanexplorer")}>
                  Read More
                </Button>
              </p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6">
              <img src={tourimg8} className="img-fluid" />
            </Col>
            <Col lg="6" className="tour-details">
              <h4 className="text-center mb-3 mt-3">India Explorer</h4>
              <p className="text-justify">
                Explore the magnificent cities of India like Delhi, Bombay,
                Udaipur, Jaipur, and Agra. The tour takes you to the most famous
                tourist spots of all the cities included. The itinerary is well
                planned for a comfortable trip with a local English .....
              </p>
              <p className="text-center mb-4">
                <Button onClick={() => this.nextPath("/indiaexplorer")}>
                  Read More
                </Button>
              </p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6">
              <img src={tourimg9} className="img-fluid" />
            </Col>
            <Col lg="6" className="tour-details">
              <h4 className="text-center mb-3 mt-3">Northeastern Hills</h4>
              <p className="text-justify">
                The North East India tour is like a dream. The mountainous
                terrain is bound to win the hearts of every tourist. Our North
                India tours encompass top destinations like Delhi, Darjeeling,
                Gangtok, and Kalimpong via the Rumtek Monastery. The tour .....
              </p>
              <p className="text-center mb-4">
                <Button onClick={() => this.nextPath("/northeasternhills")}>
                  Read More
                </Button>
              </p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6">
              <img src={tourimg10} className="img-fluid" />
            </Col>
            <Col lg="6" className="tour-details">
              <h4 className="text-center mb-3 mt-3">Exotic Nepal</h4>
              <p className="text-justify">
                Nepal is a beautiful country and a must visit for every nature
                lover. With this in mind, Taj Tour brings you Nepal Tours.
                Travel to Nepal with our well planned trip covering all the
                exotic tourist spots, including trekking The journey is made
                comfortable with excellent travel .....
              </p>
              <p className="text-center mb-4">
                <Button onClick={() => this.nextPath("/exoticnepal")}>
                  Read More
                </Button>
              </p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6">
              <img src={tourimg11} className="img-fluid" />
            </Col>
            <Col lg="6" className="tour-details">
              <h4 className="text-center mb-3 mt-3">Taj Highlights</h4>
              <p className="text-justify">
                The North East India tour is like a dream. The mountainous
                terrain is bound to win the hearts of every tourist. Our North
                India tours encompass top destinations like Delhi, Darjeeling,
                Gangtok, and Kalimpong via the Rumtek Monastery. The tour .....
              </p>
              <p className="text-center mb-4">
                <Button onClick={() => this.nextPath("/tajhighlights")}>
                  Read More
                </Button>
              </p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6">
              <img src={tourimg12} className="img-fluid" />
            </Col>
            <Col lg="6" className="tour-details">
              <h4 className="text-center mb-3 mt-3">Best of India</h4>
              <p className="text-justify">
                With our India travel packages gather the best of India in 21
                days. On our tours and trips visit the 15 top tourist
                destinations in India. The destinations in this package include
                Delhi, Varanasi, Kahjuraho, Agra, Jaipur, Udaipur, Mumbai,
                Madras .....
              </p>
              <p className="text-center mb-4">
                <Button onClick={() => this.nextPath("/bestofindia")}>
                  Read More
                </Button>
              </p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6">
              <img src={tourimg13} className="img-fluid" />
            </Col>
            <Col lg="6" className="tour-details">
              <h4 className="text-center mb-3 mt-3">Romancing the Taj</h4>
              <p className="text-justify">
                The North East India tour is like a dream. The mountainous
                terrain is bound to win the hearts of every tourist. Our North
                India tours encompass top destinations like Delhi, Darjeeling,
                Gangtok, and Kalimpong via the Rumtek Monastery. The tour .....
              </p>
              <p className="text-center mb-4">
                <Button onClick={() => this.nextPath("/romancingthetaj")}>
                  Read More
                </Button>
              </p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6">
              <img src={tourimg14} className="img-fluid" />
            </Col>
            <Col lg="6" className="tour-details">
              <h4 className="text-center mb-3 mt-3">
                Royal Rajasthan On Wheels
              </h4>
              <p className="text-justify">
                Experience the regal sojourn with leisure travel across the
                brave lands of Rajputana along with culturally rich destinations
                on 'Royal Rajasthan on Wheels'. This splendid journey begins
                from New Delhi, and takes you through erstwhile princely
                state.....
              </p>
              <p className="text-center mb-4">
                <Button
                  onClick={() => this.nextPath("/royalrajasthanonwheels")}
                >
                  Read More
                </Button>
              </p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6">
              <img src={tourimg15} className="img-fluid" />
            </Col>
            <Col lg="6" className="tour-details">
              <h4 className="text-center mb-3 mt-3">
                Taj & Wilderness of North India
              </h4>
              <p className="text-justify">
                This tour has been put together to represent the kaleidoscopic
                colors that can be witnessed whilst travelling through classic
                sights along with India's finest bird sanctuary and tiger
                reserves. Start by exploring the Mughal and Imperial monuments
                of Delhi.....
              </p>
              <p className="text-center mb-4">
                <Button
                  onClick={() => this.nextPath("/tajwildernessofnorthindia")}
                >
                  Read More
                </Button>
              </p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6">
              <img src={tourimg16} className="img-fluid" />
            </Col>
            <Col lg="6" className="tour-details">
              <h4 className="text-center mb-3 mt-3">
                Taj Exotica Resort & Spa Maldives
              </h4>
              <p className="text-justify">
                Taj Exotica Resort & Spa, Maldives, is an exclusive, private and
                romantic island resort lush with tropical plants and encircled
                by clear blue waters of one of the largest lagoons of the
                Maldives. Taj Exotica Resort & Spa spreads idyllically along a
                pristine beach....
              </p>
              <p className="text-center mb-4">
                <Button onClick={() => this.nextPath("/tajexoticaresort")}>
                  Read More
                </Button>
              </p>
            </Col>
          </Row>

          <Row className="mt-5 mb-5">
            <Col lg="6">
              <img src={tourimg17} className="img-fluid" />
            </Col>
            <Col lg="6" className="tour-details">
              <h4 className="text-center mb-3 mt-3">Essence of Sri Lanka</h4>
              <p className="text-justify">
                A lush tropical island, lapped by the warm waters of the Indian
                Ocean, Sri Lanka is a natural paradise with a landscape of
                verdant mountains, rainforests, paddy fields, tumbling
                waterfalls and sweeping gorges. This fabulous tour allows a mix
                of culture and sheer bliss ....
              </p>
              <p className="text-center mb-4">
                <Button onClick={() => this.nextPath("/essenceofsriLanka")}>
                  Read More
                </Button>
              </p>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
  nextPath(path) {
    this.props.history.push(path);
  }
}

export default withRouter(Tours);
