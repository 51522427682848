import React, { Component } from "react";
import {Container, Col, Row, Button, Card, Accordion} from "react-bootstrap";
import banner from "../images/banners/taj-and-wilderness.jpg";

import img1 from "../images/hotel1.jpg";
import img2 from "../images/hotel2.jpg";
import img3 from "../images/hotel3.jpg";

import img4 from "../images/Jama Masjid.jpg";
import img5 from "../images/india-gate.jpg";
import img6 from "../images/president-house.jpg";

import img7 from "../images/agra-fort.jpg";
import img8 from "../images/taj-mahal.jpg";
import img9 from "../images/taj-mahal2.jpg";

import img10 from "../images/chambal.jpg";
import img11 from "../images/chambal2.jpg";
import img12 from "../images/chambal3.jpg";

import img13 from "../images/chambal4.jpg";
import img14 from "../images/chambal5.jpg";
import img15 from "../images/chambal6.jpg";

import img16 from "../images/keoladeo-park.jpg";
import img17 from "../images/fatehpur-sikri3.jpg";
import img18 from "../images/birding-in-bharatpur.jpg";

import img19 from "../images/ramathra-fort.jpg";
import img20 from "../images/ramathra-fort2.jpg";
import img21 from "../images/kalisil-lake.jpg";

import img22 from "../images/ranthambore-national-park.jpg";
import img23 from "../images/ranthambore-national-park2.jpg";
import img24 from "../images/ranthambore-national-park3.jpg";

import img25 from "../images/pink-city.jpg";
import img26 from "../images/jaipur2.jpg";
import img27 from "../images/jaipur3.jpg";

import img28 from "../images/amber-fort1.jpg";
import img29 from "../images/amber-fort2.jpg";
import img30 from "../images/amber-fort3.jpg";

import img31 from "../images/delhi-airport.jpg";
import img32 from "../images/delhi-airport2.jpg";
import img33 from "../images/airport3.jpg";
import { useHistory } from "react-router-dom";
import tajwilderness from "../doc/Mini_Itinerary_Taj_and_Wilderness_of_North_India.pdf";


function TajWildernessofNorthIndia(){
  const history = useHistory();

  const routeChange = () =>{ 
    let path = `/customTour`; 
    history.push(path);
  }


return( 
  <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    Taj & Wilderness of  <strong>North India </strong>
    </h1>
    
  </Col>

  <Col lg="12">
    <p className="text-justify mt-2 tour-para"><span>This tour has been put together </span> to represent the kaleidoscopic colors that can be witnessed whilst travelling through classic sights along with India's finest bird sanctuary and tiger reserves. Start by exploring the Mughal and Imperial monuments of Delhi from here travel to Agra, home of the impressive Taj Mahal. Moving on the Chambal river, lies an oasis of tranquility nestling in sprawling acres of reclaimed woodland, next is finest bird parks in the world, Keoladeo Ghana National Park. From here move to a private fort perched on a slight hill, Ramathra Fort. Make your way through one of the India's finest tiger reserves - Ranthambore national park on a game drive and end your stay discovering the pink city of Jaipur.
    </p>

    
    <p className="text-right mb-5"><Button className="mr-5" onClick={routeChange}>Book Now</Button>
  <Button href={tajwilderness} target = "_blank"> Read Itinerary Details </Button> </p>
  </Col>
</Row>

<Row>

<Col lg="6">
<Accordion  defaultActiveKey="0">
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
      Day 01

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <h5>Delhi</h5>
        <img src={img1} className="img-fluid mr-3" />
        <img src={img2} className="img-fluid mr-3" />
        <img src={img3} className="img-fluid mr-3" />
        <p className="text-justify">Upon arrival at Delhi in the evening, you are met, greeted and escorted to the Hotel. You are welcomed in the hotel in a Traditional Indian style on arrival and check into the hotel for overnight.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
      Day 02

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
      <h5>Delhi</h5>
      <img src={img4} className="img-fluid mr-3" />
        <img src={img5} className="img-fluid mr-3" />
        <img src={img6} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast in the hotel, we leave for city sightseeing tour of Old Delhi & New Delhi.</p>

        <p>Old Delhi came into prominence when Shah Jahan moved his capital from Agra to Delhi. You visit 300 year old Chandni Chowk market. From here you continue to the Jama Masjid or Friday Mosque. This is India's largest mosque designed by Shah Jahan, and was his last architectural legacy.</p>

<p>Afternoon, we take our tour of New Delhi, visit Qutab Minar, Humayun's tomb, built by his senior wife and mother of emperor Akbar We drive pass the India Gate, Presidential Palace and Parliament House.</p>

<p>We return to hotel for overnight stay</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
      Day 03

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body>
      <h5>Delhi - Agra</h5>
      <img src={img7} className="img-fluid mr-3" />
        <img src={img8} className="img-fluid mr-3" />
        <img src={img9} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast we drive to Agra, upon arrival check in to hotel.</p>

<p>Afternoon we take a guided tour of Agra fort, built by Emperor Akbar At sunset, we take our Tonga (Horse Carriage) to the visit the world famous Taj Mahal (Closed on Friday) built by Shah Jahan in 1630 for his queen Mumtaz Mahal. We return to hotel for overnight stay</p>



      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="3">
        Day 04
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="3">
      <Card.Body>
      <h5>Agra - Chambal</h5>
      <img src={img10} className="img-fluid mr-3" />
        <img src={img11} className="img-fluid mr-3" />
        <img src={img12} className="img-fluid mr-3" />
        <p className="text-justify">After a leisurely breakfast we drive to Chambal, upon arrival check in to hotel. After lunch you have the opportunity to go for a nature walk around the lodge area and nearby farm land.</p>

<p>Overnight stay is at the hotel.</p>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="4">
      Day 05
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="4">
      <Card.Body>
      <h5>Chambal (National Chambal Sanctuary)</h5>
      <img src={img13} className="img-fluid mr-3" />
        <img src={img14} className="img-fluid mr-3" />
        <img src={img15} className="img-fluid mr-3" />
        <p className="text-justify">In the morning we will go a Safari.</p>

<p>After lunch, we go for a River safari provides opportunities to view spectacular wildlife. The Chambal Safari boatmen are exceptionally skilled at cutting the boat engines, and manoeuvring as close to the birds and animals as possible without disturbing them. The River Safari is a most relaxing, enjoyable and special experience. We return to hotel for overnight stay.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="5">
      Day 06
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="5">
      <Card.Body>
      <h5>Chambal - Keoladeo Ghana National Park</h5>
      <img src={img16} className="img-fluid mr-3" />
        <img src={img17} className="img-fluid mr-3" />
        <img src={img18} className="img-fluid mr-3" />
        <p className="text-justify">Morning breakfast is at leisure drive to Keoladeo Ghana National Park en route visit Fatehpur Sikri ,the former capital of the Mughal Empire.Birding in Bharatpur on a rickshaw is totally different kettle of fish, en entirely unique experience.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  </Accordion>
</Col>

<Col lg="6">
<Accordion  defaultActiveKey="0">

 
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="6">
      Day 07 & Day 08

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="6">
      <Card.Body>
      <h5>Keoladeo Ghana National Park - Ramathra</h5>
      <img src={img19} className="img-fluid mr-3" />
        <img src={img20} className="img-fluid mr-3" />
        <img src={img21} className="img-fluid mr-3" />
        <p className="text-justify">Travel to the unfrequented Fort of Ramathra for two nights. Exile yourself from the commotion of the big cities. You have the option to take a camel cart ride to Kalisil Lake. Watch the sunset by boat.</p>

<p>Overnight stay is at the hotel.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="7">
      Day 09 & Day 10
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="7">
      <Card.Body>
      <h5>Ramathra - Ranthambore National Park</h5>
      <img src={img22} className="img-fluid mr-3" />
        <img src={img23} className="img-fluid mr-3" />
        <img src={img24} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast we drive to Ranthambore, upon arrival check in to hotel.</p>

<p>In the afternoon you will go for your first game drive in the jungle. Ranthambore National Park is one of the finest tiger reserves in the country. After the game drive we return back to hotel for overnight stay.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="8">
      Day 11

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="8">
      <Card.Body>
      <h5>Ranthambore National Park - Jaipur</h5>
      <img src={img25} className="img-fluid mr-3" />
        <img src={img26} className="img-fluid mr-3" />
        <img src={img27} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast we drive to Jaipur the Pink City of India for two nights, In the evening, we have a cooking demonstration followed by an appetizing meal with a Rajputana Family</p>

<p>Overnight stay at hotel.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="9">
      Day 12
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="9">
      <Card.Body>
      <h5>Jaipur</h5>
      <img src={img28} className="img-fluid mr-3" />
        <img src={img29} className="img-fluid mr-3" />
        <img src={img30} className="img-fluid mr-3" />
        <p className="text-justify">Begin the day with a private yoga session. Later, visit the colossal Amber Fort. In the afternoon, take a City Tour and stroll in the local bazaar and in the evening, feel like a local as you watch Bollywood flick at a theatre designed in art deco style.</p>

<p>Overnight is at hotel.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="10">
      Day 13
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="10">
      <Card.Body>
      <h5>Jaipur - Delhi</h5>
      <img src={img31} className="img-fluid mr-3" />
        <img src={img32} className="img-fluid mr-3" />
        <img src={img33} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast we drive to Delhi international airport to board the flight to home. Meet and greet upon arrival and transfer to the International terminal for onward flight.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
</Col>
</Row>
</Container>
</>
);
}
export default TajWildernessofNorthIndia;
