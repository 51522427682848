import React, { Component } from "react";
import {Container, Col, Row, Button} from "react-bootstrap";
import banner from "../images/banners/wildlife-safaris.jpg";


const   WildlifeSafaris = () => ( 
  <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    <strong> Wildlife  </strong> Safaris 
    </h1>
    
  </Col>

  <Col lg="12">
  
<p className="text-justify mt-2 tour-para">
The best way to be close to the natural environment in India is to live it with the creatures living in it. Wildlife Safaris are gaining fast popularity in India as there are many resorts, sanctuaries, and national parks being established to care for the rich wild life we have. India has around 80 national parks, 441 sanctuaries, and 23 tiger reserves. These have been established by the government of India to conserve wildlife. The tourists who seek adventure in the wild and are thrilled with the idea of spending a vacation in the natural habitat, will love a wildlife safari for their next vacation plan.</p>

<p className="text-justify tour-para">Wildlife Tours India gives tourists the opportunity to completely explore the jungles of India and a chance to see animals like deer, zebras, lion, tigers, various birds, and much more in their wild habitat. This is the fun of a wildlife safari, you can travel along the jungle and try to spot various animals, some that you expected to be in there and some you have never thought of seeing at all!</p>

<p className="text-justify tour-para">The Wildlife Tours in India can be classified into categories like Bird Watching, Tiger Tours, or spotting wildlife in general. This is a must experience for children and adults. The Tiger Tour is becoming extremely popular in India as the threat of extinction becomes more pressing for this species. Bird Watching is another tour that is most sought after. These tours give you a glimpse into the peacock dance, exhibiting the splendor of its feathers, reminding us what an artist nature can be.</p>

<p className="text-justify tour-para">The wildlife tour also features a few days accomodation in the forest. You will stay in a wooden rest house or a tent, perfect to retire to after a busy day in the jungle. This is the best way to experience the jungle living, in the dense wilderness and with the twittering of birds waking you in the morning.</p>

<p className="text-justify tour-para">Taj Tour offers you various Wildlife Safari packages to choose from, and add the vacation of a lifetime to your memories. The beauty of the Wildlife Tour we offer is that you can experience it in the rough and speedy ride of the jeep or royally on the back of an elephant. Taj Tour takes care of all your requirements for the wildlife safari you book. Don't worry about the safari tour, hotel bookings, or travel arrangements, all is taken care of by us. All you have to do is sit back and enjoy!</p>

    
    <p className="text-right mb-5"><Button className="mr-5" href="" target="_blank">Book Now</Button> 
    <Button href=""> Read Itinerary Details </Button> </p>
  </Col>
</Row>


</Container>
</>
);

export default WildlifeSafaris;
