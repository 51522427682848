import React, { Component } from "react";
import {Container, Col, Row, Button, Card, Accordion} from "react-bootstrap";
import banner from "../images/banners/royal-rajasthan-on-wheels.jpg";

import img1 from "../images/safdarjung-railway-station.jpg";
import img2 from "../images/train.jpg";
import img3 from "../images/train2.jpg";

import img4 from "../images/jodhpur.jpg";
import img5 from "../images/jodhpur2.jpg";
import img6 from "../images/mehrangarh-fort.jpg";

import img7 from "../images/udaipur2.jpg";
import img8 from "../images/lake-pichola.jpg";
import img9 from "../images/crystal-gallery.jpg";

import img10 from "../images/ranthambore-national-park.jpg";
import img11 from "../images/caparisoned-elephants.jpg";
import img12 from "../images/hawa-mahal.jpg";

import img13 from "../images/khajuraho.jpg";
import img14 from "../images/khajuraho2.jpg";
import img15 from "../images/khajuraho3.jpg";

import img16 from "../images/varanasi.jpg";
import img17 from "../images/varanasi2.jpg";
import img18 from "../images/golden-temple.jpg";

import img19 from "../images/fatehpur-sikri.jpg";
import img20 from "../images/red-fort.jpg";
import img21 from "../images/taj-mahal.jpg";

import img22 from "../images/safdarjung-railway-station.jpg";
import img23 from "../images/train.jpg";
import img24 from "../images/train2.jpg";
import { useHistory } from "react-router-dom";
import royalrajastan from "../doc/royal_rajasthan_on_wheels_itinerary.pdf";


function RoyalRajasthanOnWheels(){
  const history = useHistory();

  const routeChange = () =>{ 
    let path = `/customTour`; 
    history.push(path);
  }


return(
  <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    Royal Rajasthan   <strong>On Wheels</strong>
    </h1>
    
  </Col>

  <Col lg="12">
    <p className="text-justify mt-2 tour-para"><span>Experience the regal sojourn </span> with leisure travel across the brave lands of Rajputana along with culturally rich destinations on 'Royal Rajasthan on Wheels'. This splendid journey begins from New Delhi, and takes you through erstwhile princely state of Jodhpur, Udaipur, Chittaurgarh, Ranthambhore Tiger Reserve, Jaipur, World Heritage Site Chandela Temples at Khajuraho, Varanasi and Taj Mahal at Agra. The entire train journey let you witness multihued Rajasthan is magnificent architecture, vibrant colour, and one of the Seven Wonders of the World and a world heritage site i.e. Taj Mahal. Every moment in the Royal Rajasthan on Wheels is an immemorial experience that is itched into the mind, body and soul forever. You will be delighted with magnificent interiors, sumptuous meals, fine wines and personalized services.
    </p>

    
    <p className="text-right mb-5"><Button className="mr-5" onClick={routeChange}>Book Now</Button>
  <Button href={royalrajastan} target = "_blank"> Read Itinerary Details </Button> </p>
  </Col>
</Row>

<Row>

<Col lg="6">
<Accordion  defaultActiveKey="0">
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
      Day 01

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <h5>Delhi - Jodhpur</h5>
        <img src={img1} className="img-fluid mr-3" />
        <img src={img2} className="img-fluid mr-3" />
        <img src={img3} className="img-fluid mr-3" />
        <p className="text-justify">At Delhi's Safdarjung Railway station, once boarding formalities are undertaken you will be afforded a warm and traditional welcome before boarding the Palace on Wheels. You will be given a brief familiarization of the tour, as we start our week-long journey through Rajasthan.</p>

<p>1830 hrs train will depart from the station.</p>

<p>Overnight onboard train.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
      Day 02

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
      <h5>Jodhpur</h5>
      <img src={img4} className="img-fluid mr-3" />
        <img src={img5} className="img-fluid mr-3" />
        <img src={img6} className="img-fluid mr-3" />
        <p className="text-justify">Breakfast will be served on board.</p>

<p>Your first stop is Jodhpur, a city ringed by a high stone wall with seven gates and several bastions. This second largest city of Rajasthan, founded by Rao Jodha in 1459 A.D. is known for its rock-solid Mehrangarh Fort.</p>

<p>After lunch, return to the train.</p>

<p>Overnight onboard train.

</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
      Day 03

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body>
      <h5>Chittorgarh & Udaipur</h5>
      <img src={img7} className="img-fluid mr-3" />
        <img src={img8} className="img-fluid mr-3" />
        <img src={img9} className="img-fluid mr-3" />
        <p className="text-justify">Breakfast will be served on board.</p>

<p>Welcome to Udaipur, the Lake City. Watermarked by whimsy and splendor, the "Venice of the East" holds stage as one of India's truly seductive cities with palaces, havelis, gardens and temples at every turn. Following breakfast, embark on a sightseeing tour of Udaipur, including a visit to the princely City Palace followed by lunch at a five-star hotel and a trip to the Crystal Gallery this afternoon.</p>

<p>Overnight onboard train.</p>



      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="3">
        Day 04
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="3">
      <Card.Body>
      <h5>Sawai Madhopur & Jaipur</h5>
      <img src={img10} className="img-fluid mr-3" />
        <img src={img11} className="img-fluid mr-3" />
        <img src={img12} className="img-fluid mr-3" />
        <p className="text-justify">One can almost sense a life of the wilderness as the train arrives early this morning in Sawai Madhopur, a city named after its founder Maharaja Sawai Madho Singh I. Visit the Ranthambore National Park, a wildlife sanctuary that is considered to be the country's best for observing and photographing the activities of the tiger. The park, covering an area of 392 sq.kms. is also home to more than 300 species of birds. The tiger, hyena, ratel, jackal and fox are among those constituting the carnivorous population here.</p>

<p>Breakfast will be served on board.</p>

<p>You are welcomed to Jaipur by caparisoned elephants and the sweet music of the auspicious 'Shehnai'. This capital city of Rajasthan, popularly known as the Pink City, was founded by Maharaja Sawai Jai Singh II in 1727 A.D. To explore the city, coach takes you to Hawa Mahal, also known as the Palace of Winds, This is followed by a visit to Amber Fort, eight kms. away, where elephant rides add to the ambiance.</p>

<p>Overnight onboard train.</p>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  </Accordion>
</Col>

<Col lg="6">
<Accordion  defaultActiveKey="0">

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="4">
      Day 05
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="4">
      <Card.Body>
      <h5>Khajuraho</h5>
      <img src={img13} className="img-fluid mr-3" />
        <img src={img14} className="img-fluid mr-3" />
        <img src={img15} className="img-fluid mr-3" />
        <p className="text-justify">Breakfast will be served on board.</p>

<p>Arrive this morning in Khajuraho, a city which exudes passion, eroticism, dance, and other forms of creative arts depicted in the sculpture and images of its temples.</p>

<p>Overnight onboard train.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="5">
      Day 06
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="5">
      <Card.Body>
      <h5>Varanasi</h5>
      <img src={img16} className="img-fluid mr-3" />
        <img src={img17} className="img-fluid mr-3" />
        <img src={img18} className="img-fluid mr-3" />
        <p className="text-justify">Breakfast will be served on board.</p>

<p>Awaken in the holy city of Varanasi, whose history dates back over 3,000 years. Temples can be found at every few paces, including the Jyotirlinga Visvanatha Temple, or Golden Temple which was rebuilt in 1776 and dedicated to Lord Shiva. Today's sightseeing tour includes visits to the Ganga Ghats, Maha Aarti, Buddhist temples at Sarnath and more.</p>
<p>Overnight onboard train.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="6">
      Day 07
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="6">
      <Card.Body>
      <h5>Bharatpur & Agra</h5>
      <img src={img19} className="img-fluid mr-3" />
        <img src={img20} className="img-fluid mr-3" />
        <img src={img21} className="img-fluid mr-3" />
        <p className="text-justify">Breakfast will be served on board.</p>

<p>Agra is one of the world's prominent tourist destinations, boasting three impressive heritage monuments. Explore the abandoned Mughal palatial complex of Fatehpur Sikri, followed by a visit to the Red Fort. Enjoy lunch at a five-star hotel and later, explore the Taj Mahal, one of the Seven Wonders of the World. Dinner will be served on board. We bid farewell to the Palace on Wheels and perhaps a final drink in the lounge bar.</p>

<p>Overnight onboard train</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="7">
      Day 08
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="7">
      <Card.Body>
      <h5>Delhi</h5>
      <img src={img22} className="img-fluid mr-3" />
        <img src={img23} className="img-fluid mr-3" />
        <img src={img24} className="img-fluid mr-3" />
        <p className="text-justify">Breakfast will be served on board.</p>

        <p>Today morning you will disembark from the train at the Safdarjung Railway Station</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
</Col>
</Row>
</Container>
</>
);
}
export default RoyalRajasthanOnWheels;
