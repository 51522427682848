import React, { Component } from "react";
import {Container, Col, Row, Button, Card, Accordion} from "react-bootstrap";
import banner from "../images/banners/magic-of-india.jpg";
import { useHistory } from "react-router-dom";

import img1 from "../images/hotel1.jpg";
import img2 from "../images/hotel2.jpg";
import img3 from "../images/hotel3.jpg";

import img4 from "../images/Jama Masjid.jpg";
import img5 from "../images/iron-pillar.jpg";
import img6 from "../images/laxmi-narayan.jpg";

import img7 from "../images/city-palace.jpg";
import img8 from "../images/sahelion-ki-badi.jpg";
import img9 from "../images/lake-pichola.jpg";

import img10 from "../images/mehrangarh-fort.jpg";
import img11 from "../images/jaswant-thada.jpg";
import img12 from "../images/clock-tower-markets.jpg";

import img13 from "../images/jaipur-city.jpg";
import img14 from "../images/shopping.jpg";
import img15 from "../images/jai-mahal-palace.jpg";

import img16 from "../images/castle-riding.jpg";
import img17 from "../images/kali-temple.jpg";
import img18 from "../images/pink-city.jpg";

import img19 from "../images/fatehpur-sikri.jpg";
import img20 from "../images/fatehpur-sikri2.jpg";
import img21 from "../images/taj-mahal.jpg";

import img22 from "../images/khajuraho.jpg";
import img23 from "../images/chatturhuih.jpg";
import img24 from "../images/parswanath.jpg";

import img25 from "../images/varanasi4.jpg";
import img26 from "../images/varanasi5.jpg";
import img27 from "../images/varanasi6.jpg";

import img28 from "../images/varanasi.jpg";
import img29 from "../images/golden-temple.jpg";
import img30 from "../images/airport.jpg";
import magicofindia from "../doc/MagicOfIndia.pdf";

  function MagicofIndia(){
    const history = useHistory();
  
    const routeChange = () =>{ 
      let path = `/customTour`; 
      history.push(path);
    }

    return(<>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>
<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    Magic of  <strong> India </strong>
    </h1>
    
  </Col>

  <Col lg="12">
    <p className="text-justify mt-2 tour-para"><span>See the magic </span> of the seven awe inspiring cities of India: Delhi, Udaipur, Jodhpur, Jaipur, Agra, Khajurao, and Varanasi in a 10 day trip organized by our India Tours and travel service. With a complete itinerary planned for a smooth trip, we also make sure you are absolutely comfortable during the entire journey. Trips to every city include a complete tour of the spectacular tourist spots the destination has to offer. So, choose our Magic of India tour packages now for a lovely vacation.</p>
    
    <p className="text-right mb-5"><Button className="mr-5" onClick={routeChange}>Book Now</Button>
  <Button href={magicofindia} target = "_blank"> Read Itinerary Details </Button> </p>
  </Col>
</Row>

<Row>
<Col lg="6">
<Accordion  defaultActiveKey="0">
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
      Day 01

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <h5>Delhi</h5>
        <img src={img1} className="img-fluid mr-3" />
        <img src={img2} className="img-fluid mr-3" />
        <img src={img3} className="img-fluid mr-3" />
        <p className="text-justify">Arrive at Hotel. Spend the rest of day at leisure.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
      Day 02

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
      <h5>Delhi</h5>
      <img src={img4} className="img-fluid mr-3" />
        <img src={img5} className="img-fluid mr-3" />
        <img src={img6} className="img-fluid mr-3" />
        <p className="text-justify">Sightseeing tour of the Old and New Delhi, visiting the Jama Masjid (1465 A.D.), drive through the fabled Chandni Chowk (Silver Street) and see the Red Fort, Rajghat, where the "Father of the nation", Mahatma Gandhi, was cremated; the 72M high Qutab Minar, the 1500-year-old Iron Pillar, government buildings, the President's House, Parliament House, India Gate, Laxmi Narayan Temple, and last but not least Delhi's main shopping centre, Connaught Place. Ample time will be available to appreciate this lovely city which resembles a vast and beautiful park.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
      Day 03

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body>
      <h5>Delhi - Udaipur</h5>
      <img src={img7} className="img-fluid mr-3" />
        <img src={img8} className="img-fluid mr-3" />
        <img src={img9} className="img-fluid mr-3" />
        <p className="text-justify">Morning flight to Udaipur. After check-in at hotel, take a sightseeing tour of the city including City Palace and museums, Jagdish Temple, Sahelion-Ki-Bari and Shilpgram. In the afternoon, enjoy a boat ride at Lake Pichola.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="5">
        Day 04
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="5">
      <Card.Body>
      <h5>Udaipur - Jodhpur</h5>
      <img src={img10} className="img-fluid mr-3" />
        <img src={img11} className="img-fluid mr-3" />
        <img src={img12} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast departure to Jodhpur by private air conditioned car a distance of 255 Kms - 6.30 hours. Enroute visit the famous Jain Temples of Ranakpur. After check-in in Jodhpur sightseeing tour of the city, including the Mehrangarh Fort, Jaswant Thada and the Clock Tower markets.</p>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="6">
      Day 05
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="6">
      <Card.Body>
      <h5>Jodhpur - Jaipur</h5>
      <img src={img13} className="img-fluid mr-3" />
        <img src={img14} className="img-fluid mr-3" />
        <img src={img15} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast departure to Jaipur by private air conditioned car a distance of 332 Kms - 8 hours . Arrive at hotel in the late afternoon. Evening is free for relaxing or shopping. Jaipur is great for purchases of jewellery and clothing. Stay at Jai Mahal Palace.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  </Accordion>
  </Col>

  <Col lg="6">
  <Accordion  defaultActiveKey="6">

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="6">
      Day 06
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="6">
      <Card.Body>
      <h5>Jaipur</h5>
      <img src={img16} className="img-fluid mr-3" />
        <img src={img17} className="img-fluid mr-3" />
        <img src={img18} className="img-fluid mr-3" />
        <p className="text-justify">Morning visit to Amber Fort including a climb to the Castle riding on the back of an elephant. Later, visit the Temple of Kali. In the afternoon visit the Palace of Wings or Hawa Mahal, the City Palace and Observatory and an orientation drive through the "Pink" city and its many interesting sights.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="7">
      Day 07
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="7">
      <Card.Body>
      <h5>Jaipur - Agra</h5>
      <img src={img19} className="img-fluid mr-3" />
        <img src={img20} className="img-fluid mr-3" />
        <img src={img21} className="img-fluid mr-3" />
        <p className="text-justify">After buffet breakfast at hotel, you will be driven to Agra a distance of 235 Kms - 6 hours, On the way to Agra visit the Fatehpur-Sikri, an ancient city built by Akbar that had to be deserted due to lack of water. A very impressive sight. Late afternoon, visit the Taj Mahal; one of the Seven Wonders of the world.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  


  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="8">
      Day 08
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="8">
      <Card.Body>
      <h5>Agra - Khajuraho</h5>
      <img src={img22} className="img-fluid mr-3" />
        <img src={img23} className="img-fluid mr-3" />
        <img src={img24} className="img-fluid mr-3" />
                                    <p className="text-justify">Depart Agra in the morning by train a short journey of 2Hrs 30 Min to Jhansi - then driven from Jhansi railway station to Khajuraho 200 Kms - 5 Hours. Arrive Khajuraho the same morning. Evening sightseeing. Khajuraho is renowned for its magnificent temples, unrivaled for a profusion of beautiful ornamentation and detail. The main temples, Kandariya Mahadeo, Chaturbhuj , Parswanath, and Ghanti, are remarkable for their exquisite design sculpture and graceful pillars.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="9">
      Day 09
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="9">
      <Card.Body>
      <h5>Khajuraho- Varanasi</h5>
      <img src={img25} className="img-fluid mr-3" />
        <img src={img26} className="img-fluid mr-3" />
        <img src={img27} className="img-fluid mr-3" />
        <p className="text-justify">Flight from Khajuraho to Varanasi . Arrive in the holy city, also known as Benaras. Afternoon excursion to Sarnath.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="10">
      Day 10
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="10">
      <Card.Body>
      <h5>Varanasi-Delhi</h5>
      <img src={img28} className="img-fluid mr-3" />
        <img src={img29} className="img-fluid mr-3" />
        <img src={img20} className="img-fluid mr-3" />
        <p className="text-justify">Varanasi is the principal city of Hinduism, located on the banks of the holy River Ganges. For several thousand years, pilgrims have cleansed themselves of sins at this holy place. Experience a unique motor launch cruise on the holy river, visit the "Golden Temple" and browse through the age-old narrow streets. Late afternoon fly back to Delhi and transfer to Delhi International Airport.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  
  

</Accordion>
</Col>

</Row>

</Container>
</>
  );
  }
export default MagicofIndia;
