import React, { Component } from "react";
import { Container } from "react-bootstrap";
const Footer = () => (
  <footer className="mt-4">
    <Container>
      <p className="copyright text-center">
        © 2022 Copyright Taj Tour. All Rights Reserved.
      </p>
    </Container>
  </footer>
);

export default Footer;
