import React, { Component } from "react";
import {Container, Col, Row, Button, Card, Accordion} from "react-bootstrap";
import banner from "../images/banners/northeastern-hills.jpg";

import img1 from "../images/airport-sl.jpg";
import img2 from "../images/hotel-sl.jpg";
import img3 from "../images/hotel-sl2.jpg";

import img4 from "../images/bmich.jpg";
import img5 from "../images/museum.jpg";
import img6 from "../images/wolfendha-church.jpg";

import img7 from "../images/banana-plantations.jpg";
import img8 from "../images/pinnawela.jpg";
import img9 from "../images/dambulla-cave.jpg";

import img10 from "../images/sigiriya.jpg";
import img11 from "../images/sigiriya2.jpg";
import img12 from "../images/kandy.jpg";

import img13 from "../images/botanical-gardens.jpg";
import img14 from "../images/kitulgala.jpg";
import img15 from "../images/tea-factory.jpg";

import img16 from "../images/beruwala.jpg";
import img17 from "../images/beruwala2.jpg";
import img18 from "../images/beruwala3.jpg";

import img19 from "../images/galle-fort.jpg";
import img20 from "../images/galle-fort2.jpg";
import img21 from "../images/turtle-hatchery.jpg";

import img22 from "../images/airport-sl.jpg";
import img23 from "../images/airport-sl2.jpg";
import img24 from "../images/airport-sl3.jpg";
import { useHistory } from "react-router-dom";
import essenceofSrilankapdf from "../doc/detailed_itinerary_for_essence_of_srilanka.pdf";

function  EssenceofSriLanka(){
  const history = useHistory();
  
  const routeChange = () =>{ 
    let path = `/customTour`; 
    history.push(path);
  }


  return(<>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    Essence of   <strong>Sri Lanka </strong>
    </h1>
    
  </Col>

  <Col lg="12">
    <p className="text-justify mt-2 tour-para"><span>A lush tropical island, </span> lapped by the warm waters of the Indian Ocean, Sri Lanka is a natural paradise with a landscape of verdant mountains, rainforests, paddy fields, tumbling waterfalls and sweeping gorges. This fabulous tour allows a mix of culture and sheer bliss. From ancient cities, to rich religious festivals, to colonial memories, to rolling tea plantations, to the best beaches, to an astonishing variety of bird and animal wildlife, and to a bustling and chaotic way of life, Sri Lanka intoxicates its visitors with its potpourri of cultures, religions, races, customs, its sheer natural beauty and its way of life.
    </p>

    
    <p className="text-right mb-5"><Button className="mr-5" onClick={routeChange}>Book Now</Button>
  <Button href={essenceofSrilankapdf} target = "_blank"> Read Itinerary Details </Button> </p>
  </Col>
</Row>

<Row>

<Col lg="6">
<Accordion  defaultActiveKey="0">
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
      Day 01

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <h5>Arrive colombo by international flight</h5>
        <img src={img1} className="img-fluid mr-3" />
        <img src={img2} className="img-fluid mr-3" />
        <img src={img3} className="img-fluid mr-3" />
        <p className="text-justify">The adventure starts out when you touch down at Bandaranaike International Airport where you will be met and transported to your hotel in Colombo, the capital of Sri Lanka.

Overnight at the hotel.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
      Day 02

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
      <h5>In colombo</h5>
      <img src={img4} className="img-fluid mr-3" />
        <img src={img5} className="img-fluid mr-3" />
        <img src={img6} className="img-fluid mr-3" />
        <p className="text-justify">Today explore the city by wandering through the sprawling collection of ancient artifacts and colonial-era relics at the must-see National Museum, beautiful Buddhist & Hindu temples, the historic Wolfendhal church, Bandaranaike Memorial International Conference Hall.

Overnight at the hotel.

</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
      Day 03

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body>
      <h5>Colombo - Pinnawala - Dambulla by surface(220 km/5hrs)</h5>
      <img src={img7} className="img-fluid mr-3" />
        <img src={img8} className="img-fluid mr-3" />
        <img src={img9} className="img-fluid mr-3" />
        <p className="text-justify">Today depart after breakfast for the drive through lush green countryside, a mix of coconut palms, paddy fields and banana plantations to Dambulla.</p>

<p>Enroute visit Pinnawala Elephant Orphanage.</p>

<p>Dambulla, located in the north central province which dates back to the 01st century BC, is Sri Lanka's greatest Cave Temple complex.This UNESCO World Heritage Site is a perfect location to view evolution of ancient Sri Lankan art.

Overnight stay in Dambulla.</p>



      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="3">
        Day 04
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="3">
      <Card.Body>
      <h5>Dambulla - Sigiriya - Kandy by surface (120 km/3hrs)</h5>
      <img src={img10} className="img-fluid mr-3" />
        <img src={img11} className="img-fluid mr-3" />
        <img src={img12} className="img-fluid mr-3" />
        <p className="text-justify">Morning, drive on to The Rock Fortress of Sigiriya.</p>

<p>Sigiriya, the impressive 5th century UNESCO World Heritage Site, rises 600 feet from the plains overlooking the surrounding countryside giving far-reaching views.</p>

<p>Thereafter head into the hills to the picturesque town of Kandy.</p>

<p>Kandy was the last royal capital and it is one of the most charming towns in Sri Lanka, famous for its gorgeous setting beside a lake, cradled by hills and surrounded by the sinuous lines of tea plantations.

Overnight stay in Kandy.</p>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  </Accordion>
</Col>

<Col lg="6">
<Accordion  defaultActiveKey="0">

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="4">
      Day 05
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="4">
      <Card.Body>
      <h5>Kandy - Nuwara Eliya by surface (120 km/3hrs)</h5>
      <img src={img13} className="img-fluid mr-3" />
        <img src={img14} className="img-fluid mr-3" />
        <img src={img15} className="img-fluid mr-3" />
        <p className="text-justify">Morning, visit the famous Royal Botanical Gardens in Peradeniya and view a rare collection of tropical exotic and indigenous flora.</p>

<p>There after leave for Nuwara Eliya via Kitulgala, visiting a tea factory. Located at an altitude of 1,868 m (6,128 ft) in the heart of the Tea Country.

Continue your drive to Nuwara Eliya and check-in to your hotel.

Overnight stay in Nuwara Eliya.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="5">
      Day 06
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="5">
      <Card.Body>
      <h5>Nuwara Eliya - Beruwala by surface (220 km/5hrs)</h5>
      <img src={img16} className="img-fluid mr-3" />
        <img src={img17} className="img-fluid mr-3" />
        <img src={img18} className="img-fluid mr-3" />
        <p className="text-justify">Morning, you will be driven to Beruwala, home to one of the longest beaches in Sri Lanka.

Today is free for you to spend at leisure. Overnight stay in Beruwala.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="6">
      Day 07
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="6">
      <Card.Body>
      <h5>Excursion to Galle by surface (75 km/1 1/2 hrs per way)</h5>
      <img src={img19} className="img-fluid mr-3" />
        <img src={img20} className="img-fluid mr-3" />
        <img src={img21} className="img-fluid mr-3" />
        <p className="text-justify">Morning, we set off today and drive along the southern shores of Sri Lanka to Galle to visit 17 century Dutch Fort, a UNESCO World Heritage Site and Turtle Hatchery.

Overnight stay in Beruwala.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="7">
      Day 08
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="7">
      <Card.Body>
      <h5>Beruwela - Colombo by surface (70 km/2hrs)</h5>
      <img src={img22} className="img-fluid mr-3" />
        <img src={img23} className="img-fluid mr-3" />
        <img src={img24} className="img-fluid mr-3" />
        <p className="text-justify">Colombo - Departure by International Flight.

Post breakfast at the hotel, you will be transferred to airport to board your flight for onward journey.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
</Col>
</Row>
</Container>
</>
);
}
export default EssenceofSriLanka;
