import React, { Component } from "react";
import { Container, Col, Row, Form, Button, Alert } from "react-bootstrap";
import banner from "../images/banners/refer-us.jpg";
import ReCAPTCHA from "react-google-recaptcha";
class TellaFriend extends Component {
  recaptchaRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      tellafriend: {
        yourName: "",
        yourEmail: "",
        name1: "",
        email1: "",
        name2: "",
        email2: "",
        name3: "",
        email3: "",
        name4: "",
        email4: "",
        name5: "",
        email5: "",
        comment: "",
      },
      recapthares: {
        success: false,
      },
      loading: false,
      validated: false,
      emailSuccess: false,
      buttonclicked: false,
    };
  }

  render() {
    let alertcontent;
    if (this.state.emailSuccess && this.state.buttonclicked) {
      alertcontent = (
        <Alert variant="success" className="mt-4">
          Thank you for contacting us. A team member will reply to you shortly.
        </Alert>
      );
    } else if (this.state.buttonclicked) {
      alertcontent = (
        <Alert variant="danger" className="mt-4">
          Sorry.. please try again or please contact us: 1-800-304-8714.
        </Alert>
      );
    }

    return (
      <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>
        <Container>
          <Row>
            <Col lg="12">
              <h1 className="mb-4 mt-5">
                Refer<strong> Us </strong>
              </h1>
            </Col>
          </Row>

          <Form
            className="my-5"
            noValidate
            validated={this.state.validated}
            onSubmit={this.sendEmailforFriend}
          >
            <Form.Row className="my-3">
              <Col>
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  placeholder="Your name"
                  id="yourName"
                  type="text"
                  value={this.state.tellafriend.yourName}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your name
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Your Email</Form.Label>
                <Form.Control
                  placeholder="Your email"
                  id="yourEmail"
                  type="email"
                  value={this.state.tellafriend.yourEmail}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your email
                </Form.Control.Feedback>
              </Col>
            </Form.Row>

            <Form.Row className="my-3">
              <Col>
                <Form.Label>Name 1</Form.Label>
                <Form.Control
                  placeholder="name 1"
                  id="name1"
                  type="text"
                  value={this.state.tellafriend.name1}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your name 1
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Email 1</Form.Label>
                <Form.Control
                  placeholder="email 1"
                  id="email1"
                  type="email"
                  value={this.state.tellafriend.email1}
                  onChange={this.handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your email 1
                </Form.Control.Feedback>
              </Col>
            </Form.Row>

            <Form.Row className="my-3">
              <Col>
                <Form.Label>Name 2</Form.Label>
                <Form.Control
                  placeholder="name 2"
                  id="name2"
                  type="text"
                  value={this.state.tellafriend.name2}
                  onChange={this.handleChange}
                />
              </Col>
              <Col>
                <Form.Label>Email 2</Form.Label>
                <Form.Control
                  placeholder="email 2"
                  id="email2"
                  type="email"
                  value={this.state.tellafriend.email2}
                  onChange={this.handleChange}
                />
              </Col>
            </Form.Row>

            <Form.Row className="my-3">
              <Col>
                <Form.Label>Name 3</Form.Label>
                <Form.Control
                  placeholder="name 3"
                  id="name3"
                  type="text"
                  value={this.state.tellafriend.name3}
                  onChange={this.handleChange}
                />
              </Col>
              <Col>
                <Form.Label>Email 3</Form.Label>
                <Form.Control
                  placeholder="email 3"
                  id="email3"
                  type="text"
                  value={this.state.tellafriend.email3}
                  onChange={this.handleChange}
                />
              </Col>
            </Form.Row>

            <Form.Row className="my-3">
              <Col>
                <Form.Label>Name 4</Form.Label>
                <Form.Control
                  placeholder="name 4"
                  id="name4"
                  type="text"
                  value={this.state.tellafriend.name4}
                  onChange={this.handleChange}
                />
              </Col>
              <Col>
                <Form.Label>Email 4</Form.Label>
                <Form.Control
                  placeholder="email 4"
                  id="email4"
                  type="text"
                  value={this.state.tellafriend.email4}
                  onChange={this.handleChange}
                />
              </Col>
            </Form.Row>

            <Form.Row className="my-3">
              <Col>
                <Form.Label>Name 5</Form.Label>
                <Form.Control
                  placeholder="Name 5"
                  id="name5"
                  type="text"
                  value={this.state.tellafriend.name5}
                  onChange={this.handleChange}
                />
              </Col>
              <Col>
                <Form.Label>Email 5</Form.Label>
                <Form.Control
                  placeholder="email 5"
                  id="email5"
                  type="text"
                  value={this.state.tellafriend.email5}
                  onChange={this.handleChange}
                />
              </Col>
            </Form.Row>

            <Form.Row className="my-3">
              <Col>
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Comments"
                  id="comment"
                  value={this.state.tellafriend.comment}
                  onChange={this.handleChange}
                />
              </Col>
              
              <Col>
              <Form.Label>Verification</Form.Label>
                <ReCAPTCHA
                  sitekey="6LfMkzUaAAAAAEXdEB_IhmE3hbHZ40aGHa93e5_G"
                  onChange={this.googleOnchange}
                  ref={(r) => (this.recaptcha = r)}
                />
              </Col>
            </Form.Row>

            <Button type="submit" variant="primary" size="lg" block>
              {this.state.loading ? (
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                ""
              )}
              Submit
            </Button>

            {alertcontent}
          </Form>
        </Container>
      </>
    );
  }
  handleChange = (event) => {
    //const { id, value } = event.target;

    let id = event.target.id;
    let value = event.target.value;

    this.setState((prevState) => ({
      tellafriend: {
        ...prevState.tellafriend,

        [id]: value,
      },
      recapthares: {
        ...prevState.recapthares,

        [id]: value,
      },
    }));
  };

  googleOnchange = (event) => {
    let response = event;

    fetch(`api/SampleData/VerifyGoogleCaptcha`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(response),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data != null) {
          let res = JSON.parse(data);

          this.setState({
            recapthares: { success: res.success },
          });
        }
      });
  };

  sendEmailforFriend = (event) => {
    this.setState({ buttonclicked: false });

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      console.log("Check validity is false");
    } else {
      event.preventDefault();
      console.log("Check validity is true");

      if (this.state.recapthares.success) {
        this.setState({ loading: true });
        fetch("api/SampleData/TellaFriend", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: [
            JSON.stringify(
              this
                .state /*{
            contact: this.state.contactUs,
            employee: this.state.employee,
          }*/
            ),
            //JSON.stringify(this.state.employee),
          ],
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              loading: false,
              emailSuccess: true,
              validated: false,
              buttonclicked: true,
              tellafriend: {
                yourName: "",
                yourEmail: "",
                name1: "",
                email1: "",
                name2: "",
                email2: "",
                name3: "",
                email3: "",
                name4: "",
                email4: "",
                name5: "",
                email5: "",
                comment: "",
              },
            });
            this.recaptcha.reset();
            console.log(data);
          });
      } else {
        this.setState({
          emailSuccess: false,
          buttonclicked: true,
        });
      }
    }

    this.setState({ validated: true });
  };
}
export default TellaFriend;
