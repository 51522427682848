import React, { Component } from "react";
import {Container, Col, Row, Button, Card, Accordion} from "react-bootstrap";
import banner from "../images/banners/surf-and-sand.jpg";

import img1 from "../images/old-delhi.jpg";
import img2 from "../images/Jama Masjid.jpg";
import img3 from "../images/red-fort.jpg";

import img4 from "../images/iron-pillar.jpg";
import img5 from "../images/india-gate.jpg";
import img6 from "../images/president-house.jpg";

import img7 from "../images/medieval-fort.jpg";
import img8 from "../images/havelis.jpg";
import img9 from "../images/havelis2.jpg";

import img10 from "../images/bkaner-fort.jpg";
import img11 from "../images/bkaner-fort2.jpg";
import img12 from "../images/bkaner-fort3.jpg";

import img13 from "../images/jaisalmer.jpg";
import img14 from "../images/jaisalmer2.jpg";
import img15 from "../images/jaisalmer3.jpg";

import img16 from "../images/jaisalmer5.jpg";
import img17 from "../images/Gadisar-lake.jpg";
import img18 from "../images/jaisalmer1.jpg";

import img19 from "../images/lodurva.jpg";
import img20 from "../images/samm-sand2.jpg";
import img21 from "../images/samm-sand.jpg";

import img22 from "../images/mehrangarh-fort.jpg";
import img23 from "../images/jaswant-thada.jpg";
import img24 from "../images/clock-tower-markets.jpg";

import img25 from "../images/jain-temples.jpg";
import img26 from "../images/jain-temples2.jpg";
import img27 from "../images/jain-temples3.jpg";

import img28 from "../images/city-palace.jpg";
import img29 from "../images/museum2.jpg";
import img30 from "../images/lake-pichola.jpg";

import img31 from "../images/nagda-temple.jpg";
import img32 from "../images/eklingji-temple.jpg";
import img33 from "../images/eklingji-temple2.jpg";

import img34 from "../images/adhai-din-ka-jhonpra.jpg";
import img35 from "../images/adhai-din-ka-jhonpra2.jpg";
import img36 from "../images/shopping.jpg";

import img37 from "../images/castle-riding.jpg";
import img38 from "../images/kali-temple.jpg";
import img39 from "../images/pink-city.jpg";

import img40 from "../images/fatehpur-sikri3.jpg";
import img41 from "../images/taj-mahal.jpg";
import img42 from "../images/agra-fort2.jpg";

import img43 from "../images/delhi-airport.jpg";
import img44 from "../images/delhi-airport2.jpg";
import img45 from "../images/airport3.jpg";
import { useHistory } from "react-router-dom";
import rajasthanexplore from "../doc/RajasthanExplorer.pdf";


function RajasthanExplorer(){
  const history = useHistory();

  const routeChange = () =>{ 
    let path = `/customTour`; 
    history.push(path);
  }


return(

  <>
        <section>
          <Row>
            <Col lg="12">
              <img src={banner} className="img-fluid" />
            </Col>
          </Row>
        </section>

<Container>
<Row>
  <Col lg="12">
    <h1 className="mb-4 mt-5">
    Rajasthan <strong> Explorer</strong>
    </h1>
    
  </Col>

  <Col lg="12">
    <p className="text-justify mt-2 tour-para"><span>Spend a vacation in the sea of sand </span>; explore Rajasthan, the Indian state for the kings and royals. Our Rajasthan tour packages incorporate a comprehensive tour of the historically and culturally rich cities in India. The Rajasthan travel begins with a short and sweet tour of the capital, moving on to other Rajasthani cities like Mandwa, Bikaner, Jaisalmer, Jodhpur, Udaipur, Ajmer, Jaipur, and ending at the famous Agra. Rajasthan tours with Taj Tour will make you feel like the royalty with first class service at your call.
    </p>

    
    <p className="text-right mb-5"><Button className="mr-5" onClick={routeChange}>Book Now</Button>
  <Button href={rajasthanexplore} target = "_blank"> Read Itinerary Details </Button> </p>
  </Col>
</Row>

<Row>

<Col lg="6">
<Accordion  defaultActiveKey="0">
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
      Day 01

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <h5>Delhi</h5>
        <img src={img1} className="img-fluid mr-3" />
        <img src={img2} className="img-fluid mr-3" />
        <img src={img3} className="img-fluid mr-3" />
        <p className="text-justify">Arrive in the night and check in at the hotel OR Arrive in afternoon sightseeing tour of Old Delhi visiting the Jama Masjid, drive through the fabled Chandri Chowk (Silver Street) and see the Red Fort</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
      Day 02

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
      <h5>Delhi</h5>
      <img src={img4} className="img-fluid mr-3" />
        <img src={img5} className="img-fluid mr-3" />
        <img src={img6} className="img-fluid mr-3" />
        <p className="text-justify">Sightseeing tour of New Delhi, visiting the Rajghat, where the "Father of the nation", Mahatma Gandhi, was cremated; the 72 metre high Qutab Minar, the 1500-year-old Iron Pillar, government buildings, the President's House, Parliament House, India Gate, Laxmi Narayan Temple, and last but not least Delhi's main shopping centre, Connaught Place. Ample time will be available to appreciate this lovely city which resembles a vast and beautiful park.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
      Day 03

      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body>
      <h5>Delhi - Mandawa</h5>
      <img src={img7} className="img-fluid mr-3" />
        <img src={img8} className="img-fluid mr-3" />
        <img src={img9} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast drive to Mandawa, a distance of 280Km; about 7 hours. Mandawa emerges like a mirage. A medieval fort (dated around 1750) gradually rises on the horizon. The Mandawa bazaar has many painted houses. Visit the havelis with their frescoes. The word haveli means 'surrounded' or an 'enclosed place.' Since the middle of the 19th century, the inhabitants of northeastern Rajasthan have patronised the art of fresco painting. The frescoes on the earliest havelis date back to the early 1800</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="3">
        Day 04
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="3">
      <Card.Body>
      <h5>Mandawa - Bikaner</h5>
      <img src={img10} className="img-fluid mr-3" />
        <img src={img11} className="img-fluid mr-3" />
        <img src={img12} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast drive to Bikaner, a distance of 170Km; about 4 hours. Afternoon, take a tour of Bikaner Visit the Fort, one of the finest of Rajput monuments. Thirty-seven palaces, pavilions and temples built by different kings are all connected by paved courtyards, narrow staircases and terraces in this Rathor stronghold.</p>
      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="4">
      Day 05
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="4">
      <Card.Body>
      <h5>Bikaner - Jaisalmer</h5>
      <img src={img13} className="img-fluid mr-3" />
        <img src={img14} className="img-fluid mr-3" />
        <img src={img15} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast drive to Jaisalmer, a distance of 333Km; about 8 hours. On arrival transfer to hotel.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="5">
      Day 06
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="5">
      <Card.Body>
      <h5>Jaisalmer</h5>
      <img src={img16} className="img-fluid mr-3" />
        <img src={img17} className="img-fluid mr-3" />
        <img src={img18} className="img-fluid mr-3" />
        <p className="text-justify">Sightseeing tour of Jaisalmer. The entire living area of Jaisalmer lies within the gigantic citadel walls. Visit the silent 12th century Fort built by Prince Jaisal and the Palace which stands at the highest point above the marketplace. Visit Gadisar Lake an artificial lake that was an important source of water in the past. A natural decline was enlarged to catch every drop of rain and, in a season of drought, Gadisar Lake was the mainstay of Jaisalmer.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

    
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="6">
      Day 07
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="6">
      <Card.Body>
      <h5>Jaisalmer</h5>
      <img src={img19} className="img-fluid mr-3" />
        <img src={img20} className="img-fluid mr-3" />
        <img src={img21} className="img-fluid mr-3" />
        <p className="text-justify">Full day visit to Lodurva and Sam Sand Dunes. Lodurva, the former capital of the Bhatti rulers is just 16 km northwest of Jaisalmer. It is an ancient township that lies in ruins about the temples. The Sam Sand Dunes permit you to experience the pace and space of desert life.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  </Accordion>
</Col>

<Col lg="6">
<Accordion  defaultActiveKey="0">

  


  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="7">
      Day 08
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="7">
      <Card.Body>
      <h5>Jaisalmer-Jodhpur</h5>
      <img src={img22} className="img-fluid mr-3" />
        <img src={img23} className="img-fluid mr-3" />
        <img src={img24} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast drive to Jodhpur, a distance of 308Km; about 7 hours. After check-in, take sightseeing tour of the city, including the Mehrangarh Fort, Jaswant Thada and the Clock Tower markets.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="8">
      Day 09
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="8">
      <Card.Body>
      <h5>Jodhpur - Udaipur</h5>
      <img src={img25} className="img-fluid mr-3" />
        <img src={img26} className="img-fluid mr-3" />
        <img src={img27} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast drive to Jodhpur, a distance of 259Km; about 7 hours. Enroute visit the famous Jain Temples of Ranakpur.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="9">
      Day 10
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="9">
      <Card.Body>
      <h5>Udaipur</h5>
      <img src={img28} className="img-fluid mr-3" />
        <img src={img29} className="img-fluid mr-3" />
        <img src={img30} className="img-fluid mr-3" />
        <p className="text-justify">Sightseeing tour of the city including City Palace and museums, ride in the world's famous Lake Pichola.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="10">
      Day 11
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="10">
      <Card.Body>
      <h5>Udaipur - Ajmer</h5>
      <img src={img31} className="img-fluid mr-3" />
        <img src={img32} className="img-fluid mr-3" />
        <img src={img33} className="img-fluid mr-3" />
        <p className="text-justify">After breakfast drive to Ajmer, a distance of 274Km; about 7.5 hours. Enroute visit Nagda and Eklingji with their temples, ancient rock edicts, stone inscriptions and simple prehistoric shrines.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="11">
      Day 12
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="11">
      <Card.Body>
      <h5>Ajmer - Jaipur</h5>
      <img src={img34} className="img-fluid mr-3" />
        <img src={img35} className="img-fluid mr-3" />
        <img src={img36} className="img-fluid mr-3" />
        <p className="text-justify">Take tour of Ajmer. Visit the Dargah and Adhai-din-ka-jhonpra. Afternoon, drive to Jaipur by bus. a distance of 138Km; about 3.5 hours. Arrive at hotel in the late afternoon. Evening is free for relaxing or shopping. Jaipur is great for purchases of jewellery and clothing.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="12">
      Day 13
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="12">
      <Card.Body>
      <h5>Jaipur</h5>
      <img src={img37} className="img-fluid mr-3" />
        <img src={img38} className="img-fluid mr-3" />
        <img src={img39} className="img-fluid mr-3" />
        <p className="text-justify">Morning visit to Amber Fort including a climb to the Castle riding on the back of an elephant. Later, visit the Temple of Kali. In the afternoon visit the "Pink" city and its many interesting sights.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="13">
      Day 14
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="13">
      <Card.Body>
      <h5>Jaipur - Agra</h5>
      <img src={img40} className="img-fluid mr-3" />
        <img src={img41} className="img-fluid mr-3" />
        <img src={img42} className="img-fluid mr-3" />
        <p className="text-justify">On the way to Agra visit the Fatehpur-Sikri, an ancient city built by Akbar that had to be deserted due to lack of water. Late afternoon, visit the Taj Mahal and Agra Fort.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="14">
      Day 15
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="14">
      <Card.Body>
      <h5>Agra - Delhi</h5>
      <img src={img43} className="img-fluid mr-3" />
        <img src={img44} className="img-fluid mr-3" />
        <img src={img45} className="img-fluid mr-3" />
        <p className="text-justify">Afternoon, drive to Delhi International Airport by bus. a distance of 235Km; about 4 hours.</p>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
</Col>
</Row>
</Container>
</>
);
}
export default RajasthanExplorer;
