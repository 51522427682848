import React, { Component } from "react";
import './App.css';
import CityEscapes from '../src/components/cityEscapes'
import Home from '../src/components/home'
import Tours from '../src/components/tours'
import Header from '../src/components/header'
import Footer from '../src/components/footer'
import { BrowserRouter, Route, Switch, useHistory, withRouter } from 'react-router-dom';
import Price from './components/price';
import Yoga from './components/yoga';
import Whyus from './components/whyus';
import CustomTour from './components/customTour';
import Testimonials from './components/testimonials';
import TellaFriend from './components/tellaFriend';
import GeneralInfo from './components/generalInfo';
import ContactUs from './components/contactUs';
import GlimpseofIndia from './components/glimpseofIndia';
import GoldenTriangle from './components/goldenTriangle';
import MagicofIndia from './components/magicofIndia';
import TranquilKerala from './components/tranquilKerala';
import PalaceonWheels from './components/palaceonWheels';
import SurfSand from './components/surfSand';
import RajasthanExplorer from './components/rajasthanExplorer';
import IndiaExplorer from './components/indiaExplorer';
import NortheasternHills from './components/northeasternHills';
import ExoticNepal from './components/exoticNepal';
import TajHighlights from './components/tajHighlights';
import BestofIndia from './components/bestofIndia';
import RomancingtheTaj from './components/romancingtheTaj';
import RoyalRajasthanOnWheels from './components/royalRajasthanOnWheels';
import TajWildernessofNorthIndia from './components/tajWildernessofNorthIndia';
import TajExoticaResort from './components/tajExoticaResort';
import EssenceofSriLanka from './components/essenceofSriLanka';
import MumbaiTours from './components/mumbaiTours';
import GroupTours from './components/groupTours';
import TailorMadeTours from './components/tailorMadeTours';
import WildlifeSafaris from './components/wildlifeSafaris';
import ScrolltoTop from './components/scrolltoTop';

class App extends React.Component {

  state = {  } 
  constructor(props) {
    super(props);
   
  }
  handleClick=(value)=>{
    console.log(this.props);
    this.props.history.push(value);

  
  }

  render() {
    return (<div className="wrapper">
       
   <BrowserRouter>
   <ScrolltoTop />
      <Header></Header>
      <Switch>
      
      <Route exact  path="/">
        
                <Home />
              </Route>
              <Route exact  path="/tours">
                <Tours />
              </Route>
             <Route exact  path="/cityEscapes">
                <CityEscapes />
      </Route>
              <Route exact  path="/yoga">
                <Yoga></Yoga>
              </Route>
              <Route exact  path="/price">
                <Price />
              </Route>
              <Route exact  path="/whyus">
                <Whyus></Whyus>
              </Route>
              <Route exact  path="/customTour">
              <CustomTour></CustomTour>
              </Route>
              <Route exact  path="/testimonials">
              <Testimonials></Testimonials>
              </Route>
              <Route exact  path="/tellaFriend">
              <TellaFriend></TellaFriend>
              </Route>
              <Route exact  path="/generalInfo">
              <GeneralInfo></GeneralInfo>
              </Route>
              <Route exact  path="/handatravel">
    
              </Route>
              <Route exact  path="/contactUs">
              <ContactUs></ContactUs>
              </Route>
              <Route exact  path="/glimpseofindia">
              <GlimpseofIndia></GlimpseofIndia>
              </Route>
              <Route exact  path="/goldentriangle">
              <GoldenTriangle></GoldenTriangle>
              </Route>
              <Route exact  path="/magicofindia">
              <MagicofIndia></MagicofIndia>
              </Route>
              <Route exact  path="/tranquilkerala">
              <TranquilKerala></TranquilKerala>
              </Route>
              <Route exact  path="/palaceonwheels">
              <PalaceonWheels></PalaceonWheels>
              </Route>
              <Route exact  path="/surfsand">
              <SurfSand></SurfSand>
              </Route>
              <Route exact  path="/rajasthanexplorer">
             <RajasthanExplorer></RajasthanExplorer>
              </Route>
              <Route exact  path="/indiaexplorer">
             <IndiaExplorer></IndiaExplorer>
              </Route>
              <Route exact  path="/northeasternhills">
             <NortheasternHills></NortheasternHills>
              </Route>
              <Route exact  path="/exoticnepal">
            <ExoticNepal></ExoticNepal>
              </Route>
              <Route exact  path="/tajhighlights">
            <TajHighlights></TajHighlights>
              </Route>
              <Route exact  path="/bestofindia">
            <BestofIndia></BestofIndia>
              </Route>
              <Route exact  path="/romancingthetaj">
            <RomancingtheTaj></RomancingtheTaj>
              </Route>
              <Route exact  path="/royalrajasthanonwheels">
            <RoyalRajasthanOnWheels></RoyalRajasthanOnWheels>
              </Route>
              <Route exact  path="/tajwildernessofnorthindia">
                <TajWildernessofNorthIndia></TajWildernessofNorthIndia>
              </Route>
              <Route exact  path="/tajexoticaresort">
                <TajExoticaResort></TajExoticaResort>
              </Route>
              <Route exact  path="/essenceofsriLanka">
                <EssenceofSriLanka></EssenceofSriLanka>
              </Route>
              <Route exact  path="/mumbaitours">
                <MumbaiTours></MumbaiTours>
              </Route>
              <Route exact  path="/grouptours">
                <GroupTours></GroupTours>
              </Route>
              <Route exact  path="/tailormadetours">
               <TailorMadeTours></TailorMadeTours>
              </Route>
              <Route exact  path="/wildlifesafaris">
             <WildlifeSafaris></WildlifeSafaris>
              </Route>
             
            </Switch>
           
          </BrowserRouter>
          <Footer></Footer>
        
        </div>);

  }
 
}
 
export default withRouter(App);
/*function App() {
  return (
    
  );
}

export default App;*/
