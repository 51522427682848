import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import FigureCaption from "react-bootstrap/FigureCaption";
import Figure from "react-bootstrap/Figure";
import { Link, withRouter } from "react-router-dom";

import img1 from "../images/slider1.jpg";
import img2 from "../images/slider2.jpg";
import img3 from "../images/slider3.jpg";
import img4 from "../images/slider4.jpg";
import img5 from "../images/slider5.jpg";

import img6 from "../images/img20.jpg";
import img7 from "../images/img2.jpg";
import img8 from "../images/img6.jpg";
import img9 from "../images/img7.jpg";
import img10 from "../images/img4.jpg";

import img11 from "../images/img8.jpg";
import img12 from "../images/img9.jpg";
import img13 from "../images/img11.jpg";
import img14 from "../images/img12.jpg";
import img15 from "../images/img13.jpg";

import img16 from "../images/img14.jpg";
import img17 from "../images/img16.jpg";
import img18 from "../images/img1.jpg";
import img19 from "../images/img19.jpg";

import img23 from "../images/img15.jpg";
import img21 from "../images/img17.jpg";
import img22 from "../images/img18.jpg";

import logo1 from "../images/logo4.png";
import logo2 from "../images/logo2.png";
import logo3 from "../images/logo3.png";
import logo4 from "../images/logo1.png";
import logo5 from "../images/logo6.png";
import logo6 from "../images/logo5.png";

import img20 from "../images/img10.jpg";

// import img11 from "../images/img14.jpg";
// import img12 from "../images/img16.jpg";
// import img13 from "../images/img1.jpg";
// import img14 from "../images/img19.jpg";
// import img15 from "../images/img15.jpg";

// import img11 from "../images/img17.jpg";
// import img12 from "../images/img18.jpg";

class Home extends Component {
  //   state = {};
  render() {
    return (
      <>
        <Carousel>
          <Carousel.Item>
            <img src={img1} className="img-fluid" />
          </Carousel.Item>

          <Carousel.Item>
            <img src={img2} className="img-fluid" />
          </Carousel.Item>

          <Carousel.Item>
            <img src={img3} className="img-fluid" />
          </Carousel.Item>

          <Carousel.Item>
            <img src={img4} className="img-fluid" />
          </Carousel.Item>

          <Carousel.Item>
            <img src={img5} className="img-fluid" />
          </Carousel.Item>
        </Carousel>

        <section className="tour-packages">
          <Container className="all-tourpack">
            <Row className="mb-4">
              <Col lg="6">
                <div className="all">
                  <h1>Tour Packages</h1>
                  <Button
                    className="viewall-btn"
                    onClick={() => this.nextPath("/tours")}
                  >
                    view all
                  </Button>
                </div>
              </Col>

              <Col lg="3">
                <Figure>
                  <Figure.Image src={img6} className="img-fluid" />

                  <Figure.Caption>
                    <div className="fig-group">
                      <p> Glimpse of India </p>
                      <Button
                        className="btn-sm"
                        onClick={() => this.nextPath("/glimpseofindia")}
                      >
                        read more
                      </Button>
                    </div>
                  </Figure.Caption>
                </Figure>
              </Col>

              <Col lg="3">
                <Figure>
                  <Figure.Image src={img7} className="img-fluid" />

                  <Figure.Caption>
                    <div className="fig-group">
                      <p> Golden Triangle </p>
                      <Button
                        className="btn-sm"
                        onClick={() => this.nextPath("/goldentriangle")}
                      >
                        {" "}
                        read more
                      </Button>
                    </div>
                  </Figure.Caption>
                </Figure>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col lg="3">
                <Figure>
                  <Figure.Image src={img8} className="img-fluid" />

                  <Figure.Caption>
                    <div className="fig-group">
                      <p> Magic of India</p>
                      <Button
                        className="btn-sm"
                        onClick={() => this.nextPath("/magicofindia")}
                      >
                        {" "}
                        read more
                      </Button>
                    </div>
                  </Figure.Caption>
                </Figure>
              </Col>

              <Col lg="3">
                <Figure>
                  <Figure.Image src={img9} className="img-fluid" />

                  <Figure.Caption>
                    <div className="fig-group">
                      <p>Tranquil Kerala</p>
                      <Button
                        className="btn-sm"
                        onClick={() => this.nextPath("/tranquilkerala")}
                      >
                        {" "}
                        read more
                      </Button>
                    </div>
                  </Figure.Caption>
                </Figure>
              </Col>

              <Col lg="3">
                <Figure>
                  <Figure.Image src={img10} className="img-fluid" />

                  <Figure.Caption>
                    <div className="fig-group">
                      <p>Palace on Wheels</p>
                      <Button
                        className="btn-sm"
                        onClick={() => this.nextPath("/palaceonwheels")}
                      >
                        {" "}
                        read more
                      </Button>
                    </div>
                  </Figure.Caption>
                </Figure>
              </Col>

              <Col lg="3">
                <Figure>
                  <Figure.Image src={img11} className="img-fluid" />

                  <Figure.Caption>
                    <div className="fig-group">
                      <p>Surf and Sand</p>
                      <Button
                        className="btn-sm"
                        onClick={() => this.nextPath("/surfsand")}
                      >
                        {" "}
                        read more
                      </Button>
                    </div>
                  </Figure.Caption>
                </Figure>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col lg="3">
                <Figure>
                  <Figure.Image src={img12} className="img-fluid" />

                  <Figure.Caption>
                    <div className="fig-group">
                      <p>Rajasthan Explorer</p>
                      <Button
                        className="btn-sm"
                        onClick={() => this.nextPath("/rajasthanexplorer")}
                      >
                        {" "}
                        read more
                      </Button>
                    </div>
                  </Figure.Caption>
                </Figure>
              </Col>

              <Col lg="3">
                <Figure>
                  <Figure.Image src={img13} className="img-fluid" />

                  <Figure.Caption>
                    <div className="fig-group">
                      <p>India Explorer</p>
                      <Button
                        className="btn-sm"
                        onClick={() => this.nextPath("/indiaexplorer")}
                      >
                        {" "}
                        read more
                      </Button>
                    </div>
                  </Figure.Caption>
                </Figure>
              </Col>

              <Col lg="3">
                <Figure>
                  <Figure.Image src={img14} className="img-fluid" />

                  <Figure.Caption>
                    <div className="fig-group">
                      <p>Northeastern Hills</p>
                      <Button
                        className="btn-sm"
                        onClick={() => this.nextPath("/northeasternhills")}
                      >
                        {" "}
                        read more
                      </Button>
                    </div>
                  </Figure.Caption>
                </Figure>
              </Col>

              <Col lg="3">
                <Figure>
                  <Figure.Image src={img15} className="img-fluid" />
                  <Figure.Caption>
                    <div className="fig-group">
                      <p>Exotic Nepal</p>
                      <Button
                        className="btn-sm"
                        onClick={() => this.nextPath("/exoticnepal")}
                      >
                        {" "}
                        read more
                      </Button>
                    </div>
                  </Figure.Caption>
                </Figure>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col lg="3">
                <Figure>
                  <Figure.Image src={img16} className="img-fluid" />

                  <Figure.Caption>
                    <div className="fig-group">
                      <p>Taj Highlights</p>
                      <Button
                        className="btn-sm"
                        onClick={() => this.nextPath("/tajhighlights")}
                      >
                        {" "}
                        read more
                      </Button>
                    </div>
                  </Figure.Caption>
                </Figure>
              </Col>

              <Col lg="3">
                <Figure>
                  <Figure.Image src={img17} className="img-fluid" />

                  <Figure.Caption>
                    <div className="fig-group">
                      <p>Best of India</p>
                      <Button
                        className="btn-sm"
                        onClick={() => this.nextPath("/bestofindia")}
                      >
                        {" "}
                        read more
                      </Button>
                    </div>
                  </Figure.Caption>
                </Figure>
              </Col>

              <Col lg="3">
                <Figure>
                  <Figure.Image src={img18} className="img-fluid" />
                  <Figure.Caption>
                    <div className="fig-group">
                      <p>Romancing the Taj</p>
                      <Button
                        className="btn-sm"
                        onClick={() => this.nextPath("/romancingthetaj")}
                      >
                        {" "}
                        read more
                      </Button>
                    </div>
                  </Figure.Caption>
                </Figure>
              </Col>

              <Col lg="3">
                <Figure>
                  <Figure.Image src={img19} className="img-fluid" />
                  <Figure.Caption>
                    <div className="fig-group">
                      <p>Royal Rajashtan On Wheels</p>
                      <Button
                        className="btn-sm"
                        onClick={() => this.nextPath("/royalrajasthanonwheels")}
                      >
                        {" "}
                        read more
                      </Button>
                    </div>
                  </Figure.Caption>
                </Figure>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col lg="3">
                <Figure>
                  <Figure.Image src={img23} className="img-fluid" />

                  <Figure.Caption>
                    <div className="fig-group">
                      <p>Taj & Wilderness of North India</p>
                      <Button
                        className="btn-sm"
                        onClick={() =>
                          this.nextPath("/tajwildernessofnorthindia")
                        }
                      >
                        {" "}
                        read more
                      </Button>
                    </div>
                  </Figure.Caption>
                </Figure>
              </Col>

              <Col lg="3">
                <Figure>
                  <Figure.Image src={img21} className="img-fluid" />

                  <Figure.Caption>
                    <div className="fig-group">
                      <p>Taj Exotica Resort & Spa Maldives</p>
                      <Button
                        className="btn-sm"
                        onClick={() => this.nextPath("/tajexoticaresort")}
                      >
                        {" "}
                        read more
                      </Button>
                    </div>
                  </Figure.Caption>
                </Figure>
              </Col>

              <Col lg="3">
                <Figure>
                  <Figure.Image src={img22} className="img-fluid" />
                  <Figure.Caption>
                    <div className="fig-group">
                      <p>Essence of Sri Lanka</p>
                      <Button
                        className="btn-sm"
                        onClick={() => this.nextPath("/essenceofsriLanka")}
                      >
                        {" "}
                        read more
                      </Button>
                    </div>
                  </Figure.Caption>
                </Figure>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="about-us">
          <Container>
            <Row>
              <Col className="video">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item"
                    src="https://player.vimeo.com/video/64553709"
                    width="450"
                    height="300"
                    frameBorder="0"
                    webkitallowfullscreen=""
                    mozallowfullscreen=""
                    allowFullScreen=""
                  ></iframe>
                </div>
              </Col>

              <Col className="about-details">
                <h1>
                  Why Choose <strong>Taj Tour </strong>
                </h1>
                <p>
                  <strong>Taj Tour</strong>is a privately owned company that has
                  been selling tours to India since 1976. We are a direct Tour
                  Operator, which means that when you deal with us there is no
                  middleman. In addition, unlike with the large companies we
                  work with you personally. You as a customer can contact our
                  representative directly and arrange a tour itinerary that is
                  best suitable for you.
                </p>
                <Button
                  variant="primary read-btn"
                  onClick={() => this.nextPath("/whyus")}
                >
                  Read More
                </Button>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="partners">
          <Container>
            <Row>
              <Col>
                <img src={logo1} />
              </Col>
              <Col>
                <img src={logo2} />
              </Col>
              <Col>
                <img src={logo3} />
              </Col>
              <Col>
                <img src={logo4} />
              </Col>
              <Col>
                <img src={logo5} />
              </Col>
              <Col>
                <img src={logo6} />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="testimonials">
          <Container>
            <Row className="mt-2">
              <Col lg="6">
                <Carousel>
                  <Carousel.Item>
                    <div>
                      <p>
                        Hello Desmond - Long overdue, but just to let you know
                        our time in India was fantastic and a real adventure.
                        Thank you for your assistance in making sure the Delhi,
                        Agra, Jaipur, Cochin, Munnar visits were enjoyable.....{" "}
                        <a onClick={() => this.nextPath("/testimonials")}>Read more </a>
                      </p>
                      <h6>Cathy Callaghan | Toronto</h6>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div>
                      <p>
                        After months of preparation it's all over. Weâ€™re back
                        home after a couple of days in Amsterdam and have gotten
                        over the effects of jet lag. The trip was exceptional in
                        all respects. Not a moment of disappointment
                        anywhere..... <a onClick={() => this.nextPath("/testimonials")}>Read more </a>
                      </p>
                      <h6>Jean-Paul | Saint Lambert</h6>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div>
                      <p>
                        Desmond, we have returned weary and pleased with our
                        trip. You provided what we asked for, tailoring the trip
                        to our interests. SITA supported our trip almost
                        seamlessly; agents meeting us at destinations, providing
                        good guides.....<a onClick={() => this.nextPath("/testimonials")}>Read more </a>
                      </p>
                      <h6>Bill and PatLivingston | CA</h6>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </Col>
              <Col lg="6">
                <Row>
                  <Col className="">
                    <img src={img20} className="img-fluid custom-img" />
                  </Col>

                  <Col>
                    <div className="custom-tour">
                      <h3>Custom Tours</h3>
                      <p>
                        Enter the trip details of the destination(s) that you
                        would like to get more information on
                      </p>
                      <Button variant="primary book-btn" onClick={() => this.nextPath("/customTour")}>Book Now</Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
  nextPath(path) {
    this.props.history.push(path);
  }
}

export default withRouter(Home);
